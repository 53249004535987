import React, { FC } from "react";

export const VolumeVideoPlayerIcon: FC<any> = () => {
    return (
        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5H0.5V3.50002H4H4.11803L4.22361 3.44723L9.5 0.809032V13.191L4.22361 10.5528L4.11803 10.5H4Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 11.4722C13.2275 10.3736 14 8.777 14 7C14 5.223 13.2275 3.62643 12 2.52779V3.99951C12.6279 4.83526 13 5.87417 13 7C13 8.12582 12.6279 9.16474 12 10.0005V11.4722Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14 13.7083C15.8412 12.0604 17 9.66552 17 7.00002C17 4.33452 15.8412 1.93966 14 0.291702V1.70837C15.2447 3.11864 16 4.97113 16 7.00002C16 9.02891 15.2447 10.8814 14 12.2917V13.7083Z" />
        </svg>
    );
}
