import React, {FunctionComponent, useEffect} from 'react';
import './AppContent.css';
import events, {Sponsor, Event} from './events';
import {Video} from "./components/VideoBroadcastApp/Video/Video";
import SponsorVideoLogoGroup from "./components/SponsorVideoLogo/SponsorVideoLogoGroup";

export interface AppProps {
    event: Event;
}

const AppContent: FunctionComponent<AppProps> = ({event}) => {
    return (
        <>
            {event.hlsPlaylistLink && (
                <div className="App-player-custom-container" data-testid="custom-video-player">
                    <Video
                        url={event.hlsPlaylistLink}
                        showAdvancedControls={true}
                        useScrollMinimizer={true}
                        sponsors={event.sponsors ?? []}
                    />
                </div>
            )}
            {
                event.youtubeLink &&
                <div className="App-player-container">
                    <iframe className="App-player" title="player" src={event.youtubeLink}
                            data-testid="youtube-video-player"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                            allowFullScreen={true}/>
                </div>
            }
            <a href="https://live.jugru.org" target="_blank" rel="noopener noreferrer">
                <div className="App-ribbon">
                    <div className="App-ribbon-logo">
                        <img className="App-ribbon-logo-img" src="/jrg-logo.png" alt=""/>
                    </div>
                    <div className="App-ribbon-ad">
                        <div className="App-ribbon-title">Любимые конференции</div>
                        <div className="App-ribbon-subtitle">4 ноября&thinsp;—&thinsp;5 декабря</div>
                        <div className="App-ribbon-ticket">Купить билет</div>
                    </div>
                    <div className="App-ribbon-chat">
                        {event.telegramLink && (
                            <a href={event.telegramLink} target="_blank" rel="noopener noreferrer"
                               data-testid="app-content-tg-link">
                                <div className="App-ribbon-chat-title">Чат трансляции</div>
                                <div className="App-ribbon-chat-telegram">
                                    <span role="img" className="App-ribbon-chat-logo" aria-label="">💬</span>
                                    {event.telegramLink.replace('tg://resolve?domain=', '')}
                                </div>
                                <div className="App-ribbon-chat-talk">Пообщаться</div>
                            </a>
                        )}
                    </div>
                    <div className="App-ribbon-feedback">
                        {event.surveyMonkey && (
                            <a href={event.surveyMonkey} target="_blank" rel="noopener noreferrer">
                                <div className="App-ribbon-feedback-title">Обратная связь</div>
                                <div className="App-ribbon-feedback-emoji">
                                    <span role="img" className="App-ribbon-chat-logo" aria-label="">😑 🤔 🙂 😍</span>
                                </div>
                                <div className="App-ribbon-feedback-send">Заполнить анкету</div>
                            </a>
                        )}
                    </div>
                </div>
                { //add sponsors for youtube link. In JRG Player they integrated into player
                    event.youtubeLink && event.sponsors &&
                    <SponsorVideoLogoGroup sponsors={event.sponsors as Sponsor[]}/>
                }
            </a>
            <div className="App-content">
                <div className="App-content-talk">
                    <div className="App-content-talk-bits">
                        <div className="App-content-talk-bits-date">
                            <span role="img" className="App-content-icon" aria-label="">🗓</span>
                            {event.date}
                        </div>
                        <div className="App-content-talk-bits-time">
                            <span role="img" className="App-content-icon" aria-label="">🕑</span>
                            {event.timeStart}&thinsp;—&thinsp;{event.timeEnd}
                        </div>
                        <div className="App-content-talk-bits-tag">
                            <span role="img" className="App-content-icon" aria-label="">{event.complexityEmoji}</span>
                            {event.complexityTag}
                        </div>
                        <div className="App-content-talk-bits-lang">{event.langTag}</div>
                    </div>
                    <div className="App-content-talk-title">{event.title}</div>
                    <div className="App-content-talk-tags">{event.tags}</div>
                    <div className="App-content-talk-subtitle">Что будет на встрече</div>
                    <div className="App-content-talk-description">
                        {event.description.map((p, i) => (
                            <p key={i}>{p}</p>
                        ))}
                    </div>
                    {event.qaIframeLink &&
                    <div className="App-content-ask-speaker-content" data-testid={'app-content-qa-block'}>
                        <iframe title="qa" src={event.qaIframeLink} height="100%" width="100%"/>
                    </div>}
                    {event.showNotes && <div className="App-content-show-notes-title">Заметки</div>}
                    {event.showNotes && <div className="App-content-talk-show-notes">
                        <ul className="App-content-show-notes-content">
                            {event.showNotes}
                        </ul>
                    </div>}
                </div>
                <div className="App-content-speakers">
                    <span className="App-content-speakers__title">Участники</span>
                    {event.speakers.map(speaker => (
                        <div className="App-content-speaker" key={speaker.name}>
                            <img className="App-content-speaker-photo" src={speaker.photoLink} alt=""/>
                            <div>
                            <div className="App-content-speaker-name">{speaker.name}</div>
                            <div>
                                {speaker.affiliation && (
                                <div className="App-content-speaker-affilation">{speaker.affiliation}</div>)}
                                {speaker.twitterAccount && (<div className="App-content-speaker-account">
                                    <img src="img/twitter-logo.png" className="App-content-icon-img" alt="" />
                                    <a href={'https://twitter.com/' + speaker.twitterAccount} target='_blank'
                                       rel="noopener noreferrer">{speaker.twitterAccount}</a>
                                </div>)}
                            </div>
                            </div>
                            <div className="App-content-speaker-description">{speaker.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>);
}

export default AppContent;
