import React, { FC } from "react";


export const PauseIcon: FC<any> = () => {
    return (
        <svg
            height="100%" version="1.1" viewBox="0 0 36 36" width="100%">
            <path
                  d="M 12,26 16,26 16,10 12,10 z M 21,26 25,26 25,10 21,10 z"
                  id="ytp-id-116" />
        </svg>
    );
}
