import React, { FC } from 'react';
import styles from './VideoPlayerUI.module.scss';
import timeLineStyles from './controls/timeline/VideoPlayerTimeline.module.scss';
import classNames from 'classnames';

import { observer } from 'mobx-react';

import { VideoPlayerUIProps } from './VideoPlayerUIProps';

import { VideoPlayerVolume } from './controls/volume/VideoPlayerVolume';
import { VideoPlayerQuality } from "./controls/quality/VideoPlayerQuality";
import { VideoPlayerTimeline } from "./controls/timeline/VideoPlayerTimeline";

import { formatMsToHmss } from '../../../../lib/time/format/formatMsToHmss';

import { PlayVideoPlayerIcon } from "../../../icons/PlayVideoPlayerIcon";
import { PauseVideoPlayerIcon } from "../../../icons/PauseVideoPlayerIcon";

import { VideoControlButton } from "./controls/buttons/VideoControlButton";
import { VideoSpinner } from "../../components/VideoSpinner/VideoSpinner";

export const VideoPlayerUI: FC<VideoPlayerUIProps> = observer(
    props => {
        const customControls = props.customControls != null
            ? props.customControls
            : false;
        const controls = !customControls && props.controls != null
            ? props.controls
            : false;

        const videoPlayerState = props.videoPlayerState;
        const levels = props.videoPlayerState.levels;
        const currentLevel = videoPlayerState.currentLevel;
        const videoState = videoPlayerState.video;

        return (
            <div
                onClick={(event) => {
                    if ((event.target as Element).classList.contains(timeLineStyles.line)) {
                        return;
                    }

                    props.onVideoClick && props.onVideoClick();
                }}
                onDoubleClick={() => {
                    props.onVideoDoubleClick && props.onVideoDoubleClick();
                }}
                className={
                    classNames(
                        styles.root,
                        props.className,
                        videoPlayerState.isFetching && styles.__isFetching,
                        videoPlayerState.isLevelFetching && styles.__isLevelFetching,
                        videoState.isWaitingData && styles.__isVideoWaitingData,
                    )
                }
            >
                <div className={styles.videoWrapper}>
                    <video
                        // style={{ maxHeight: props.fullScreen ? undefined : '70vh' }}
                        style={{ height: props.fullScreen ? undefined : '70vh' }}
                        className={styles.video}
                        ref={props.videoRef}
                        crossOrigin={props.crossOrigin}
                        controls={controls}
                        autoPlay={props.autoPlay}
                    />
                </div>

                <div className={styles.loader}>
                    <VideoSpinner />
                </div>

                <div className={styles.centerIndicator}>
                    {
                        props.inCenterIndicatorRenderer
                        ? props.inCenterIndicatorRenderer()
                        : null
                    }
                </div>

                {
                    props.liveIndicatorRenderer
                    ? props.liveIndicatorRenderer(videoState.isLive, videoState.isLivePlaying)
                    : null
                }

                {
                    props.nowWatchingIndicatorRenderer
                        ? props.nowWatchingIndicatorRenderer(videoState.isLive)
                        : null
                }

                { customControls && (
                    <div className={styles.controls}>
                        <VideoPlayerTimeline
                            className={styles.timeline}
                            currentTime={videoState.currentTimeInMs}
                            duration={videoState.durationInMs}
                            isLive={videoState.isLive}
                            isLivePlaying={videoState.isLivePlaying}
                            onMove={props.moveTimeline}
                        />

                        <div
                            onClick={(event) => { event.stopPropagation() }}
                            className={styles.controlsButtons}>
                            <div className={styles.controlsLeftPanel}>
                                { !videoState.isShowPauseControl && (
                                    <VideoControlButton
                                        title={"Play"}
                                        className={styles.videoControlButton}
                                        onClick={props.play}>
                                        <PlayVideoPlayerIcon />
                                    </VideoControlButton>
                                ) }
                                { videoState.isShowPauseControl && (
                                    <VideoControlButton
                                        title={'Pause'}
                                        className={styles.videoControlButton}
                                        onClick={props.pause}>
                                        <PauseVideoPlayerIcon />
                                    </VideoControlButton>
                                ) }

                                <VideoPlayerVolume
                                    className={styles.volume}
                                    setMuted={props.setMuted}
                                    setVolume={props.setVolume}
                                    isMuted={videoState.isMuted}
                                    volume={videoState.volume}
                                />

                                <div className={styles.time}>
                                    {
                                        videoState.currentTimeInMs ?
                                            videoState.isLive
                                            ? formatMsToHmss(videoState.currentTimeInMs)
                                            : `${formatMsToHmss(videoState.currentTimeInMs)} / ${formatMsToHmss(videoState.durationInMs)}`
                                        : null
                                    }
                                    {
                                        videoState.isLive &&
                                        !videoState.isLivePlaying &&
                                        <button
                                            onClick={() => {
                                                props.onLiveRestored && props.onLiveRestored();
                                            }}
                                            className={styles.liveBtn}
                                        >
                                            <div>live</div>
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className={styles.controlsRightPanel}>
                                { levels.length !== 0 && (
                                  <VideoPlayerQuality
                                        className={styles.quality}
                                        isLevelFetching={videoPlayerState.isLevelFetching}
                                        levels={levels}
                                        currentLevel={currentLevel}
                                        chooseHlsLevel={props.chooseHlsLevel}
                                  />
                                ) }

                                {
                                    props.customControls && props.extControlsRenderer
                                    ? props.extControlsRenderer()
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    },
);
