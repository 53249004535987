import React, { createRef, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { VideoPlayerUI } from './ui/VideoPlayerUI';
import { VideoPlayerViewModel } from './ViewModel/VideoPlayerViewModel';
import { VideoPlayerUIProps } from './ui/VideoPlayerUIProps';
import { VideoPlayerProps } from './VideoPlayerProps';

import { useHotkeys } from 'react-hotkeys-hook';


// ЭТО ТАКОЙ КОМПОНЕНТ - КОТОРЫЙ МОЖНО ВЫНЕСТИ В ЛИБУ!
export const VideoPlayer: FC<VideoPlayerProps> = props => {
    const [ viewModel ] = useState(() => new VideoPlayerViewModel());

    const [ isPlaying, setIsPlaying ] = useState(false);

    // resolving props
    const withCredentials = props.withCredentials != undefined
        ? props.withCredentials
        : false;
    const source = props.source;
    const actualLive = props.actualLive;

    useHotkeys('space', (event) => {
        if (!props.useKeysEvents) {
            return;
        }

        event.preventDefault();

        props.onKeySpacePressed
            && props.onKeySpacePressed(() => {
                if (isPlaying) {
                    pause();
                } else {
                    play();
                }
            });

    }, {}, [props.useKeysEvents, isPlaying]);

    useHotkeys('right', (event) => {
        if (!props.useKeysEvents) {
            return;
        }

        event.preventDefault();

        const seekMs = (props.playerRewindSecs ?? 10) * 1000;
        const currentTimeInMs = viewModel.uiState.video.currentTimeInMs;

        viewModel.setSeekInMs(currentTimeInMs + seekMs);
    }, {}, [props.useKeysEvents]);

    useHotkeys('left', (event) => {
        if (!props.useKeysEvents) {
            return;
        }

        event.preventDefault();

        const seekMs = (props.playerRewindSecs ?? 10) * 1000;
        const currentTimeInMs = viewModel.uiState.video.currentTimeInMs;

        viewModel.setSeekInMs(currentTimeInMs - seekMs);

        viewModel.uiState.video.setIsLivePlaying(false);
    }, {}, [props.useKeysEvents]);

    // connect with props
    useEffect(
        () => {
            viewModel.setActualLive(actualLive || false);
        },
        [source, actualLive],
    );

    useEffect(
        () => {
            viewModel.setSource(source, withCredentials);
        },
        [source, withCredentials],
    );

    // connect with video tag
    const videoRef = createRef<HTMLVideoElement>();
    useEffect(
        () => {
            const currentVideo = videoRef.current;
            if (currentVideo) {
                viewModel.setVideoElement(currentVideo);
            }
        },
        [videoRef.current],
    );

    // outside api integrations
    // seek
    useEffect(
        () => {
            viewModel.setOnSeeked(props.onSeeked);
        },
        [props.onSeeked],
    );
    useEffect(
        () => {
            viewModel.setOnSeeking(props.onSeeking);
        },
        [props.onSeeking],
    );
    useEffect(
        () => {
            if (props.seekInMs != null) {
                viewModel.setSeekInMs(props.seekInMs);
            }
        },
        [props.seekInMs],
    );

    useEffect(
        () => {
            viewModel.setOnCurrentTimeUpdated(props.onCurrentTimeUpdated);
        },
        [props.onCurrentTimeUpdated],
    );

    useEffect(() => {
        if (!props.autoPlay) {
            viewModel.hidePauseControl();
        }
    });

    useEffect(() => {
        if (props.autoPlay) {
            setIsPlaying(true);
        }
    }, []);

    const play = useCallback(
        () => {
            viewModel.play();
            setIsPlaying(true);
            props.onPlay && props.onPlay();
        },
        [isPlaying]
    )

    const pause = useCallback(
        () => {
            viewModel.pause();
            setIsPlaying(false);
            viewModel.uiState.video.setIsLivePlaying(false);
            props.onPause && props.onPause();
        },
        [isPlaying]
    );

    const renderUI = useMemo(
        () => props.uiRenderer
            ? props.uiRenderer
            : (uiProps: VideoPlayerUIProps) => <VideoPlayerUI { ...uiProps } />,
        [props.uiRenderer],
    );

    if (viewModel.uiState.errors && viewModel.uiState.errors.length > 0) {
        return props.noHlsRenderer
            ? props.noHlsRenderer()
            : <div>
                {
                    viewModel.uiState.errors.map(error => <div key={error}>{error}</div>)
                }
            </div>
    }

    return (
        renderUI({
            videoRef,
            videoPlayerState: viewModel.uiState,
            crossOrigin: withCredentials ? 'use-credentials' : undefined,
            autoPlay: props.autoPlay,
            customControls: props.customControls,
            controls: props.controls,
            play,
            pause,
            chooseHlsLevel: viewModel.chooseHlsLevel,
            moveTimeline: viewModel.moveTimeline,
            setVolume: viewModel.setVolume,
            setMuted: viewModel.setMuted,
            fullScreen: props.fullScreen,
            extControlsRenderer: props.extControlsRenderer,
            liveIndicatorRenderer: props.liveIndicatorRenderer,
            nowWatchingIndicatorRenderer: props.nowWatchingIndicatorRenderer,
            inCenterIndicatorRenderer: props.inCenterIndicatorRenderer,
            onVideoClick: () => {
                if (props.playOrPauseAtClick) {
                    if (isPlaying) {
                        pause();
                    } else {
                        play();
                    }
                }
            },
            onLiveRestored: () => {
                viewModel.uiState.markAsFetchingSourceStarted();
                viewModel.restartLive();
                play();
                viewModel.uiState.video.setIsLivePlaying(true);
            },
            onVideoDoubleClick: props.onVideoDoubleClick
        })
    );
};
