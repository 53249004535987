
import { AppConfigInterface } from "./config";


export const getAppConfig = (): AppConfigInterface => {
    return {
        telegramSupportBot: 'tg://resolve?domain=@JUGConfSupport_bot',
        hideControlsAfterHoverSecs: 4,
        saveVideoSeekSecs: 5,
        miniPlayer: {
            widthByScreenFactor: 4,
            heightByWidthFactor: 0.6,
            minWidth: 300,
            maxWidth: 600,
            minHeight: 180,
            maxHeight: 360,
        },
        playerRewindSecs: 10,
    } as AppConfigInterface;
};
