import Hls from 'hls.js';
import { VideoPlayerHlsLevel } from './VideoPlayerHlsLevel';
import { VideoPlayerErrorCode } from './VideoPlayerErrorCode';
import { VideoPlayerUIStateImpl } from './VideoPlayerUIStateImpl';
import { NamespacedLogger } from '../../../../lib/logging/NamespacedLogger';
import { transformSecondsToMs } from '../../../../lib/time/transformSecondsToMs';
import { transformMsToSeconds } from '../../../../lib/time/transformMsToSeconds';

const hlsLogger = new NamespacedLogger('Hls.js:VideoPlayerVM');
const videoLogger = new NamespacedLogger('Video:VideoPlayerVM');

export class VideoPlayerViewModel {

  uiState = new VideoPlayerUIStateImpl();

  private source: string|undefined;
  private withCredentials: boolean = false;

  setSource(
    source: string,
    withCredentials: boolean,
  ) {
    this.source = source;
    this.withCredentials = withCredentials;
    this.settingUpVideoTag();
  }

  private videoElement: HTMLVideoElement|undefined;

  setVideoElement(videoElement: HTMLVideoElement) {
    this.videoElement = videoElement;
    this.settingUpVideoTag();
  }

  private settingUpVideoTag() {
    const videoElement = this.videoElement;
    const source = this.source;

    if (
      videoElement != null
      && source != null
    ) {
       this.configureVideoElement(videoElement, source);
    }
  }

  private onSeeked: ((seekInMs: number) => void)|undefined;

  setOnSeeked(
    onSeeked: ((seekInMs: number) => void)|undefined,
  ) {
    this.onSeeked = onSeeked;
  }

  private onSeeking: ((seekInMs: number) => void)|undefined;

  setOnSeeking(
    onSeeking: ((seekInMs: number) => void)|undefined,
  ) {
    this.onSeeking = onSeeking;
  }

  setSeekInMs(seekInMs: number) {
    if (this.videoElement) {
      this.videoElement.currentTime = transformMsToSeconds(seekInMs);
    }
  }

  private onCurrentTimeUpdated: ((seekInMs: number) => void) | undefined;

  setOnCurrentTimeUpdated(onCurrentTimeUpdated: ((seekInMs: number) => void) | undefined) {
    this.onCurrentTimeUpdated = onCurrentTimeUpdated;
  }

  private actualLive: boolean = false;
  private wasSeeked = false;

  setActualLive(
    actualLive: boolean = false,
  ) {
    this.actualLive = actualLive;
    const videoElement = this.videoElement;

    if (actualLive && videoElement != null) {
      videoElement.currentTime = videoElement.duration;
    }
  }

  private hls: Hls|undefined;

  private configureVideoElement(
    videoElement: HTMLVideoElement,
    source: string,
  ) {
    //
    if (!Hls.isSupported()) {
      // TODO [dmitry.makhnev]: implement no HLS way
      this.uiState.setErrors(
        true,
        VideoPlayerErrorCode.HLS_DOES_NOT_SUPPORT,
      );
      return;
    }

    this.wasSeeked = false;
    this.uiState.markAsFetchingSourceStarted();

    this.configureHls(
      videoElement,
      source,
      this.withCredentials,
    );

    this.configureVideoTag(
      videoElement,
    );
  }

  private configureHls(
    videoElement: HTMLVideoElement,
    source: string,
    withCredentials: boolean,
  ) {

    if (this.hls) {
      this.hls.destroy();
      this.uiState.resetLevels();
    }

    const hls = new Hls({
      // TODO [dmitry.makhnev]: resolve settings
      // https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning
      debug: false,
      enableWorker: true,

      liveBackBufferLength: Infinity,
      liveMaxLatencyDurationCount: Infinity,
      liveSyncDurationCount: 2,

      manifestLoadingMaxRetry: 10,
      manifestLoadingRetryDelay: 5000,
      manifestLoadingMaxRetryTimeout : 50000,

      maxBufferHole: 0.2,
      xhrSetup: xhr => {
        xhr.withCredentials = withCredentials;
      },
    });

    this.hls = hls;

    hls.on(Hls.Events.ERROR, this.handleHlsError);
    hls.on(Hls.Events.MEDIA_ATTACHED, this.handleHlsMediaAttached);
    hls.on(Hls.Events.MANIFEST_PARSED, this.handleHlsManifestParsed);
    hls.on(Hls.Events.LEVEL_SWITCHED, this.handleHlsLevelSwitched);
    hls.attachMedia(videoElement);
    hls.loadSource(source)
  }

  private handleHlsMediaAttached = () => {
    hlsLogger.info('media attached');
  };

  private handleHlsManifestParsed = () => {
    hlsLogger.info('manifest parsed');
    this.processHlsLevels();
  };

  private handleHlsLevelSwitched = () => {
    hlsLogger.info('level switched');
    let hls = this.hls;
    if (hls) {
      const currentLevelIndex = hls.currentLevel;
      const currentLevel = hls.levels[currentLevelIndex];

      this.uiState.setCurrentLevel(
        {
          width: currentLevel.width,
          height: currentLevel.height,
          levelIndex: currentLevelIndex,
        },
        false,
      );

      this.uiState.video.setIsLive(
        currentLevel.details?.live || false
      );

      this.uiState.video.setIsLivePlaying(
          currentLevel.details?.live || false
      );

      this.updateVideoTimeline();
      this.updateVideoVolume();
    }
  };

  private updateVideoTimeline() {
    const videoElement = this.videoElement;
    if (videoElement) {
      const currentTimeInMs = transformSecondsToMs(videoElement.currentTime);
      const durationInMs = transformSecondsToMs(videoElement.duration);
      this.uiState.video.setTimes(
        currentTimeInMs,
        durationInMs,
      );
    }
  }
  private updateVideoVolume() {
    const videoElement = this.videoElement;
    if (videoElement) {
      this.uiState.video.setVolumeData(
        videoElement.volume,
        videoElement.muted,
      );
    }
  }

  private handleHlsError = (
    event: 'hlsError',
    data: Hls.errorData,
  ) => {
    hlsLogger.error(data);
  };

  private processHlsLevels() {
    if (this.hls) {
      const levels: VideoPlayerHlsLevel[] = this.hls.levels.map((level, i) => ({
        width: level.width,
        height: level.height,
        levelIndex: i,
      }));
      this.uiState.setLevels(levels);
    }
  }

  private configureVideoTag(
    videoElement: HTMLVideoElement,
  ) {
    videoElement.addEventListener('canplay', this.handleVideoCanPlay);
    videoElement.addEventListener('waiting', this.handleVideoWaiting);

    videoElement.addEventListener('play', this.handleVideoPlay);
    videoElement.addEventListener('pause', this.handleVideoPause);

    videoElement.addEventListener('timeupdate', this.handleVideoTimeUpdate);
    videoElement.addEventListener('volumechange', this.handleVideoVolumeChange);

    videoElement.addEventListener('seeking', this.handleVideoSeeking);
    videoElement.addEventListener('seeked', this.handleVideoSeeked);
  }

  private handleAnyVideoEvent = (event: Event) => {
    videoLogger.debug(event.type);
  };

  private handleVideoTimeUpdate = () => {
    videoLogger.debug('time update');

    const currentTime = this.videoElement?.currentTime || 0;
    const currentTimeInMs = transformSecondsToMs(currentTime);

    this.uiState.video.setCurrentTimeInMs(currentTimeInMs);

    this.onCurrentTimeUpdated && this.onCurrentTimeUpdated(currentTimeInMs);
  };

  private handleVideoCanPlay = () => {
    videoLogger.debug('canplay');
    this.uiState.markAsReadyToPlay();
  };

  private handleVideoWaiting = () => {
    videoLogger.debug('waiting');
    this.uiState.video.setIsWaitingData(true);
  };

  private handleVideoPause = () => {
    videoLogger.debug('pause');
    this.uiState.video.setIsShowPauseControl(false);

    if (this.uiState.video.isLive) {
      this.uiState.video.setIsLivePlaying(false);
    }
  };
  private handleVideoPlay = () => {
    videoLogger.debug('play');
    this.uiState.video.setIsShowPauseControl(true);
  };

  private handleVideoSeeking = () => {
    videoLogger.debug('seeking');

    this.wasSeeked = true;

    const videoElement = this.videoElement;
    if (videoElement) {
      const currentTimeInMs = transformSecondsToMs(videoElement.currentTime);
      this.uiState.video.setCurrentTimeInMs(currentTimeInMs);
      this.onSeeking?.(currentTimeInMs);
    }
  };

  private handleVideoSeeked = () => {
    videoLogger.debug('seeked');

    const videoElement = this.videoElement;
    if (videoElement) {
      const currentTimeInMs = transformSecondsToMs(videoElement.currentTime);
      this.onSeeked?.(currentTimeInMs);
    }
  };

  private handleVideoVolumeChange = () => {
    videoLogger.debug('volume change');

    this.updateVideoVolume();
  };

  moveTimeline = (percent: number) => {
    const videoElement = this.videoElement;
    if (videoElement) {
      const durationPercentInMs = this.uiState.video.durationInMs / 100;
      const newCurrentTimeInMs = percent * durationPercentInMs;
      const newCurrentTimeInSeconds = transformMsToSeconds(newCurrentTimeInMs);
      videoElement.currentTime = newCurrentTimeInSeconds;

      this.uiState.video.setIsLivePlaying(false);
    }
  };

  setVolume = (volume: number) => {
    const videoElement = this.videoElement;
    if (videoElement) {
      videoElement.volume = volume;
    }
  };

  setMuted = (isMuted: boolean) =>{
    const videoElement = this.videoElement;
    if (videoElement) {
      videoElement.muted = isMuted;
    }
  };

  chooseHlsLevel = (levelIndex: number) => {
    if (this.hls) {
      this.hls.currentLevel = levelIndex;
      this.uiState.setIsLevelFetching(true);
    }
  };

  hidePauseControl = () => {
    if (this.hls) {
      this.uiState.video.setIsShowPauseControl(false);
    }
  };

  play = () => {
    if (this.videoElement) {
      this.videoElement.play();
    }
  };

  pause = () => {
    if (this.videoElement) {
      this.videoElement.pause();
    }
  };

  restartLive = () => {
    if (this.hls) {
      if (this.source && this.videoElement) {
        this.hls.attachMedia(this.videoElement);
        this.hls.loadSource(this.source);
      }
    }
  }
}
