import * as React from 'react'
import styles from './SponsorVideoLogo.module.scss'
import {url} from "inspector";

type Props = {
    sponsor: string;
    url: string;
    scale?: number;
    monochrome?: boolean;
}

/**
 * Add images to public/img/sponsors with -bw postfix for monochrome and without postifix for general logo
 * For example: sponsor code - alm_works. Save two images img/sponsors/alm_works.png and img/sponsors/alm_works-bw.png
 * @param sponsor
 * @param url
 * @param scale
 * @param monochrome
 * @constructor
 */
const SponsorVideoLogo: React.FunctionComponent<Props> = ({
                                                              sponsor,
                                                              url,
                                                              scale,
                                                              monochrome
                                                          }) => {

    return (
        <a className={styles.sponsorLogoContainer} href={url} target="_blank">
            <img
                className={`${styles.sponsorLogoImage} ${monochrome && styles.monochrome}`}
                style={{height: `${100 * (scale ? scale : 1.0)}%`}}
                src={`img/sponsors/${sponsor}${monochrome ? '-bw': ''}.png`}
                alt={sponsor}
                data-testid="sponsor-video-logo-targe-image"
            />
        </a>
    );
}

SponsorVideoLogo.defaultProps = {
    monochrome: false
}

export default SponsorVideoLogo
