export class CallbackTimer {
    public readonly timerId: number;

    constructor(callback: Function, seconds: number) {
        const timerHandler: Function = () => {
            callback();

            this.stop();
        };

        this.timerId = setTimeout(timerHandler, seconds * 1000);
    }

    public stop(): void {
        clearTimeout(this.timerId);
    }
}
