import React, { FC } from "react";
import styles from './VideoSpinner.module.scss';
import classNames from "classnames";

export interface VideoSpinnerProps {
    className?: string;
}

export const VideoSpinner: FC<VideoSpinnerProps> = ({ className }) => {
    return (
        <div className={className}>
            <div className={styles.ldsSpinner}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
