import React from "react";
import {Event} from "../../events";
import {AppMore} from "../../AppMore";
import styles from './Events.module.scss'

type Props = {
    events: Event[];
}

/**
 * Accepts Events and renders Passed and Future events by the date.
 * @param events
 * @constructor
 */
const Events: React.FunctionComponent<Props> = ({events}) => {
    return (
        <div>
            <div className={styles.title}>
                JUG RU Group
            </div>
            <div className={styles.subtitle}>
                Мы делаем крупнейшие в России конференции и митапы для программистов, а также организуем
                онлайн-мероприятия на заказ
            </div>
            <AppMore events={events}/>
        </div>
    );
}

export default Events;
