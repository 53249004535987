
export class NamespacedLogger {
  private decoratedNamespace: string;

  constructor(
    namespace: string,
  ) {
    this.decoratedNamespace = `[${namespace}]:`;
  }

  debug(...data: any) {
    console.debug(this.decoratedNamespace, ...data);
  }

  log(...data: any) {
    console.log(this.decoratedNamespace, ...data);
  };

  info(...data: any) {
    console.info(this.decoratedNamespace, ...data);
  }

  error(...data: any) {
    console.error(this.decoratedNamespace, ...data);
  }
}
