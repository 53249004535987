import React, {FunctionComponent} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Event} from "./events";
import AppContent from "./AppContent";
import Header from "./components/Header/Header";
import DebugPage from "./components/DebugPage";
import Events from "./components/Events/Events";

export interface AppRouterProps {
    events: Event[];
}

const AppRouter: FunctionComponent<AppRouterProps> = ({events}) => {
    return (<div className="App">
        <Header/>
        <Route exact path='/'>
            <Redirect to={'/events'}/>
        </Route>
        <Switch>
            {events.map(event => (
                <Route path={'/' + event.id} key={event.id}>
                    <AppContent event={event}/>
                </Route>
            ))}
            {events
                .filter(event => event.fuckupUrl != undefined)
                .map(event => (
                    <Redirect to={`/${event.id}`} from={`/${event.fuckupUrl}`}/>
                ))
            }
            <Route path={'/events'}>
                <Events events={events}/>
            </Route>
            <Route path={'/debug-page'} key={'debug-page'}>
                <DebugPage />
            </Route>
        </Switch>
    </div>);
}


export {AppRouter};
