import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from './VideoControlButton.module.scss';


export interface VideoControlButtonProps {
    children?: ReactNode;
    className?: string;
    title?: string;
    onClick?: () => void;
    onMouseMove?: () => void;
    onMouseLeave?: () => void;
}

export const VideoControlButton: FC<VideoControlButtonProps> = ({
    children,
    className,
    title,
    onClick,
    onMouseMove,
    onMouseLeave
    }) => {
 return (
     <button
         title={title}
         className={classNames(styles.videoControlButton, className)}
         children={children}
         onClick={() => onClick ? onClick() : null}
         onMouseMove={() => onMouseMove ? onMouseMove() : null}
         onMouseLeave={() => onMouseLeave ? onMouseLeave() : null}
     />
 );
}
