import React, { FC, MouseEvent, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import styles from './VideoPlayerVolume.module.scss';
import classNames from 'classnames';

import { observer } from 'mobx-react';

import { VideoPlayerVolumeProps } from './VideoPlayerVolumeProps';

import { VolumeVideoPlayerIcon } from "../../../../../icons/VolumeVideoPlayerIcon";
import { VolumeOffVideoPlayerIcon } from "../../../../../icons/VolumeOffVideoPlayerIcon";

import { VideoControlButton } from "../buttons/VideoControlButton";

function calcAndNotifyNewVolume(
    line: HTMLDivElement | null,
    event: MouseEvent<HTMLDivElement, any>,
    setVolume: ((volume: number) => void) | undefined,
    setMuted: ((isMuted: boolean) => void) | undefined,
) {
    if (line) {
        const lineDOMRect = line.getBoundingClientRect();
        const pixelsFromLeft = event.clientX - lineDOMRect.left;
        const resultVolume = pixelsFromLeft / lineDOMRect.width;

        setMuted?.(false);

        if (resultVolume <= 0) {
            setMuted?.(true);
            setVolume?.(0);
        } else {
            setVolume?.(resultVolume);
        }
    }
}

const convertVolumeToPixels = (volume: number, line: HTMLDivElement | null): number => {
    if (line) {
        const lineWidth = line.offsetWidth;
        const pixels = lineWidth * volume;

        return pixels > 52 ? 52 : pixels;
    }

    return 0;
}

export const VideoPlayerVolume: FC<VideoPlayerVolumeProps> = observer(props => {
    const [ showTrack, setShowTrack ] = useState(false);

    const iconButtonHover = useCallback(
        () => {
            setShowTrack(true);
        },
        []
    );

    const iconButtonLeave = useCallback(
        () => {
            setShowTrack(false);
            mouseState.isPressed = false;
        },
        []
    );

    const mute = useCallback(
        () => {
            props.setMuted?.(true);
        },
        [],
    );

    const unmute = useCallback(
        () => {
            props.setMuted?.(false);
        },
        [],
    );

    const isMuted = props.isMuted;

    const lineBarRef = useRef<HTMLDivElement>(null);

    const mouseState = useMemo(
        () => ({
            isPressed: false,
        }),
        [],
    );

    const onLineMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>(
        event => {
            mouseState.isPressed = true;
            calcAndNotifyNewVolume(
                lineBarRef.current,
                event,
                props.setVolume,
                props.setMuted,
            );
        },
        [],
    );

    const onLineMouseUp = useCallback(
        () => {
            mouseState.isPressed = false;
        },
        [],
    );

    const onLineMoseMove = useCallback<MouseEventHandler<HTMLDivElement>>(
        event => {
            if (mouseState.isPressed) {
                calcAndNotifyNewVolume(
                    lineBarRef.current,
                    event,
                    props.setVolume,
                    props.setMuted,
                );
            }
        },
        [],
    );

    const onLineBarClick = useCallback<MouseEventHandler<HTMLDivElement>>(
        event => {
            calcAndNotifyNewVolume(
                lineBarRef.current,
                event,
                props.setVolume,
                props.setMuted,
            );
        },
        [],
    );

    return (
        <div
            onMouseMove={iconButtonHover}
            onMouseLeave={iconButtonLeave}
            className={classNames(
                styles.volume,
                props.className,
            )}
        >
            { !isMuted && <VideoControlButton
                title={"Mute"}
                onClick={mute}>
                <VolumeVideoPlayerIcon />
            </VideoControlButton> }

            { isMuted && <VideoControlButton
                title={"Unmute"}
                onClick={unmute}>
                <VolumeOffVideoPlayerIcon />
            </VideoControlButton> }

            <div
                className={classNames(
                    styles.volumePanel,
                    showTrack ? styles.full : styles.short
                )}>
                <div
                    ref={lineBarRef}

                    onClick={onLineBarClick}

                    onMouseDown={onLineMouseDown}
                    onMouseUp={onLineMouseUp}
                    onMouseMove={onLineMoseMove}

                    className={classNames(
                        styles.volumeSlider,
                        showTrack ? styles.showBar : null
                    )}>
                    <div
                        style={{ left: `${convertVolumeToPixels(isMuted ? 0 : props.volume, lineBarRef.current)}px` }}
                        className={styles.volumeSliderHandle}>
                    </div>
                </div>
            </div>
        </div>
    );
});
