import { MINUTES_IN_HOUR, MS_IN_HOUR, MS_IN_MINUTE, MS_IN_SECOND, SECONDS_IN_MINUTE } from '../time.consts';
import { addLeftPad0 } from '../../numbers/format/addLeftPad0';

export function formatMsToHmss(ms: number): string {
  const allSeconds = Math.floor(ms / MS_IN_SECOND);
  const allMinutes = Math.floor(ms / MS_IN_MINUTE);
  const allHours = Math.floor(ms / MS_IN_HOUR);

  const resultMinutes = allMinutes - allHours * MINUTES_IN_HOUR;
  const resultSeconds = allSeconds - allMinutes * SECONDS_IN_MINUTE;

  const hasHours = allHours > 0;
  const resultAsArray: string[] = [];

  if (hasHours) {
    resultAsArray.push(
      allHours.toString(10)
    );
  }

  resultAsArray.push(
    addLeftPad0(
      resultMinutes,
      () => hasHours && resultMinutes < 10,
    ),
  );

  resultAsArray.push(
    addLeftPad0(resultSeconds),
  );

  return resultAsArray.join(':');
}
