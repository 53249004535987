import React, { FC } from "react";

export const VolumeOffVideoPlayerIcon: FC<any> = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.9144 9.24275L15.6215 8.53564L14.2074 7.12153L15.6216 5.70735L14.9145 5.00024L13.5003 6.41443L12.086 5.00011L11.3789 5.70721L12.7932 7.12153L11.379 8.53577L12.0861 9.24288L13.5003 7.82864L14.9144 9.24275Z" />
            <path d="M4 10.5H0.5V3.5H4H4.11803L4.22361 3.44721L9.5 0.809017V13.191L4.22361 10.5528L4.11803 10.5H4Z" />
        </svg>
    );
}
