import globalStyles from '../../../styles/styles.module.scss';

import { SimpleEncodingForUrl } from "../../../lib/encoding/simpleEncodingForUrl";
import { isClientSide } from "../../../lib/getSafeBrowserObject";


export const addWindowScrollListener = (handler: () => void) => {
    window.addEventListener('scroll', handler);
}

export const removeWindowScrollListener = (handler: () => void) => {
    window.removeEventListener('scroll', handler);
}

export const addWindowResizeListener = (handler: () => void) => {
    window.addEventListener('resize', handler);
}

export const removeWindowResizeScrollListener = (handler: () => void) => {
    window.removeEventListener('resize', handler);
}

export const addDocumentFullscreenListener = (handler: () => void) => {
    document.addEventListener("fullscreenchange", handler);
}

export const removeDocumentFullscreenListener = (handler: () => void) => {
    document.removeEventListener("fullscreenchange", handler);
}

export const getFullScreenElement = (): Element | null => {
    return document.fullscreenElement;
}

export const getNavBarHeight = (): number => {
    const defaultHeight = 56;

    if (!isClientSide) {
        return defaultHeight;
    }

    const navBar = document.getElementsByClassName(globalStyles.navBar)[0] as HTMLDivElement;

    let height = navBar?.offsetHeight ?? defaultHeight;

    return height === 0 ? defaultHeight : height;
}
