import React, { FC, MouseEvent, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './VideoPlayerTimeline.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { VideoPlayerTimelineProps } from './VideoPlayerTimelineProps';
import { formatMsToHmss } from "../../../../../../lib/time/format/formatMsToHmss";

function calcAndNotifyNewTimelinePercent(
    root: HTMLDivElement|null,
    event: MouseEvent<HTMLDivElement, any>,
    onMove: ((percent: number) => void)|undefined,
) {
    if (root) {
        const rootDOMRect = root.getBoundingClientRect();
        const pixelsFromLeft = event.clientX - rootDOMRect.left;
        const timelinePercent = pixelsFromLeft / (rootDOMRect.width / 100);
        onMove?.(timelinePercent);
    }
}

const convertPixelsToProcents = (line: HTMLDivElement | null, event: MouseEvent<HTMLDivElement, any>): number => {
    if (line) {
        const rootDOMRect = line.getBoundingClientRect();
        const pixelsFromLeft = event.clientX - rootDOMRect.left;
        return pixelsFromLeft / (rootDOMRect.width / 100);
    }

    return 0;
}

const convertProcentsToTime = (procents: number, duration: number): number => {
    const ms = duration / 100 * procents;

    return ms;
}

export const VideoPlayerTimeline: FC<VideoPlayerTimelineProps> = observer(props => {

    const [ lineHover, setLineHover ] = useState(false);
    const [ procentsByMouse, setProcentsByMouse ] = useState(0);

    const lineRef = useRef<HTMLDivElement>(null);

    const onLineClick = useCallback<MouseEventHandler<HTMLDivElement>>(
        event => calcAndNotifyNewTimelinePercent(
            lineRef.current,
            event,
            props.onMove,
        ),
        [],
    );

    const mouseState = useMemo(
        () => ({
            isPressed: false,
        }),
        [],
    );

    const onLineMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>(
        event => {
            mouseState.isPressed = true;

            calcAndNotifyNewTimelinePercent(
                lineRef.current,
                event,
                props.onMove,
            );
        },
        [],
    );
    const onLineMouseUp = useCallback(
        () => {
            mouseState.isPressed = false;
        },
        [],
    );

    const onLineMouseMove = useCallback<MouseEventHandler<HTMLDivElement>>(
        event => {
            if (mouseState.isPressed)  {
                calcAndNotifyNewTimelinePercent(
                    lineRef.current,
                    event,
                    props.onMove,
                );
            }

            setProcentsByMouse(convertPixelsToProcents(lineRef.current, event));
        },
        [],
    );



    return (
        <div
            className={classNames(
                styles.timeLine,
                props.className,
            )}
        >
            <div
                className={classNames(
                    styles.line,
                    lineHover ? styles.__hover : null,
                )}
                ref={lineRef}
                onClick={onLineClick}
                onMouseDown={onLineMouseDown}
                onMouseUp={onLineMouseUp}
                onMouseMove={onLineMouseMove}
                onMouseOver={() => setLineHover(true)}
                onMouseLeave={() => setLineHover(mouseState.isPressed)}
            >
                <div
                    className={styles.fillLine}
                    style={
                        props.isLivePlaying
                        ? { width: '100%' }
                        : { width: `${props.currentTime / (props.duration / 100)}%`,}
                    }
                />

                {
                    lineHover &&
                    <>
                        {
                            !props.isLivePlaying &&
                            <div
                                onMouseUp={onLineMouseUp}
                                style={{ left: `${props.currentTime / (props.duration / 100) - 0.6}%` }}
                                className={classNames(
                                    styles.studButton
                                )}
                            />
                        }
                        <div
                            style={{
                                left: `calc(${procentsByMouse}% - 35px)`,
                            }}
                            className={classNames(styles.timeInfo)}>
                            {
                                formatMsToHmss(convertProcentsToTime(procentsByMouse, props.duration))
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
});
