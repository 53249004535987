import React, { FC } from "react";
import styles from './VideoPlayerNoHlsStub.module.scss';
import classNames from "classnames";


export interface VideoPlayerNoHlsStubProps {
    className?: string;
}

export const VideoPlayerNoHlsStub: FC<VideoPlayerNoHlsStubProps> = ({className}) => {
    return (
        <>
            <div className={classNames(
                styles.videoPlayerNoHlsStub,
                styles.defaultBound,
                className
            )}>
                На данный момент ваше устройство не поддерживается.
            </div>
        </>
    )
}
