import React, { CSSProperties, FC } from "react";
import styles from './BigStateVideoAnimateIcon.module.scss';
import classNames from "classnames";

import { PlayIcon } from "../../../icons/PlayIcon";
import { PauseIcon } from "../../../icons/PauseIcon";


export enum BigStateVideoAnimateIconType {
    PAUSE = 'PAUSE',
    PLAY = 'PLAY',
};


export interface BigStateVideoAnimateIconProps {
    type: BigStateVideoAnimateIconType;
    className?: string;
    style?: CSSProperties;
}

export const BigStateVideoAnimateIcon: FC<BigStateVideoAnimateIconProps> = ({
        type,
        className,
        style
    }) => {
    return (
        <div
            style={style}
            className={classNames(
                styles.bigStateVideoAnimateIcon,
                className,
            )}
        >
            {
                type === BigStateVideoAnimateIconType.PLAY
                ? <PlayIcon  style={{ marginLeft: '3px' }} />
                :
                type === BigStateVideoAnimateIconType.PAUSE
                ? <PauseIcon />
                : null
            }
        </div>
    )
}
