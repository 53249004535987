import React, { FC } from "react";

export const RestoreScreenVideoPlayerIcon: FC<any> = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 14L4 11L4 10L3 10L3.49691e-07 10L2.62268e-07 11L3 11L3 14L4 14Z" />
            <path d="M13 14L13 11L16 11L16 10L13 10L12 10L12 11L12 14L13 14Z" />
            <path d="M12 -3.49691e-07L12 3L12 4L13 4L16 4L16 3L13 3L13 -2.38498e-08L12 -3.49691e-07Z" />
            <path d="M3 4L0 4L8.74228e-08 3L3 3L3 6.27833e-07L4 9.53674e-07L4 3L4 4L3 4Z" />
        </svg>
    );
}
