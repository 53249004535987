import React, { FC } from "react";

export const PauseVideoPlayerIcon: FC<any> = () => {
    return (
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1" height="10" fill="white"/>
            <rect x="5" width="1" height="10" fill="white"/>
        </svg>
    );
}
