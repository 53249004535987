import React, { FC } from "react";
import styles from './LiveIndicator.module.scss';
import classNames from "classnames";

export interface LiveIndicatorProps {
    className?: string;
    active?: boolean;
}

export const LiveIndicator: FC<LiveIndicatorProps> = ({
    className,
    active
}) => {
    return (
        <div
            className={classNames(
                styles.liveIndicator,
                className,
                active ? styles.__active : null
            )}
        >
            <svg
                className={styles.livePoint}
                width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" />
            </svg>

            <div className={styles.liveText}>
                live
            </div>
        </div>
    )
}
