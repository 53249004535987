import * as React from 'react'
import SponsorVideoLogo from "./SponsorVideoLogo";
import styles from './SponsorVideoLogo.module.scss'
import {Sponsor} from "../../events";

type Props = {
    sponsors: Sponsor[];
    monochrome?: boolean;
}

const SponsorVideoLogoGroup: React.FunctionComponent<Props> = ({sponsors, monochrome}) => {
    return <div className={styles.sponsorLogoGroupContainer}>
        {
            sponsors.map(sponsor => <SponsorVideoLogo
                sponsor={sponsor.name}
                url={sponsor.webpage}
                scale={sponsor.scale}
                monochrome={monochrome}
            />)
        }
    </div>
}

export default SponsorVideoLogoGroup