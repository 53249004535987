import styles from '../styles/styles.module.scss';


export const openFullscreen = (element?: HTMLElement | null) => {
    const doc = element ? element : document.documentElement as any;

    document.body.classList.add(styles.fullscreen);

    if (doc.requestFullscreen) {
        doc.requestFullscreen();
    } else if (doc.mozRequestFullScreen) { /* Firefox */
        doc.mozRequestFullScreen();
    } else if (doc.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        doc.webkitRequestFullscreen();
    } else if (doc.msRequestFullscreen) { /* IE/Edge */
        doc.msRequestFullscreen();
    }
}

export const closeFullscreen = (element?: HTMLElement | null, onExited?: () => void) => {
    const doc = document as any;

    document.body.classList.remove(styles.fullscreen);
    
    if (doc.exitFullscreen) {
        setTimeout(() => {
            doc.exitFullscreen();

            if (onExited) {
                onExited();
            }
        }, 300);

    } else if (doc.mozCancelFullScreen) { /* Firefox */
        doc.mozCancelFullScreen();

        if (onExited) {
            onExited();
        }
    } else if (doc.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        doc.webkitExitFullscreen();

        if (onExited) {
            onExited();
        }
    } else if (doc.msExitFullscreen) { /* IE/Edge */
        doc.msExitFullscreen();

        if (onExited) {
            onExited();
        }
    }
}
