import { action, observable } from 'mobx';
import { CurrentVideoPlayerHlsLevel, VideoPlayerHlsLevel } from './VideoPlayerHlsLevel';
import { VideoPlayerErrorCode } from './VideoPlayerErrorCode';
import { VideoPlayerUIState } from '../ui/state/VideoPlayerUIState';
import { VideoPlayerVideoUIStateImpl } from './VideoPlayerVideoUIStateImpl';

export class VideoPlayerUIStateImpl implements VideoPlayerUIState {

  @observable
  isReady = false;

  @observable
  isFetching = false;

  @action.bound
  markAsFetchingSourceStarted() {
    this.isFetching = true;
  }

  @action.bound
  markAsReadyToPlayAfterSourceWasFetched() {
    this.isFetching = false;
    this.isReady = true;
  }

  @observable
  hasFailure = false;

  @observable.ref
  errors: VideoPlayerErrorCode[] = [];

  @action.bound
  setErrors(
    hasFailure: boolean,
    ...errors:VideoPlayerErrorCode[]
  ) {
    this.hasFailure = hasFailure;
    this.errors = errors || [];
  };

  @observable
  isLevelFetching = true;

  @action.bound
  setIsLevelFetching(isLevelFetching: boolean) {
    this.isLevelFetching = isLevelFetching;
  }

  @observable.ref
  levels: VideoPlayerHlsLevel[] = [];

  @action.bound
  setLevels(
    levels: VideoPlayerHlsLevel[],
  ) {
    this.levels = levels
  }

  @observable.ref
  currentLevel: CurrentVideoPlayerHlsLevel|null = null;

  @action.bound
  setCurrentLevel(
    currentLevel: CurrentVideoPlayerHlsLevel|null,
    isLevelFetching?: boolean,
  ) {
    this.currentLevel = currentLevel;

    if (isLevelFetching != null) {
      this.isLevelFetching = isLevelFetching;
    }
  }

  @action.bound
  resetLevels() {
    this.levels = [];
    this.currentLevel = null;
  }

  @action.bound
  markAsReadyToPlay() {
    if (this.isFetching) {
      this.markAsReadyToPlayAfterSourceWasFetched();
    }
    this.video.setIsWaitingData(false);
  }

  video = new VideoPlayerVideoUIStateImpl();
}
