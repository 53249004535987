import React from "react";
import styles from './Header.module.scss'

class Header extends React.Component {
    render() {
        return (
            <header>
                <a href={"/"} target="_blank">
                    <img className={styles.header__logo} src="img/logo.svg" alt={"JUG Ru Group logo"}/>
                </a>
                <nav>
                    <ul className={styles.nav__links}>
                        <li><a href="/events">Митапы</a></li>
                        <li><a href="https://live.jugru.org">Конференции</a></li>
                    </ul>
                </nav>
            </header>
        );
    }
}

export default Header;
