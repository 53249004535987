import React, { FC } from "react";
import styles from './VideoPlayerStub.module.scss';
import classNames from "classnames";


export interface VideoPlayerStubProps {
    className?: string
}

export const VideoPlayerStub: FC<VideoPlayerStubProps> = ({ className }) => {
    return (
        <div
            className={classNames(styles.videoPlayerStub, styles.defaultBound, className)}>
            Здесь скоро начнется видосик...
        </div>
    )
}
