import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './Video.module.scss';
import classNames from "classnames";

import { observer } from "mobx-react";

import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { VideoControlButton } from "../VideoPlayer/ui/controls/buttons/VideoControlButton";
import { LiveIndicator } from "../components/LiveIndicator/LiveIndicator";

import { ToMiniVideoPlayerIcon } from "../../icons/ToMiniVideoPlayerIcon";
import { RestoreFromMiniVideoPlayerIcon } from "../../icons/RestoreFromMiniVideoPlayerIcon";
import { FullScreenVideoPlayerIcon } from "../../icons/FullScreenVideoPlayerIcon";
import { RestoreScreenVideoPlayerIcon } from "../../icons/RestoreScreenVideoPlayerIcon";
import { CloseMiniVideoPlayerIcon } from "../../icons/CloseMiniVideoPlayerIcon";

import { closeFullscreen, openFullscreen } from "../../../lib/fullScreen";

import { NowWatchingIndicator } from "../components/NowWatchingIndicator/NowWatchingIndicator";
import {
    BigStateVideoAnimateIcon,
    BigStateVideoAnimateIconType
} from "../components/BigStateVideoAnimateIcon/BigStateVideoAnimateIcon";
import { CallbackTimer } from "../../../lib/timers/callback-timer";

import { VideoPlayerStub } from "../components/VideoPlayerStub/VideoPlayerStub";
// import { VideoBroadcastTimeLine } from "../components/VideoBroadcastTimeLine/VideoBroadcastTimeLine";

import { FormattedMessage } from "react-intl";

import {
    addDocumentFullscreenListener, addWindowResizeListener,
    addWindowScrollListener, getFullScreenElement, getNavBarHeight,
    removeDocumentFullscreenListener, removeWindowResizeScrollListener,
    removeWindowScrollListener,
} from "./video.functions";

import { getAppConfig } from "../../../config/getAppConfig";


import { CallbackRepeater } from "../../../lib/timers/callback-repeater";

import { VideoPlayerNoHlsStub } from "../components/VideoPlayerNoHlsStub/VideoPlayerNoHlsStub";
import {Sponsor} from "../../../events";
import SponsorVideoLogoGroup from "../../SponsorVideoLogo/SponsorVideoLogoGroup";



const NAVIGATION_HEIGHT = 1;
const SAVE_VIDEO_SEEK_SECS = getAppConfig()?.saveVideoSeekSecs ?? 5;
const PING_I_M_WATCHING = getAppConfig()?.ws?.ping?.i_m_watching ?? 5;
const MINI_PLAYER_DIMENSIONS = getAppConfig()?.miniPlayer;
const PLAYER_REWIND_SECS = getAppConfig()?.playerRewindSecs ?? 10;

export interface VideoProps {
    className?: string;
    isFloatDefault?: boolean;
    useScrollMinimizer?: boolean;
    showAdvancedControls?: boolean;
    useKeysEvents?: boolean,
    onMouseMove?: () => void;
    onMouseLeave?: () => void;
    onFloatChange?: (isFloat: boolean) => void;
    url?: string;
    sponsors: Sponsor[];
}

export const Video: FC<VideoProps> = observer((
    {
        className,
        isFloatDefault,
        useScrollMinimizer,
        showAdvancedControls,
        useKeysEvents,
        onMouseMove,
        onMouseLeave,
        onFloatChange,
        url,
        sponsors
    }) => {
    const [ isFloat, setIsFloat ] = useState(false);
    const [ isFullScreen, setIsFullScreen ] = useState(false);
    const [ showCustomControls, setShowCustomControls ] = useState(false);

    const [ iMWatchingRepeater, setIMWatchingRepeater ] = useState<CallbackRepeater>();

    const [ stateForBigIcon, setStateForBigIcon ] = useState<BigStateVideoAnimateIconType | undefined>();

    const [ hideControlsAfterHoverTimer, setHideControlsAfterHover ] = useState<CallbackTimer>();

    const [ currentVideo, setSource ] = useState({
        url: '',
        withCredentials: true,
    });

    const [ seek, setSeek ] = useState<number | undefined>(undefined);

    const [ videoToLocalSaveTimer, setVideoToLocalSaveTimer ] = useState<CallbackTimer>();

    const [ videoContainerHeight, setVideoContainerHeight ] = useState<number>();

    const [ videoMiniContainerWidth, setVideoMiniContainerWidth ] = useState(480);
    const [ videoMiniContainerHeight, setVideoMiniContainerHeight ] = useState(320);

    const [ miniClosed, setMiniClosed ] = useState(false);

    const videoContainerEndRef = useRef<HTMLDivElement>(null);

    const hideControlsAfterHoverSecs = getAppConfig().hideControlsAfterHoverSecs;

    const minimizeAndRestoreByScroll = () => {
        const height = videoContainerEndRef.current?.offsetTop ?? Infinity;

        if (!isFloat && window.scrollY > height) {
            setIsFloat(true);
            onFloatChange && onFloatChange(true);

            videoContainerEndRef.current?.scrollIntoView();
        }

        if (isFloat && window.scrollY < NAVIGATION_HEIGHT) {
            setIsFloat(false);
            onFloatChange && onFloatChange(false);

            videoContainerEndRef.current?.scrollIntoView();
        }
    }

    const restoreScreen = () => {
        if (getFullScreenElement()) {
        } else {
            onMouseLeave && onMouseLeave();
            setIsFullScreen(false);
            setVideoContainerHeight(undefined);
        }
    }

    const resizeMiniPlayer = () => {
        const windowWidth = window.innerWidth;

        let width = windowWidth / MINI_PLAYER_DIMENSIONS?.widthByScreenFactor ?? 4;
        let height = width * MINI_PLAYER_DIMENSIONS?.heightByWidthFactor ?? .6;

        const minWidth = MINI_PLAYER_DIMENSIONS?.minWidth ?? 300;
        const maxWidth = MINI_PLAYER_DIMENSIONS?.maxWidth ?? 600;
        const minHeight = MINI_PLAYER_DIMENSIONS?.minHeight ?? 180;
        const maxHeight = MINI_PLAYER_DIMENSIONS?.maxHeight ?? 360;

        width = width < minWidth ? minWidth : width > maxWidth ? maxWidth : width;
        height = height < minHeight ? minHeight : height > maxHeight ? maxHeight : height;

        setVideoMiniContainerWidth(width);
        setVideoMiniContainerHeight(height);
    }

    const sendIMWatchingMessage = (
        eventId: number,
        speechId: number,
        dayId: number,
        trackId: number,
        slotId: number,
    ) => {
        const message = {
            createdAt: new Date(),

            eventId,
            dayId,
            trackId,
            slotId,
            talkId: speechId,

            lkUserId: -1,
            jugCrmId: eventId
        }

    }

    useEffect(() => {
        setIsFloat(isFloatDefault ?? false);
    }, [isFloatDefault]);

    useEffect(() => {
        if (!useScrollMinimizer) {
            return;
        }

        addWindowScrollListener(minimizeAndRestoreByScroll);

        return () => {
            removeWindowScrollListener(minimizeAndRestoreByScroll);
        }
    });

    useEffect(() => {
        resizeMiniPlayer();

        addWindowResizeListener(resizeMiniPlayer);

        return () => {
            removeWindowResizeScrollListener(resizeMiniPlayer);
        }
    });

    // useEffect(() => {
    //     setSeek(LocalStorageStateService.getVideoSeek(broadcastState?.selectedSpeech?.jEventSpeechId ?? 0, 0));
    // }, [ broadcastState?.selectedSpeech?.jEventSpeechId ]);

    useEffect(() => {
        addDocumentFullscreenListener(restoreScreen);

        return () => {
            removeDocumentFullscreenListener(restoreScreen);
        }
    }, [isFullScreen]);

    // useEffect(() => {
    //     if (broadcastState?.selectedSpeech) {
    //         selectSpeech(broadcastState?.selectedSpeech.jEventSpeechId);
    //
    //         const _repeater = new CallbackRepeater(() => {
    //             const places = broadcastService?.getSpeechPlacesBySpeechId(broadcastState?.selectedSpeech?.jEventSpeechId)
    //
    //             sendIMWatchingMessage(
    //                 broadcastState?.selectedEvent?.jugCrmEventId ?? 0,
    //                 broadcastState?.selectedSpeech?.jEventSpeechId ?? 0,
    //                 places?.dayId ?? -1,
    //                 places?.trackId ?? -1,
    //                 places?.slotId ?? -1,
    //             );
    //         }, PING_I_M_WATCHING);
    //
    //         setIMWatchingRepeater(_repeater);
    //     }
    //
    //     new CallbackTimer(() => {
    //         const places = broadcastService?.getSpeechPlacesBySpeechId(broadcastState?.selectedSpeech?.jEventSpeechId)
    //
    //         nowWatchingService?.subscribe({
    //             talkId: broadcastState?.selectedSpeech?.jEventSpeechId,
    //             slotId: places?.slotId ?? -1,
    //             trackId: places?.trackId ?? -1,
    //             dayId: places?.dayId ?? -1,
    //             eventId: broadcastState?.selectedEvent?.jugCrmEventId
    //         });
    //     }, 1);
    //
    //     return () => {
    //         if (broadcastState?.previousSelectedSpeechId) {
    //             const places = broadcastService?.getSpeechPlacesBySpeechId(broadcastState?.previousSelectedSpeechId)
    //
    //             nowWatchingService?.unsubscribe({
    //                 talkId: broadcastState?.previousSelectedSpeechId,
    //                 slotId: places?.slotId ?? -1,
    //                 trackId: places?.trackId ?? -1,
    //                 dayId: places?.dayId ?? -1,
    //                 eventId: broadcastState?.selectedEvent?.jugCrmEventId
    //             });
    //
    //             iMWatchingRepeater?.finish();
    //         }
    //     }
    // }, [broadcastState?.selectedSpeech]);

    const onSeeked = useCallback(
        (seekInMs: number) => {
            console.log(`onSeeked ${seekInMs}`);
        },
        [],
    );

    const onSeeking = useCallback(
        (seekInMs: number) => {
            console.log(`onSeeking ${seekInMs}`);
        },
        [],
    );

    const changeFloatMode = useCallback(
        () => {
            const newIsFloat = !isFloat;
            const oldIsFloat = isFloat;

            setIsFloat(newIsFloat);
            setShowCustomControls(oldIsFloat);

            if (onFloatChange) {
                onFloatChange(newIsFloat);
            }

            if (oldIsFloat) {
                setShowCustomControls(false);

                setTimeout(() => {
                    window.scrollTo({ top: 0 });
                });
            } else {
                setMiniClosed(false);

                onMouseLeave && onMouseLeave();
            }
        },
        [isFloat]
    );

    const changeFullScreen = useCallback(
        () => {
            const newIsFullScreen = !isFullScreen;

            if (newIsFullScreen) {
                openFullscreen();
                setShowCustomControls(false);

                setTimeout(() => {
                    const advancedControlsHeight = advancedControlsContainerRef.current?.offsetHeight ?? 0;
                    const newVideoHeight = window.screen.height - advancedControlsHeight;

                    setVideoContainerHeight(newVideoHeight);
                }, 100);
            } else {
                closeFullscreen(mainVideoContainerRef.current?.parentElement, () => setVideoContainerHeight(undefined));
            }

            onMouseLeave && onMouseLeave();

            setIsFullScreen(newIsFullScreen);
        },
        [isFullScreen]
    );

    const closeMini = useCallback(() => {
        setMiniClosed(true);

        if (!isFloatDefault) {
            changeFloatMode();
        }
    }, [isFloatDefault]);

    const showBigStatePlayIcon = () => {
        setStateForBigIcon(BigStateVideoAnimateIconType.PLAY);
    }

    const showBigStatePauseIcon = () => {
        setStateForBigIcon(BigStateVideoAnimateIconType.PAUSE);
    }

    const hideControlsAfterHover = () => {
        if (hideControlsAfterHoverTimer) {
            hideControlsAfterHoverTimer.stop();
        }

        const timer = new CallbackTimer(() => {
            setShowCustomControls(false);
            onMouseLeave && onMouseLeave();
        }, hideControlsAfterHoverSecs);

        setHideControlsAfterHover(timer);
    }

    const canMouseEvents = (event: any): boolean => {
        return ((videoContainerEndRef.current?.offsetTop ?? 0) - window.scrollY) > NAVIGATION_HEIGHT
            && event.clientY > NAVIGATION_HEIGHT;
    }

    const selectSpeech = (speechId: number) => {
        // broadcastService?.selectSpeech(speechId);

        // const scheduleActivityWithTrackPlayList = broadcastState?.activitiesMap.get(speechId);

        // if (scheduleActivityWithTrackPlayList) {
            // смотрим есть ли персональный плейлист,
            // смотри есть ли смещения

            // setSource({
            //     url: scheduleActivityWithTrackPlayList?.trackPlaylistUri ?? '',
            //     withCredentials: scheduleActivityWithTrackPlayList.trackPlaylistUri !== 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8'
            // });
        // }
    }
    //
    // const goToSpeech = (speechId: number) => {
    //     const id = SimpleEncodingForUrl.numbersEncode(broadcastState?.selectedSpeech?.jugCrmEventId ?? 0, speechId);
    //     router.push(NAVIGATION_ROUTES.onlineV.route, NAVIGATION_ROUTES.onlineV.createUrl(id));
    // }

    const mainVideoContainerRef = useRef<HTMLDivElement>(null);
    const advancedControlsContainerRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div
                ref={mainVideoContainerRef}
                style={
                    isFloat
                    ? { width: `${videoMiniContainerWidth}px`, height: `${videoMiniContainerHeight}px` }
                    : videoContainerHeight
                        ? { height: `${videoContainerHeight}px` }
                        : {}
                }
                onMouseMove={(event) => {
                    if (canMouseEvents(event)) {
                        onMouseMove && onMouseMove();

                        hideControlsAfterHover();

                        if (!isFloat) {
                            setShowCustomControls(true);
                        }
                    }
                }}
                onMouseLeave={(event) => {
                    onMouseLeave && onMouseLeave();

                    if (hideControlsAfterHoverTimer) {
                        hideControlsAfterHoverTimer.stop();
                    }

                    setHideControlsAfterHover(undefined);

                    setShowCustomControls(false);
                }}
                className={classNames(
                    styles.videoContainer,
                    isFullScreen ? styles.__fullScreen : null,
                    className,
                    isFloat ? styles.__float : null,
                    // (isFloat && !broadcastState?.selectedSpeech) ? styles.__hide : null,
                    (isFloatDefault && miniClosed) ? styles.__hide : null
                )}>

                {
                    currentVideo && url
                    ?
                    <VideoPlayer
                        seekInMs={seek}
                        source={url ?? ''}
                        autoPlay={true}
                        withCredentials={currentVideo.withCredentials}
                        customControls={showCustomControls}
                        fullScreen={isFullScreen}
                        playOrPauseAtClick={true}
                        playerRewindSecs={PLAYER_REWIND_SECS}
                        useKeysEvents={useKeysEvents}
                        onSeeked={onSeeked}
                        onSeeking={onSeeking}
                        onPlay={showBigStatePlayIcon}
                        onPause={showBigStatePauseIcon}
                        onCurrentTimeUpdated={(seek) => {
                            if (videoToLocalSaveTimer) {
                                return;
                            }

                            const saver = new CallbackTimer(() => {
                                saver?.stop();
                                setVideoToLocalSaveTimer(undefined);

                                // LocalStorageStateService.setVideoSeek(broadcastState?.selectedSpeech?.jEventSpeechId ?? 0, seek);
                            }, SAVE_VIDEO_SEEK_SECS);

                            setVideoToLocalSaveTimer(saver);
                        }}
                        onVideoDoubleClick={() => {
                            if (!isFloat) {
                                changeFullScreen();
                            } else {
                                changeFloatMode();
                            }
                        }}
                        onKeySpacePressed={(togglePlayPause) => {
                            togglePlayPause();
                        }}
                        noHlsRenderer={() => <VideoPlayerNoHlsStub />}
                        extControlsRenderer={() => {
                            return (
                                <>
                                    {
                                        !isFloat && !isFullScreen
                                            ? <VideoControlButton
                                                className={styles.videoControlButton}
                                                onClick={changeFloatMode}
                                            >
                                                <ToMiniVideoPlayerIcon />
                                            </VideoControlButton>
                                            : null
                                    }
                                    {
                                        isFullScreen
                                            ? <VideoControlButton
                                                onClick={changeFullScreen}
                                                className={styles.videoControlButton}
                                            >
                                                <RestoreScreenVideoPlayerIcon />
                                            </VideoControlButton>
                                            : <VideoControlButton
                                                onClick={changeFullScreen}
                                                className={styles.videoControlButton}
                                            >
                                                <FullScreenVideoPlayerIcon />
                                            </VideoControlButton>
                                    }
                                </>
                            )
                        }}
                        liveIndicatorRenderer={(isLive, isLivePlaying) => (
                            <>
                                {
                                    isLive &&
                                    <LiveIndicator
                                        active={isLivePlaying}
                                        className={classNames(
                                            styles.liveIndicator,
                                            isFloat ? styles.__right : styles.__left,
                                            showCustomControls ? styles.__showWithControls : null
                                        )} />
                                }
                            </>
                        )}
                        nowWatchingIndicatorRenderer={(isLive) => (
                            <>
                                {/*{*/}
                                {/*    !isFloat &&*/}
                                {/*    // nowWatchingModel?.nowWatchingNumber != undefined &&*/}
                                {/*    // nowWatchingModel?.nowWatchingNumber > -1 &&*/}
                                {/*    <NowWatchingIndicator*/}
                                {/*        // count={nowWatchingModel?.nowWatchingNumber}*/}
                                {/*        className={classNames(*/}
                                {/*            styles.nowWatchingIndicator,*/}
                                {/*            isLive ? styles.__showWithLive : null,*/}
                                {/*            showCustomControls ? styles.__showWithControls : null*/}
                                {/*        )} />*/}
                                {/*}*/}
                            </>
                        )}
                        inCenterIndicatorRenderer={() => (
                            <>
                                {
                                    stateForBigIcon === 'PLAY' &&
                                    <div className={ styles.bigStateVideoAnimateIcon }>
                                        <BigStateVideoAnimateIcon
                                            type={BigStateVideoAnimateIconType.PLAY}
                                        />
                                    </div>
                                }

                                {
                                    stateForBigIcon === 'PAUSE' &&
                                    <div className={ styles.bigStateVideoAnimateIcon }>
                                        <BigStateVideoAnimateIcon
                                            type={BigStateVideoAnimateIconType.PAUSE}
                                        />
                                    </div>
                                }
                            </>
                        )}
                    />
                    :
                    <VideoPlayerStub
                        className={isFloat ? styles.__stubFloat : ''}
                    />
                }

                {
                    isFloat
                        ? <>
                            <VideoControlButton
                                className={styles.videoRestoreFromMiniControlButton}
                                onClick={changeFloatMode}
                            >
                                <RestoreFromMiniVideoPlayerIcon />
                            </VideoControlButton>
                            <VideoControlButton
                                className={classNames(
                                    styles.videoCloseMiniControlButton,
                                    styles.__right,
                                )}
                                onClick={closeMini}
                            >
                                <CloseMiniVideoPlayerIcon />
                            </VideoControlButton>
                        </>
                        : null
                }
            </div>
            <div ref={videoContainerEndRef} />
            {
                showAdvancedControls &&
                <div
                    ref={advancedControlsContainerRef}
                    className={styles.videoAdvancedControls}
                >
                    <div className={styles.extControls}>
                        <div>
                            <SponsorVideoLogoGroup sponsors={sponsors} monochrome={true}/>
                        </div>

                        {/*{ videosMock.map(video => (*/}
                        {/*    <button disabled*/}
                        {/*            key={video.id} onClick={() => setSource(video)}>*/}
                        {/*        { video.name }*/}
                        {/*    </button>*/}
                        {/*)) }*/}
                        {/*<br />*/}
                        {/*<button onClick={() => setSeek(100)}>seek 100ms</button>*/}
                        {/*<button onClick={() => setSeek(72000)}>seek 72000ms</button>*/}
                        {/*<button onClick={() => setSeek(312000)}>seek 312000s</button>*/}
                    </div>
                </div>
            }
        </>
    );
});

