import React, { CSSProperties, FC } from "react";


export interface PlayIconProps {
    style?: CSSProperties;
}

export const PlayIcon: FC<any> = ({ style }) => {
    return (
        <svg
            style={style}
            height="100%" version="1.1" viewBox="0 0 36 36" width="100%">
            <path
                  d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z"
                  id="ytp-id-107" />
        </svg>
    );
}
