import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {AppRouter} from "./AppRouter";
import events from "./events";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppRouter events={events} />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
