import React, { FC } from "react";

export const RestoreFromMiniVideoPlayerIcon: FC<any> = () => {
    return (
        <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) ">
                    <path d="M19,19 L5,19 L5,5 L12,5 L12,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12 L19,12 L19,19 Z M14,3 L14,5 L17.59,5 L7.76,14.83 L9.17,16.24 L19,6.41 L19,10 L21,10 L21,3 L14,3 Z" fill="#fff" fillRule="nonzero" />
        </g>
        </g>
        </svg>
    );
}
