import { VideoPlayerVideoUIState } from '../ui/state/VideoPlayerVideoUIState';
import { action, observable } from 'mobx';

export class VideoPlayerVideoUIStateImpl implements VideoPlayerVideoUIState {

  @observable
  isLive = false;

  @action.bound
  setIsLive(isLive: boolean) {
    this.isLive = isLive;
  }

  @observable
  isLivePlaying = false;

  @action.bound
  setIsLivePlaying(isLivePlaying: boolean) {
    this.isLivePlaying = isLivePlaying;
  }

  @observable
  isShowPauseControl = false;

  @action.bound
  setIsShowPauseControl(isShowPauseControl: boolean) {
    this.isShowPauseControl = isShowPauseControl;
  }

  @observable
  isWaitingData = false;

  @action.bound
  setIsWaitingData(isWaitingData: boolean) {
    this.isWaitingData = isWaitingData;
  }

  @observable
  currentTimeInMs: number = 0;

  @action.bound
  setCurrentTimeInMs(currentTimeInMs: number) {
    this.currentTimeInMs = currentTimeInMs;
  }

  @observable
  durationInMs: number = 0;

  @action.bound
  setTotalDurationInMs(totalDurationInMs: number) {
    this.durationInMs = totalDurationInMs;
  }

  @action.bound
  setTimes(
    currentTimeInMs: number,
    totalDurationInMs: number,
  ) {
    this.currentTimeInMs = currentTimeInMs;
    this.durationInMs = totalDurationInMs;
  }

  @observable
  volume: number = 0;

  @observable
  isMuted: boolean = false;

  @action.bound
  setVolumeData(
    volume: number,
    isMuted?: boolean
  ) {
    this.volume = volume;
    if (isMuted != null) {
      this.isMuted = isMuted;
    }
  }

}
