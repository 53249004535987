import React, {FunctionComponent} from "react";

import {Event} from "./events";
import {Link} from "react-router-dom";
import {updatePageAndSend} from "./lib/ga-calls";
import {scrollToTop} from "./lib/scroll";

export interface AppMoreProps {
    events: Event[];
}

const AppMore: FunctionComponent<AppMoreProps> = ({events}) => {
    return (
        <div className="App-more">
            <div className="App-more-title">Пройдут</div>
            <div className="App-more-events">
            {events.filter(x => !x.hasPassed).map(event => (
                <Link to={'/' + event.id}
                      key={event.id}
                      onClick={() => {
                          updatePageAndSend(event.id);
                          scrollToTop();
                      }}
                      data-testid={event.id}
                >
                    <div className="App-more-event">
                        <div className="App-more-event-date">{event.date}&thinsp;—&thinsp;{event.timeStart}</div>
                        <div className="App-more-event-content">
                            <div className="App-more-event-title">{event.title}</div>
                            <div className="App-content-talk-tags">{event.tags}</div>
                            <div className="App-content-talk-bits">
                                <div className="App-content-talk-bits-tag">
                                    <span role="img" className="App-content-icon"
                                          aria-label="">{event.complexityEmoji}</span>
                                    {event.complexityTag}
                                </div>
                                <div className="App-content-talk-bits-lang">{event.langTag}</div>
                            </div>
                        </div>
                    </div>
                </Link>
                )
            )}
            </div>

            <div className="App-more-title">Прошли</div>
            <div className="App-more-events">
                {events.filter(x => x.hasPassed).map(event => (
                    <Link to={'/' + event.id}
                          key={event.id}
                          onClick={() => {
                              updatePageAndSend(event.id);
                              scrollToTop();
                          }}
                          data-testid={event.id}
                    >
                        <div className="App-more-event">
                            <div className="App-more-event-date">{event.date}&thinsp;—&thinsp;{event.timeStart}</div>
                            <div className="App-more-event-content">
                                <div className="App-more-event-title">{event.title}</div>
                                <div className="App-content-talk-tags">{event.tags}</div>
                                <div className="App-content-talk-bits">
                                    <div className="App-content-talk-bits-tag">
                                    <span role="img" className="App-content-icon"
                                          aria-label="">{event.complexityEmoji}</span>
                                        {event.complexityTag}
                                    </div>
                                    <div className="App-content-talk-bits-lang">{event.langTag}</div>
                                </div>
                            </div>
                        </div>
                    </Link>
                )).reverse()}
            </div>
        </div>
    )
}

export {AppMore}
