import React, { FC, useCallback, useState } from 'react';
import styles from './VideoPlayerQuality.module.scss';
import classNames from 'classnames';

import { observer } from 'mobx-react';

import { VideoPlayerQualityProps } from './VideoPlayerQualityProps';

import { VideoControlButton } from "../buttons/VideoControlButton";

import { CallbackTimer } from "../../../../../../lib/timers/callback-timer";


const levelXYToP = {
    [0]: '',
    [320]: '240p',
    [480]: '360p',
    [512]: '360p',
    [640]: '360p',
    [854]: '480p',
    [1280]: '720p',
    [1920]: '1080p',
    [2560]: '1440p',
    [3840]: '4K',
}

export const VideoPlayerQuality: FC<VideoPlayerQualityProps> = observer(props => {
    const levels = props.levels;
    const currentLevel = props.currentLevel;

    const [ showList, setShowList ] = useState(false);

    const qualitySelectedViewHover = useCallback(
        () => {
            setShowList(true);
        },
        []
    );

    const qualitySelectedViewLeave = useCallback(
        () => {
            setShowList(false);
        },
        []
    );

    const toggleQualitySelectedView = useCallback(
        () => {
            setShowList(!showList);
        },
        [showList]
    );

    const tryHidePopup = useCallback(() => {
        new CallbackTimer(() => {
            qualitySelectedViewLeave();
        }, 2);
    }, []);

    return (
        <VideoControlButton
            onClick={toggleQualitySelectedView}

            className={classNames(
                styles.quality,
                props.className,
            )}
        >
            <div
                title={'Levels'}
                className={styles.qualitySelectedView}>
                {(levelXYToP as any)[currentLevel?.width ?? 0]}
            </div>

            <div
                onMouseLeave={tryHidePopup}
                style={{ display: showList ? 'flex' : 'none' }}
                className={styles.qualityList}>
                { levels.map(level =>
                    <button
                        disabled={props.isLevelFetching || currentLevel?.levelIndex === level.levelIndex}
                        key={level.levelIndex}
                        className={classNames(
                            styles.levelButton,
                            currentLevel?.levelIndex === level.levelIndex && styles.__currentLevel
                        )}
                        onClick={ () => props.chooseHlsLevel?.(level.levelIndex) }
                    >
                        {(levelXYToP as any)[level.width]}
                    </button>
                ) }
            </div>
        </VideoControlButton>
    );
});
