import * as React from 'react'
import AppContent from "../AppContent";

type Props = {}

const DebugPage: React.FunctionComponent<Props> = () => {

    return (
        <div>
            <AppContent event={
                {
                    id: 'qa-survival-bias-4',
                    hlsPlaylistLink: "https://d1vpkt96p37mm7.cloudfront.net/test/data.m3u8",
                    hasPassed: false,
                    date: '22 мая',
                    timeStart: '18:00',
                    timeEnd: '19:30',
                    complexityEmoji: '🔥',
                    complexityTag: 'FLAME',
                    langTag: 'RU',
                    title: 'Шоу «Ошибка выжившего» Episode 4',
                    tags: '#qa #show #holywar',
                    description: [
                        'Ведущие поделятся новостями минувший недели, ответят на ваши вопросы и разберутся в очередном инструменте!'
                    ],
                    telegramLink: 'tg://resolve?domain=heisenbugconf',
                    speakers: [
                        {
                            photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                            name: 'Артем Ерошенко',
                            description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                            twitterAccount: 'eroshenkoam'
                        },
                        {
                            photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                            name: 'Всеволод Брекелов',
                            description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                            twitterAccount: 'brekelov'
                        }
                    ],
                    sponsors: [
                        {name: "alm_works", webpage: "https://almworks.com/", scale: 0.8},
                        {name: "infobip", webpage: "https://www.infobip.com/"}
                    ]
                }
            }/>
        </div>
    );
}

export default DebugPage