import React from "react";

export type Sponsor = {
    name: string;
    webpage: string;
    scale?: number;
}

export type Event = {
    id: string,
    fuckupUrl?: string,
    hasPassed: boolean,
    youtubeLink?: string,
    hlsPlaylistLink?: string,
    date: string,
    timeStart: string,
    timeEnd: string,
    complexityEmoji: string,
    complexityTag: string,
    langTag: string,
    title: string,
    tags: string,
    description: string[],
    showNotes?: React.ReactNode,
    qaIframeLink?: string,
    telegramLink?: string,
    surveyMonkey?: string,
    sponsors?: Sponsor[],
    speakers: {
        photoLink: string,
        name: string,
        affiliation?: string,
        description: string,
        twitterAccount?: string
    }[]
}
//test hls stream - https://d1vpkt96p37mm7.cloudfront.net/test/data.m3u8
const events: Event[] = [
    {
        id: 'java-jug-devops-vs-devs',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/Pa_tFjLyW8s',
        date: '17 апреля',
        timeStart: '12:00',
        timeEnd: '13:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Разбор доклада Баруха Садогурского «Devops для разработчиков (или против них?!)»',
        tags: '#devops #jbaruch #holywar',
        description: [
            'Впервые в своей истории JUG.ru выйдет обеими ногами в онлайн и представит вам новый формат: разбор доклада с экспертами.",' +
            'Вашему вниманию мы представили живой разбор доклада Баруха Садогурского «DevOps для разработчиков (или против них?!)».'
        ],
        speakers: [
            {
                photoLink: 'https://pbs.twimg.com/profile_images/1225192722748694528/38_2OsWm_400x400.jpg',
                name: 'Барух Садогурский',
                affiliation: 'JFrog',
                description: 'Developer Advocate в компании JFrog, автор доклада, евангелист DevOps и любимец публики',
                twitterAccount: 'jbaruch'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/6FnUGOWjrMfw5lvdZk8AfU/fdf5bbfd4f1092257a7e64d8701d5ac7/Keks.jpg',
                name: 'Антон Кекс',
                affiliation: 'Codeborne',
                description: 'Основатель компании Codeborne, гуру методологии XP и настоящий Software Craftsman',
                twitterAccount: 'antonkeks'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/HyzBkYJ2voZPl7TbYeqTg/bd639e34791e87bdc704613d292cf2c4/Anastasjev.jpg',
                name: 'Олег Анастасьев',
                affiliation: 'Одноклассники',
                description: 'Ведущий разработчик в Одноклассниках и гуру распределенных систем',
                twitterAccount: 'm0nstermind'
            },
            {
                photoLink: 'https://pbs.twimg.com/profile_images/904461024786513920/xqFuwPeM_400x400.jpg',
                name: 'Алексей Федоров',
                affiliation: 'JUG Ru Group',
                description: 'Лидер питерского JUG и организатор Java-конференций Joker и JPoint',
                twitterAccount: '23derevo'
            }
        ]
    },
    {
        id: 'js-piterjs-46',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/FMNLN5YIE_M',
        date: '23 апреля',
        timeStart: '19:00',
        timeEnd: '21:30',
        complexityEmoji: '👷‍♀️',
        complexityTag: 'PRACTICAL',
        langTag: 'RU',
        title: 'Митап PiterJS #46',
        tags: '#frontend #components #performance #css-in-ts',
        description: [
            'Митап PiterJS в формате онлайн. Поговорим про разработку сложных UI-компонентов, производительность и CSS-in-TS. Будет интересно.'
        ],
        speakers: [
            {
                photoLink: 'https://miro.medium.com/max/1000/1*Z8p9lIdAtY3HrhEvyonAkA.png',
                name: 'Роман Пятаков',
                affiliation: 'Lamoda',
                description: 'Frontend Tech Lead. Фронтенд-разработчик с опытом 4 года. Стать спикером — моя давняя мечта. Люблю петь в караоке, учусь играть на флейте. Всегда рад узнать что-то новое. Христианин.',
                twitterAccount: 'romankipper'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*9BBgQVNHVB4N4p0h0Sp9tA.png',
                name: 'Иван Затравкин',
                affiliation: 'SEMrush',
                description: 'Lead Fullstack Developer. Любитель кода со стажем. Пришёл в веб из мира ядерной физики, где будучи студентом пытался выжать максимум производительности с помощью интринсиков и GPGPU(CUDA).'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*HIhONQbU5pnHtLiI7ur-9A.png',
                name: 'Дмитрий Карловский',
                affiliation: '1С',
                description: 'Руководитель направления. Автор одного из первых чисто тайпскриптовых фреймворков.'
            }
        ]
    },
    {
        id: 'qa-survival-bias-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/Yo9tWwtdwz8',
        date: '1 мая',
        timeStart: '17:00',
        timeEnd: '18:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего»',
        tags: '#qa #show #holywar',
        description: [
            'Всеволод Брекелов и Артём Ерошенко поговорят о тестировании и ответят на вопросы зрителей.',
            'Прямо во время записи зрители шоу смогут позвонить в студию и задать вопрос в прямом эфире.'
        ],
        qaIframeLink: 'https://app.sli.do/event/1uv4mb1u',
        showNotes: (<ul>
            <li><a href="https://github.com/microsoft/playwright">Playwright</a></li>
            <li><a href="https://habr.com/ru/company/jugru/blog/494256/">Статья про Selenium</a></li>
            <li><a href="https://github.com/features/actions">GitHub Actions</a></li>
            <li><a href="https://github.com/qawolf/qawolf">QAWolf</a></li>
            <li>Что нужно знать
                <ul>система сборки(Maven/Gradle),
                    <li><a href="https://github.com/SeleniumHQ/selenium)">WebDrive</a></li>
                    <li>фреймворк для запуска тестов(TestNG, JUnit 4, JUnit 5)</li>
                    <li>подход(PageObject, KISS), инструменты(Selenide, HTMLElements, JDI, Atlas)</li>
                    <li>матчеры(AssertJ, Hamcrest), моки(MockServer, WireMock), доп. библиотеки для API(RestAssured,
                        Retrofit)
                    </li>
                </ul>
            </li>
            <li><a href="https://www.youtube.com/watch?v=MbzCd5Jpbds">Доклад про Atlas</a>,</li>
            <li><a href="https://github.com/lviggiano/owner">Библиотека Owner</a>,</li>
            <li><a href="https://habr.com/ru/company/jugru/blog/491844/">Доклад Артема про визуализацию покрытия
                автотестами</a>,
            </li>
            <li><a href="https://blog.jetbrains.com/idea/2020/03/intellij-idea-2020-1-selenium-support">IDEA Selenium
                support</a>'
            </li>
        </ul>)
        ,
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Известный спикер конференций по тестированию, эксперт по автоматизации тестирования, автор инструмента Allure/Allure 2.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ]
    },
    {
        id: 'mobile-gdg-spb-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/KoKKiwPqwRk',
        date: '6 мая',
        timeStart: '18:00',
        timeEnd: '20:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Встреча GDG SPB с обсуждением доклада Йонатана Левина',
        tags: '#career #holywar',
        description: [
            'Это первый онлайн-митап Google Developer Group St. Petersburg, и в этот раз мы разберёмся, кто такой ценный разработчик.',
            'Мы не хотим, чтобы получился обычный доклад с говорящей головой и слайдами. Поэтому у нас будет сразу три говорящих головы 😀.',
            'Итак, наши гости в студии (полный список имен мы пока оставим в секрете) будут совместно комментировать доклад Йонатана Левина с последней конференции Mobius, спорить и делиться своим опытом. А чтобы дискуссия не была скучной и однобокой, вы сможете голосовать за замену экспертов! Так вы сможете влиять на то, что происходит на экране.'
        ],
        telegramLink: 'tg://resolve?domain=gdgspb',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/6SXJPURm2kMEioa0QK6ou/f6694c29d15cce6845307067781e850e/photo_2018-04-03_14-04-05.jpg?w=600',
                name: 'Йонатан Левин',
                affiliation: 'monday.com',
                description: 'Руководитель группы разработки в monday.com, ex-GDE, активный спикер международных конференций, основатель Android Academy.',
                twitterAccount: 'parahall'
            }
        ]
    },
    {
        id: 'qa-survival-bias-2',
        youtubeLink: 'https://www.youtube.com/embed/2dyuveoW1ls',
        hasPassed: true,
        date: '8 мая',
        timeStart: '17:00',
        timeEnd: '18:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего» Episode 2',
        tags: '#qa #show #holywar',
        description: [
            'Всеволод Брекелов и Артём Ерошенко поговорят о тестировании и ответят на вопросы зрителей.'
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ],
        showNotes: (<ul>
            <li><a href="https://www.qawolf.com/">QAWolf</a></li>
            <li><a href="https://githubsatellite.com/">Конференция от GitHub</a></li>
            <li><a href="https://help.github.com/en/actions">GitHub Actions</a></li>
            <li><a href="https://github.com/microsoft/playwright">Playwright</a></li>
            <li><a href="https://habr.com/ru/company/jugru/blog/487294/">Хабр статья про Playwright</a></li>
            <li><a href="https://kotlinlang.org/docs/tutorials/edu-tools-learner.html">EduTools для изучения Kotlin</a></li>
            <li><a href="https://www.youtube.com/watch?v=dxGGZQiuD6Q/">GitHub Actions разобрали в этом выпуске с демонстрацией</a></li>
            <li><a href="https://www.youtube.com/watch?v=sBQasFy2Q3w">Kafka Streams testing</a></li>
            <li><a href="https://habr.com/ru/company/jugru/blog/499408/">Статья про тестирование Kafka стрим приложений</a></li>
            <li><a href="https://web.dev/vitals/">Web Vitals. Essential metrics for a healthy site</a></li>
            <li><a href="https://developers.google.com/web/tools/chrome-devtools/network/reference">Chrome DevTools. Network Analysis.</a></li>
            <li><a href="https://www.youtube.com/watch?v=UmEAj0u0Dsc">Доклад Артема Ерошенко про визуальное покрытие тестов.</a></li>
            <li><a href="https://github.com/qagid/github-actions">Демо GitHub Actions.</a></li>
        </ul>)
    },
    {
        id: 'java-jug-spring-the-builder-1',
        youtubeLink: 'https://www.youtube.com/embed/rd6wxPzXQvo',
        hasPassed: true,
        date: '11 мая',
        timeStart: '17:00',
        timeEnd: '19:16',
        complexityEmoji: '👷',
        complexityTag: 'PRACTICAL',
        langTag: 'RU',
        title: 'JUG «Spring Построитель» с Евгением Борисовым',
        tags: '#spring #builder #ripperislive',
        description: [
            "Оглядываясь на весь свой опыт разработки, я могу с увернностью сказать, что людям лучше всего даются те технологии, аналог которых они писали ещё до появления этих технологий. Вы хотите понимать Spring на уровне их создателей, перестаньте его потрошить, создайте его сами.",
            "Я понимаю, что в рамках данного доклада у меня не будет возможности написать Spring Framework, который есть сегодня и который писался многими людьми и Йоргеном Холлером на протяжении почти 20 лет. Но я успею реализовать все основные механизмы и концепции, необходимые для понимая ядра, на которое нанизывается всё остальное.",
            "Доклад почти весь будет состоять из лайв-кодинга, мы возмём за основу проекта какую-нибудь весёлую, но типичную задачу. Но чтобы реализовать её красиво, без копи-паста и прочих костылей, нам понадобится ифраструктура, даюшая инверсию контроля, dependency injection, AOP, возможность конфигурировать объекты, не меняя код, возможность расширения в будущем и т.п.",
            "По окончании доклада вы уйдёте, не только понимая, зачем нужен Spring, как им правильно пользоваться и как он устроен, но и получите наглядный сжатый опыт написания подобных систем."
        ],
        telegramLink: 'tg://resolve?domain=jug_ru',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/jwKvsDAhAkaMIiueikasI/291331aeeb36c14b29e08cf16c6dfbf2/Borisov__1_.png?w=1920',
                name: 'Евгений Борисов',
                affiliation: 'Epam',
                description: 'Евгений пишет на Java почти 20 лет. За эти годы он принял участие в большом количестве энтерпрайз проектов, несколько лет был свободным консультантом, открыл свой стартап провёл сотни тренингов и десятки докладов, а сегодня возглавляет израильский отдел разработки в Epam',
                twitterAccount: 'jekaborisov'
            }
        ],
        sponsors: [
            {name: "alm_works", webpage: "https://almworks.com/", scale: 0.8},
            {name: "infobip", webpage: "https://www.infobip.com/"}
        ]
    },
    {
        id: 'js-holy-breakfast-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/xJw7iQKp2cI',
        date: '12 мая',
        timeStart: '11:00',
        timeEnd: '12:00',
        complexityEmoji: '🐱',
        complexityTag: 'FUN',
        langTag: 'RU',
        title: 'Тяжелое утро с HolyJS #1',
        tags: '#holyjs #morning #show',
        description: [
            "Почему все технические блоги и подкасты выходят вечером, когда после тяжелого рабочего дня голова идет кругом?",
            "Конференция HolyJS исправляет положение и представляет новое утреннее разговорное шоу. Программный комитет HolyJS встретится со спикерами конференции в виртуальной студии, чтобы помочь вам проснуться за чашечкой кофе.",
            "Никакого хардкора и мозголомных тем: обсуждать будем инструменты, задачки, технологии и околоайтишные темы,— так, чтобы рабочий день начинался с приятного!"
        ],
        telegramLink: 'tg://resolve?domain=holyjsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/2Sr3POKsCQmEecYMe2eSIs/c55a1eb684aadf07411608e03235a735/avatar4.jpg?w=340',
                name: 'Евгений Кот',
                affiliation: 'Wrike',
                description: 'Долгое время занимался .NET, Microsoft stack. Работал в Quest Software, после этого в Dell, где начал заниматься фронтендом. Прошёл путь от CoffeeScript + Angular 1.0 до Dart + Angular 2.0. На данный момент трудится в Wrike, высоконагруженном SaaS-приложении с очень богатым клиентским кодом. Команда Wrike пишет на Dart и Angular 2.0 и стремится быть up-to-date со всеми современными течениями в мире веб.',
                twitterAccount: 'bunopus'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4WhNI6NhtSEawOGM0gcCi6/d7dfc7a60a9a5e7785fc1d951d88b385/zolotykh.jpg?w=340',
                name: 'Алексей Золотых',
                affiliation: 'Infobip',
                description: 'Пишет на JavaScript c 2007 года. До перехода к JS работал верстальщиком, сверстал более 100 сайтов. Писал на jQuery, Backbone.js, Angular 1, Angular 2, React, Ext JS, PolymerJS. Любит пользовательские интерфейсы и кофе.',
                twitterAccount: 'zolotyh'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/3kxJvN0P7TV8b5QC6Fjxy8/3d263d9bbb7de00dded29d716f4b462b/Andrey_Lushnikov.jpg?w=340',
                name: 'Андрей Лушников',
                affiliation: 'Microsoft',
                description: 'Former Chrome Puppeteer team lead. Currently in Microsoft Playwright team.',
                twitterAccount: 'aslushnikov'
            }
        ]
    },
    {
        id: 'mobile-gdg-spb-live-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/JkG-poKX45A',
        date: '13 мая',
        timeStart: '19:00',
        timeEnd: '20:30',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'GDG live: Будущее мобильной разработки',
        tags: '#future',
        description: [
            "Продолжаем традицию обсуждать насущные вопросы мобильной разработки и на этот раз мероприятие пройдет совместно с GDG Moscow и мы пройдемся по текущему состоянию индустрии и попробуем предсказать будущее.",
            "Итак, мы постараемся ответить на вопросы:",
            "— Что будет происходить в разработке технологически?",
            "— Какие подходы будут развиваться, а какие уйдут в туман?",
            "— Фреймворки, новые релизы продуктов и прочее!"
        ],
        telegramLink: 'tg://resolve?domain=gdgspb',
        speakers: [
            {
                photoLink: 'https://habrastorage.org/webt/px/lv/bz/pxlvbz2hnxny6qje1fscki6bwra.png?w=340',
                name: 'Матвей Мальков',
                affiliation: 'Google',
                description: 'разрабатывает Jetpack Compose в Google. Известный спикер на технических конференциях.',
                twitterAccount: 'matvei_jj'
            },
            {
                photoLink: 'https://habrastorage.org/webt/ug/tp/fd/ugtpfdxdulsyrjc4ghxwoqgpsrw.png?w=340',
                name: 'Александр Денисов',
                affiliation: 'EPAM',
                description: 'Ведущий подкастов Mobile People Talks, Flutter Podcast, активный спикер и популяризатор современных подходов в разработке и Flutter.',
                twitterAccount: 'ShuregDenisov'
            },
            {
                photoLink: 'https://habrastorage.org/webt/qh/af/3z/qhaf3zjm32ghepkbcf5ahjy0aak.png?w=340',
                name: 'Артем Зиннатуллин',
                affiliation: 'Lyft',
                description: 'Девелопер в Lyft, известен тем, что до глубины разбирается во всех инструментах, с которыми ему приходится работать.',
                twitterAccount: 'artem_zin'
            },
            {
                photoLink: 'https://habrastorage.org/webt/9z/j1/pg/9zj1pglch_jpaeb9ty1puqsyc4a.png?w=340',
                name: 'Владимир Тагаков',
                affiliation: 'Lyft',
                description: 'Работает в архитектурной команде в Lyft. Интересуется тулами, ex-Яндекс.'
            }
        ]
    },
    {
        id: 'mobile-mobius-breakfast-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/DIeCauHPq0A',
        date: '14 мая',
        timeStart: '11:00',
        timeEnd: '12:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: '"Hello, world!" с Mobius #1',
        tags: '#mobius #morning #show',
        description: [
            "Обычно технические подкасты выходят по вечерам, когда после тяжелого рабочего дня ни у кого уже нет сил вникать в детали. Мы решили исправить эту ситуацию и запустили утреннее шоу \"Hello, world!\" c Mobius. Вести его будут участники программного комитета конференции: лид проекта Redmadrobot Петр Козлов и Android software engineer в Revolut Константин Куликов.",
            "Гостем первой программы, которая пройдет 14 мая в 11:00, станет Константин Цховребов из JetBrains. Он расскажет о том, каково перейти с позиции head of Android в разработчика, как работать с компилятором после Android, а также поделится инсайдами из JetBrains.",
            "Кроме того, Костя расскажет немного подробностей о своем докладе «Запускаем типичный Android-клиент мультиплатформенно: путь практика», который он подготовил для Mobius 2020 Piter. Он будет посвящен тем проблемам, с которыми Костя столкнулся при переводе своего проекта GitFox на мультиплатформу в JetBrains."
        ],
        telegramLink: 'tg://resolve?domain=mobiusconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/7gDONILz3XQUOxFksCVCBw/41331ba45123cd549a9c0f1b3b621a2d/Petr_Kozlov.jpg?w=340',
                name: 'Петр Козлов',
                affiliation: 'Redmadrobot',
                description: 'Лид проекта Redmadrobot.',
                twitterAccount: 'xd720p'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/5vklgASOSS0sXvjVVCa0AL/b88a8aab38d759a2e60376ffad483d5b/Konstantin_Kulikov.jpg?w=340',
                name: 'Константин Куликов',
                affiliation: 'Revolut',
                description: 'Android software engineer в Revolut.',
                twitterAccount: 'Semper_Viventem'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/5yleZeQ9jPoROA02D44vLO/b12365a9faf969ab921b5096e228d685/Tskhovrebov.jpg?w=340',
                name: 'Константин Цховребов',
                affiliation: 'JetBrains',
                description: '',
                twitterAccount: ''
            }
        ]
    },
    {
        id: 'qa-survival-bias-3',
        youtubeLink: 'https://www.youtube.com/embed/dxGGZQiuD6Q',
        hasPassed: true,
        date: '15 мая',
        timeStart: '18:00',
        timeEnd: '19:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего» Episode 3',
        tags: '#qa #show #holywar',
        description: [
            'Ведущие в этот раз разберутся в инструментах для запуска тестов, таких как Jenkins, GitLab, TeamCity, GitHub Actions ' +
            'и других. Вместе с вами выберут наиболее популярные, покажут пример на GitHub Actions, ответят на ваши вопросы и разыграют приз:' +
            ' годовую лицензию на любую IDE от компании JetBrains. '
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        surveyMonkey: 'https://ru.research.net/r/meetup_150520_bias',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ],
        sponsors: [
            {name: "jetbrains", webpage: "https://jetbrains.com/", scale: 2},
        ],
        showNotes: (<ul>
            <li><a href="https://github.com/awslabs/cdk8s/">Amazon анонсировали cdk8s</a></li>
            <li><a href="https://devoops-moscow.ru/2020/msk/talks/2un1bptbuhtkmql67vnaoo/">Доклад про Pulumi, альтернатива cdk8s</a></li>
            <li><a href="https://up9.com/">Инструмент up9</a></li>
            <li><a href="https://blog.jetbrains.com/teamcity/2020/05/new-teamcity-notifier-browser-extension/">TeamCity extension</a></li>
            <li><a href="http://share.firefox.dev/lighthouse">Lighthouse в Firefox</a></li>
            <li><a href="https://developers.google.com/web/tools/lighthouse">Lighthouse</a></li>
            <li><a href="https://www.sitespeed.io/">Sitespeed</a></li>
            <li><a href="https://www.youtube.com/watch?v=sBQasFy2Q3w">Kafka Streams testing</a></li>
            <li><a href="https://habr.com/ru/company/jugru/blog/499408/">Статья про тестирование Kafka стрим приложений</a></li>
            <li><a href="https://web.dev/vitals/">Web Vitals. Essential metrics for a healthy site</a></li>
            <li><a href="https://developers.google.com/web/tools/chrome-devtools/network/reference">Chrome DevTools. Network Analysis.</a></li>
            <li><a href="https://www.youtube.com/watch?v=UmEAj0u0Dsc">Доклад Артема Ерошенко про визуальное покрытие тестов.</a></li>
            <li><a href="https://github.com/qagid/github-actions">Демо GitHub Actions.</a></li>
        </ul>)
    },
    {
        id: 'dotnet-spb-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/LX8kJWImAQU',
        date: '15 мая',
        timeStart: '20:00',
        timeEnd: '22:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Встреча SPbDotNet № 59',
        tags: '#SpbDotNet, #DotNetRu, #DotNet, #Unity, #GameDev',
        description: [
            "Истинным знаниям не страшны преграды. Оковы изоляции не сдержат доблестных носителей силы. Встречайте первую прорывную встречу сообщества в формате online. Событие будет полностью посвящено разработке игр под Unity. Мы пройдёмся от полного нуля до работающих примеров, осуществляя ваши детские мечты в прямом эфире. За насыщенный графоний отвечает команда конференции DotNext.",
            "Программа встречи:",
            "20:00 – 21:00 Артём Лунев (Наука) «Разработка игр на движке Unity»",
            "21:00 – 21:10 Перерыв",
            "21:10 – 22:00 Александр Кугушев (EPAM) «GameDev как хобби»"
        ],
        telegramLink: 'tg://resolve?domain=SpbDotNetChat',
        speakers: [
            {
                photoLink: 'https://habrastorage.org/webt/ll/el/1s/llel1sp0psdclxzfac9xqf4pfha.png?w=340',
                name: 'Артём Лунев',
                affiliation: 'Наука',
                description: 'Артём занимается геймдевом в свободное время более 4-х лет. Начал изучать Unity пока учился в университете. За это время зарелизил 2 проекта под мобильные платформы. Приходилось работать как с издателем так и без него. Около года занимался разработкой своей игры вместе с командой. Из-за кучи ошибок и переоценки сил проект до релиза не дошёл, но остался большой багаж беcценного опыта. По работе приходится сталкиваться с Delphi и SQL. Пишет всякие штуки для нефтеперерабатывающего завода.',
                twitterAccount: ''
            },
            {
                photoLink: 'https://images.ctfassets.net/9n3x4rtjlya6/2Icv3CeR8cUkeWq4q8cOco/c78a8b6bf2c9053e2334a7335cc7148d/Kugushev.jpg?w=340',
                name: 'Александр Кугушев',
                affiliation: 'EPAM',
                description: 'Lead Software Engineer в компании EPAM, где разрабатывает облачную платформу для рынка частных инвестиций (private capital market). В свободное от AWS, Docker и остального кровавого энтерпрайза время занимается обучением студентов.',
                twitterAccount: ''
            }
        ]
    },
    {
        id: 'js-holy-breakfast-2',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/ZIE2_c6MRZ0',
        date: '19 мая',
        timeStart: '11:00',
        timeEnd: '12:00',
        complexityEmoji: '🐱',
        complexityTag: 'FUN',
        langTag: 'RU',
        title: 'Тяжелое утро с HolyJS #2',
        tags: '#holyjs #morning #show',
        description: [
            "Гостем второго выпуска нового шоу HolyJS станет GraphQL-ниндзя, разработчик интернет-продуктов и " +
            "опенсорс-мейнтейнер Павел Черторогов.",
            "С 2015 года Павел делает основной упор в работе на изоморфные приложения, а летом 2016 он начал разработку " +
            "GraphQL-compose (генератора GraphQL-схем) в опенсорсе. ",
            "В «Тяжелом утре» ребята будут говорить, само собой, о GraphQL и GraphQL-compose, а также легко и непринужденно перемоют косточки бэкендерам.",
            "Кстати, на HolyJS 2020 Piter Павел в своем докладе покажет, что делать, когда вам подходит GraphQL, " +
            "но весь бэк уже лет десять как написан на REST API, и переписать его просто нереально."
        ],
        telegramLink: 'tg://resolve?domain=holyjsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4SA2g93yacTLEDGJrhjpX7/948d71f542d67504527e62fb3818dc86/Chertorogov.jpg?w=340',
                name: 'Павел Черторогов',
                affiliation: 'PS.kz',
                description: 'Author of graphql-compose (tool that greatly helps to construct complex GraphQL schema on NodeJS)',
                twitterAccount: 'nodkz'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/2Sr3POKsCQmEecYMe2eSIs/c55a1eb684aadf07411608e03235a735/avatar4.jpg?w=340',
                name: 'Евгений Кот',
                affiliation: 'Wrike',
                description: 'Долгое время занимался .NET, Microsoft stack. Работал в Quest Software, после этого в Dell, где начал заниматься фронтендом. Прошёл путь от CoffeeScript + Angular 1.0 до Dart + Angular 2.0. На данный момент трудится в Wrike, высоконагруженном SaaS-приложении с очень богатым клиентским кодом. Команда Wrike пишет на Dart и Angular 2.0 и стремится быть up-to-date со всеми современными течениями в мире веб.',
                twitterAccount: 'bunopus'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4WhNI6NhtSEawOGM0gcCi6/d7dfc7a60a9a5e7785fc1d951d88b385/zolotykh.jpg?w=340',
                name: 'Алексей Золотых',
                affiliation: 'Infobip',
                description: 'Пишет на JavaScript c 2007 года. До перехода к JS работал верстальщиком, сверстал более 100 сайтов. Писал на jQuery, Backbone.js, Angular 1, Angular 2, React, Ext JS, PolymerJS. Любит пользовательские интерфейсы и кофе.',
                twitterAccount: 'zolotyh'
            }
        ]
    },
    {
        id: 'hydra-heads-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/x7vJeKwArdk',
        date: '19 мая',
        timeStart: '20:00',
        timeEnd: '21:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Шоу «Головы Гидры» #1',
        tags: '#hydraconf #concurrency #ЖЗЛ #sptdc',
        description: [
            'У конференции по разработке многопоточных и распределенных систем Hydra появилось свое онлайн-шоу — «Головы гидры».',
            'Участники программного комитета конференции будут регулярно встречаться в эфире, чтобы обсудить новости индустрии, алгоритмы и структуры данных, модели памяти, компиляторы и рантайм.',
            'В этом выпуске ведущие вспомнят как прошла первая Hydra, и расскажут, чем конференция в этом году будет отличаться от предыдущей, а также подробно коснутся тем будущей Hydra и ее спикеров. Кроме того, Никита Коваль подробно расскажет о тех докладчиках, которых ведет он лично, и о своем докладе, посвященном SegmentQueueSynchronizer — верифицированной с помощью фреймворка Iris для прувера Coq абстракции для программирования примитивов синхронизации.'
        ],
        telegramLink: 'tg://resolve?domain=hydraconf',
        surveyMonkey: 'https://ru.research.net/r/meetup_190520',
        speakers: [
            {
                photoLink: 'https://pbs.twimg.com/profile_images/904461024786513920/xqFuwPeM_400x400.jpg',
                name: 'Алексей Федоров',
                affiliation: 'JUG Ru Group',
                description: 'Лидер питерского JUG и организатор Java-конференций Joker и JPoint',
                twitterAccount: '23derevo'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5aYfREZK1x2E0o598oGsQd/0a60f37b8919975d0c36e44b8a9d0425/Aksenov.jpg?w=200',
                name: 'Виталий Аксенов',
                affiliation: 'ITMO University',
                description: 'Сотрудник кафедры «Компьютерные Технологии» Университета ИТМО.',
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/3RpkoML9L5KEkJp71HbuuH/a2428e1518a6040779aa88aa1de3aff3/koval.jpg?w=200',
                name: 'Никита Коваль',
                affiliation: 'JetBrains',
                description: 'Разрабатывает алгоритмы для корутин в команде Kotlin.',
                twitterAccount: 'nkoval_'
            }
        ]
    },
    {
        id: 'qa-heisenbug-breakfast-1',
        fuckupUrl: 'heisenbug-breakfast-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/84ps-GmOKTg',
        date: '20 мая',
        timeStart: '11:00',
        timeEnd: '11:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Тяжелое утро с Heisenbug #1',
        tags: '#games #amazon #industry',
        description: [
            "«Тяжелое утро с Heisenbug» — это встречи программного комитета конференции со спикерами в виртуальной студии. Никакого хардкора и мозголомных тем: только инструменты, задачки, технологии и новости IT.",
            "Ведущие эфира: тестировщик со стажем Михаил Чумаков и DM в EPAM Авенир Воронов. Гость шоу, инженер по автоматизации в Amazon на игровом движке Lumberyard, спикер Heisenbug 2019 MSK Артем Несиоловский, обсудит с ведущими новости игровой индустрии и IT, а также покажет, какие подходы и инструменты используют при разработке игр."
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/1g2xxe4E88Kis0KeWAw6U8/53dad8b6db56a016c784fb48de0531ec/c47105e5-75b0-4e2f-a970-a82375983016.jpg?w=200',
                name: 'Михаил Чумаков',
                affiliation: '',
                description: 'Профессионально занимается тестированием более 10 лет, не профессионально — остальное время. В течение карьеры работал в огромных корпорациях и маленьких стартапах. Собирал команды, автоматизировал, обучал коллег, отвечал за качество. Любит самообразовываться и постоянно изучать что-то новое. Сейчас Михаил продолжает образование в одном из ВУЗов Канады.',
                twitterAccount: 'ainomc1'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/3ATywAPoYXADt4e3xSMHD3/5a540263d70c79ebed6eba59f716e65d/100065_668442024_Avenir_Voronov.JPG?w=200',
                name: 'Авенир Воронов',
                affiliation: 'EPAM',
                description: 'DM в компании EPAM. Работал разработчиком в HP, Luxoft, Motorola. Прошел путь от программиста до CTO. Ведет тренинги и участвует в организации конференций Jug Ru Group. Есть кот.',
                twitterAccount: 'avenirvoronov'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/HSgO1Kof3sRCHsZ5Bnhi4/746dbe25cfcdd61427c88fbc3dd3ac7d/nesiolovsky.jpg?w=300',
                name: 'Артём Несиоловский',
                affiliation: 'Amazon',
                description: 'Окончил МИФИ, фак. Кибернетики. 8+ лет в разработке и тестировании, 5 из них в играх. Экс сотрудник Nvidia, Yandex; в данный момент - инженер по автоматизации в Amazon на игровом движке Lumberyard.',
                twitterAccount: 'yinmute'
            },
        ]
    },
    {
        id: 'java-jpoint-coffee-1',
        fuckupUrl: 'jpoint-coffee-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/euoqwouDrSc',
        date: '20 мая',
        timeStart: '12:00',
        timeEnd: '12:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Первая чашка кофе с JPoint #1',
        tags: '#java #news',
        description: [
            "«Первая чашка кофе с JPoint» — это встречи программного комитета конференции с ее спикерами, на которых не будет никакого хардкора: только инструменты, задачки, технологии и околоайтишные темы.",
            "Первый выпуск шоу, будут вести основатель встреч JUG.MSK Андрей Когунь и Developer Advocate проекта GraalVM в OracleLabs Олег Шелаев. Их гостем станет Владимир Ситников, который уже десять лет работает над производительностью и масштабируемостью NetCracker OSS. Они обсудят в эфире новости из мира Java, рассмотрят инструменты, которыми Владимир пользуется для решения рабочих задач, и поговорят о том, как сделать идеальный доклад на конференции. "
        ],
        telegramLink: 'tg://resolve?domain=jpointconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/nOqNSu66FcGT0MwzHuWMe/38d4e7aae96ac8a7c1d4fb9353243215/Selajev.jpg',
                name: 'Олег Шелаев',
                affiliation: 'OracleLabs, Oracle',
                description: 'Developer advocate for GraalVM at OracleLabs, Oracle.',
                twitterAccount: 'shelajevoleg'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7FnTvLwAqWekJIlkSb5mSY/90a4fc100f1db0dd83816289fc37d63e/Kogun.jpg',
                name: 'Андрей Когунь',
                affiliation: 'КРОК',
                description: 'Основатель и организатор встреч московского сообщества Java-разработчиков JUG.MSK.',
                twitterAccount: 'andrei_kogun'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5mzA6ao6QF4MnwWTdLdu55/7bf2f4e0c1ebe2ef4e5d7b5f9df6e389/Sitnikov.jpg',
                name: 'Владимир Ситников',
                affiliation: 'Netcracker',
                description: 'Десять лет работает над производительностью и масштабируемостью NetCracker OSS — ПО, используемого операторами связи для автоматизации процессов управления сетью и сетевым оборудованием. Увлекается вопросами производительности Java и Oracle Database. Автор более десятка улучшений производительности в официальном PostgreSQL JDBC-драйвере.',
                twitterAccount: 'vladimirsitnikv'
            },
        ]
    },
    {
        id: 'mobile-mobius-breakfast-2',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/A9fnoOst8ps',
        date: '21 мая',
        timeStart: '11:00',
        timeEnd: '12:00',
        complexityEmoji: '🐱',
        complexityTag: 'FUN',
        langTag: 'RU',
        title: '"Hello, world!" с Mobius #2',
        tags: '#mobius #morning #show',
        description: [
            "Во втором выпуске участники программного комитета Mobius Николай Ашанин и Алексей Кудрявцев ждут на утреннюю беседу Владислава Фица из Algolia, который работает над open source-инструментами для реализации поисковых интерфейсов на мобильных платформах.",
            "В эфире они обсудят нюансы работы во Франции, open source в стартапе за рубежом и особенности мобильного поиска."
        ],
        telegramLink: 'tg://resolve?domain=mobiusconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/6FtpzOmVoW8M6SGy64WCoE/d59d4c8ba6e63830befd69ae2d7a60f4/IMG_5142.JPG?w=200',
                name: 'Николай Ашанин',
                affiliation: 'Кошелек',
                description: 'Руководитель клиентской разработки в компании Кошелёк. Более 10 лет опыта в IT, в основном в мобильной разработке, также имеет опыт в облачных технологиях и создании архитектуры комплексных систем. Ведет блог для начинающих Software Architects.',
                twitterAccount: 'n_ashanin'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/fszs5DSiJvnjIbvlW7HHi/a4f30c28c8d5e3ee48c33469fadb50b2/kudryavtsev.jpg?w=200',
                name: 'Алексей Кудрявцев',
                affiliation: 'Avito',
                description: 'iOS performance engineer в Авито. Любит, чтобы пользователь получал нужный результат максимально быстро и красиво. Активный участник iOS-сообщества. Ведет подкаст AppsCast и телеграм-канал cocoa_powder.',
                twitterAccount: 'west0r'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/3loK8xekW2FENk7f8IFsYa/bff3f2601854fd25f2bc4bbe601a2ac8/Vladislav_Fits.png?w=200',
                name: 'Владислав Фиц',
                affiliation: 'Algolia',
                description: 'В iOS-разработке с 2011 года. Закончил магистратуру в Париже, после чего решил обосноваться во Франции и продолжил карьеру в стартапах. В 2018 году присоединился к команде Algolia, где работает над open source-инструментами для реализации поисковых интерфейсов на мобильных платформах. Очень скучает по родному Петербургу и наведывается туда при каждом удобном случае.',
                twitterAccount: 'vfitc'
            }
        ]
    },
    {
        id: 'devops-brunch-1',
        hasPassed:  true,
        youtubeLink: 'https://www.youtube.com/embed/FLvpN9I15S0',
        date: '21 мая',
        timeStart: '18:00',
        timeEnd: '18:40',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Шоу "DevOops в рабочий полдник" №1',
        tags: '#devoops',
        description: [
            "DevOops представляет новое вечернее шоу «DevOops в рабочий полдник» с участниками программного комитета и спикерами конференции. Никакого хардкора: только дружеская беседа, околайтишные новости и, конечно же, DevOps.",
            "В эфир к Баруху Садогурскому, Андрею Когуню и Мише Дружинину придет постоянный спикер DevOops, сооснователь подкаста «Разбор Полетов», Developer Advocate в компании Confluent Виктор Гамов. Они обсудят Kafka, Stream processing, DevOps и даже порасспрашивают Виктора про качалку. В качестве бонуса Виктор покажет, как организована его домашняя студия.",
            "Кстати, на DevOops 2020 Moscow Виктор выступит с докладом про то, как использовать Kotlin DSL для Kubernetes для настройки деплоя."
        ],
        telegramLink: 'tg://resolve?domain=devOopsconf',
        surveyMonkey: 'https://ru.research.net/r/meetup_210520',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1LoXqAs2dGOWIUC2cMooK4/057601cd55f057b4a437a4e2c2bf8fa5/2018-03-13_19.35.48.jpg?w=600',
                name: 'Барух Садогурский',
                affiliation: 'JFrog',
                description: 'Поскольку «религия не позволяет» быть евангелистом, Барух — developer advocate в компании JFrog и делает в жизни ровно 3 вещи: зависает с разработчиками Bintray и Artifactory, пописывает для них код и рассказывает о впечатлениях в блогах и на конференциях, таких как JavaOne, Devoxx, OSCON, конечно же, JPoint и Joker, да и многих других. И так более десяти лет подряд.',
                twitterAccount: 'jbaruch'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/6wJHO9pVTPnS51pJr7enDj/fe4eae2c627af16998c39b1b4f995b20/Druzhinin.jpg?w=200',
                name: 'Михаил Дружинин',
                affiliation: 'Amazon',
                description: 'Технический менеджер в компании Amazon. 13 лет в IT. Тренер по архитектуре и Java-разработке. Управлял проектами как Team Lead и PM. Участвовал во многих проектах в роли архитектора.',
                twitterAccount: 'xomyakus'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7FnTvLwAqWekJIlkSb5mSY/90a4fc100f1db0dd83816289fc37d63e/Kogun.jpg',
                name: 'Андрей Когунь',
                affiliation: 'КРОК',
                description: 'Основатель и организатор встреч московского сообщества Java-разработчиков JUG.MSK.',
                twitterAccount: 'akogun'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5ZLE2iKkxPaZUngCs9xyST/c1ce6a53377791b2cdb80399ac5136d7/gamov.jpg?w=200',
                name: 'Виктор Гамов',
                affiliation: 'Confluent',
                description: 'Виктор Гамов — сооснователь и лидер любимого многими программистами правильного подкаста «Разбор Полётов». По совместительству является Developer Advocate в компании Confluent, которая разрабатывает платформу на базе Apache Kafka. Помогает клиентам в проектировании и разработке распределенных систем обработки потоковых данных. Соавтор книги «Enterprise Web Development» издательства O\'Reilly.' +
                    'В свободное от работы время Виктор не забывает про качалку и бицуху. Является завсегдатаем конференций JUG Ru Group (JPoint, Joker, JBreak) и других международных конференций (JavaOne, Devoxx, OSCON, Qcon). Пишет в Twitter как @gamussa. Ведет канал про Kafka в Telegram.',
                twitterAccount: 'gamussa'
            },
        ]
    },
    {
        id: 'js-piterjs-47',
        hasPassed: true,
        // hlsPlaylistLink: 'https://d1vpkt96p37mm7.cloudfront.net/piterjs47/piterjs47.m3u8',
        youtubeLink: 'https://www.youtube.com/embed/pev6g_oysUs',
        date: '21 мая',
        timeStart: '19:00',
        timeEnd: '21:30',
        complexityEmoji: '👷‍♀️',
        complexityTag: 'PRACTICAL',
        langTag: 'RU',
        title: 'Митап PiterJS #47',
        tags: '#frontend #components #performance #css-in-ts',
        description: [
            'Митап PiterJS в формате онлайн.  В этот раз гости эфира обсудят cordova, нейронные сети в JavaScript и starter kit для резюме.',
            'Расписание:',
            '19:10–19:30 — «Cordova + React = 🤎»: ',
            'разработчик интерфейсов Владимир Ульянов расскажет про то, как выглядит сегодня старенькая cordova, и что можно получить, если совместить ее с современными технологиями.',
            '19:45–20:15 — «Нейронные сети в JS: А зачем?»: ',
            'создатель нескольких pet-проектов и участник исследований по T5 сетям Кирилл Сергеев расскажет про общее состояние нейронных сетей на данный момент. Как язык и инфраструктура JavaScript — прекрасный инструмент для выполнения задач последней мили в нейронных сетях. Возможные варианты развития JS в сфере применения нейронных сетей.',
            '20:30–21:00 — «Tree — единый AST чтобы править всеми»: ',
            'Основатель ТехноГильдии HyOO Дмитрий Карловский вместе со слушателями проанализирует существующие текстовые форматы данных и с нуля спроектирует новый, лишённый уже привычных нам всем недостатков.'
        ],
        telegramLink: 'tg://resolve?domain=piterjsflood',
        speakers: [
            {
                photoLink: 'https://i.ibb.co/c1fBmXW/h-J2-Qq-Oke-400x400.jpg',
                name: 'Михаил Полубояринов',
                affiliation: 'SEMrush',
                description: 'Работаю в SEMrush, организатор PiterJS, в разработке с 2004 года.',
                twitterAccount: 'mike1pol'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*sSTiF4f4rtcAlZm-ktnKRQ.png',
                name: 'Владимир Ульянов',
                affiliation: 'GridDynamics',
                description: 'Занимаюсь разработкой интерфейсов, и серверов для этих интерфейсов. Люблю пробовать всё необычное и интересное :)',
                twitterAccount: 'lionskape'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*8VZW7RG8M0-CQsHS85Ih1w.png',
                name: 'Кирилл Сергеев',
                affiliation: 'Awwcor',
                description: 'В университете писал диплом по нейронным сетям. С давнего времени наблюдаю за этой сферой. Делал несколько Pet-проектов. Участвовал в исследованиях по T5 сетям. В JS с 2012 года на бекенде. В последнее время работаю в компании в сфере фармацевтики на JS.',
                twitterAccount: 'cloudkserg'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*yIv9cMFiLGdeTwzu6ryQxA.png',
                name: 'Дмитрий Карловский',
                affiliation: 'ТехноГильдия HyOO',
                description: 'Рассекаю на велосипедах по бездорожью против ветра в гору на лыжах.',
                twitterAccount: '_jin_nin_'
            },
        ]
    },
    {
        id: 'c-plus-plus-morning-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/FJQQVeJqyQo',
        date: '22 мая',
        timeStart: '13:00',
        timeEnd: '13:40',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Шоу "Утро с С++ Russia" #1',
        tags: '',
        description: [
            'Позднее утро пятницы — лучшее время для дружеской беседы про плюсы. С++ Russia представляет новое разговорное шоу «Утро с C++ Russia», где участники программного комитета конференции будут встречаться со спикерами. Никакого хардкора и мозголомных тем: только инструменты, задачки, технологии и новости IT.',
            'Первый выпуск «Утра с C++ Russia» пройдет 22 мая в 13:00 c организатором C++ Siberia Сергеем Платоновым и разработчиком ПО в области информационной безопасности Павлом Филоновым.',
            'Гостем эфира будет Антон Бикинеев из Google. Он пообщается с ведущими про C++ в Google, про то, как Chrome расширяет язык для собственных нужд, а именно — про сборку мусора в C++, чем и занимается Антон, про стандарты и многое другое. '
        ],
        telegramLink: 'tg://resolve?domain=cpprussia',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1RVQKFKjEleCAAn2WsQqAv/8a8c5646e572407cce55eb6d6cc5db3a/Platonov.jpg?w=200',
                name: 'Сергей Платонов',
                affiliation: 'Tensor Technologies',
                description: 'C++ программист. Организатор C++ Siberia.',
                twitterAccount: 'sermp'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7K6BS8bMMueKKia7KQIaGY/611209d08d440588f812189d382adcf8/Filonov.jpg?w=200',
                name: 'Павел Филонов',
                affiliation: 'Лаборатория Касперского',
                description: 'Более 5 лет занимается разработкой ПО в области информационной безопасности. Более 3 лет активно использует методы анализа данных для разработки и внедрения методов машинного обучения в ИБ. До начала карьеры разработчика занимался исследованиями в области радиофизики и преподаванием в высшей школе.',
                twitterAccount: 'pavel_filonov'
            },
            {
                photoLink: 'https://i.ibb.co/KhzNMCQ/image.jpg',
                name: 'Антон Бикинеев',
                affiliation: 'Google',
                description: 'Проработал над различными проектами в различных сферах, начиная от встроенных систем и заканчивая распределенными серверными приложениями. Работал в компаниях Terminal Technologies, Ste||ar-group, Parallels, Kaspersky Lab. Внес вклад в разработку библиотеки для высоко-производительных вычислений HPX, а также разрабатывал различные математические спец. функции для библиотеки Boost. Сейчас работает в Google над сборщиком мусора C++ объектов внутри браузера Chrome.',
                twitterAccount: ''
            }
        ]
    },
    {
        id: 'java-jug-cloud-native-java-for-this-decade-quarkus',
        youtubeLink: 'https://www.youtube.com/embed/3qSapgjG-Ew',
        hasPassed: true,
        date: '22 мая',
        timeStart: '18:00',
        timeEnd: '20:00',
        complexityEmoji: '👷',
        complexityTag: 'PRACTICAL',
        langTag: 'EN',
        title: 'JUG «Cloud-native Java for this decade with Quarkus»',
        tags: '#quarkus #cloudnative #java #livecoding',
        description: [
            "Enterprise Java has come a long way. What does a modern development approach look like, in the age of Spring, Jakarta EE and MicroProfile?",
            "In this session, we'll have a look at supersonic, subatomic Java with Quarkus. If you're familiar with enterprise development with Spring or Java EE, you'll be delighted to see the effective way of working, Quarkus enables. We'll see the benefits of Quarkus for modern, cloud-native microservices in 2020. Get yourself ready for this live-coding-only session!"
        ],
        telegramLink: 'tg://resolve?domain=jug_ru',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/6wRrAEdnpa8sraY3OQYzfu/9f2f8a025f32a9442d4c6424203ce447/Daschner.jpg?w=1920',
                name: 'Sebastian Daschner',
                affiliation: 'IBM',
                description: 'Sebastian Daschner is a Lead Java Developer Advocate for IBM, author, and trainer and is enthusiastic about programming and Java (EE). He is the author of the book "Architecting Modern Java EE Applications". Sebastian is participating in the JCP, helping to form the future standards of Java EE, serving in the JAX-RS, JSON-P and Config Expert Groups and collaborating on various open source projects. For his contributions in the Java community and ecosystem, he was recognized as a Java Champion, Oracle Developer Champion and JavaOne Rockstar.\n' +
                    '\n' +
                    'Besides Java, Sebastian is also a heavy user of Linux and container technologies like Docker. He evangelizes computer science practices on his blog, his newsletter, and on Twitter via @DaschnerS. When not working with Java, he also loves to travel the world — either by plane or motorbike.',
                twitterAccount: 'DaschnerS'
            }
        ],
        surveyMonkey: 'https://ru.research.net/r/meetup_220520_jug',
        sponsors: [
            {name: "alm_works", webpage: "https://almworks.com/", scale: 0.8},
            {name: "infobip", webpage: "https://www.infobip.com/"}
        ]
    },
    {
        id: 'qa-survival-bias-4',
        youtubeLink: 'https://www.youtube.com/embed/Dvp9pLrKTlc',
        hasPassed: true,
        date: '22 мая',
        timeStart: '18:00',
        timeEnd: '19:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего» Episode 4',
        tags: '#qa #show #holywar',
        description: [
            'Ведущие поделятся новостями минувший недели, ответят на ваши вопросы и разберутся в очередном инструменте!'
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ],
    },
    {
        id: 'js-holy-breakfast-3',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/CeoQ8cDWy_Y',
        date: '26 мая',
        timeStart: '11:00',
        timeEnd: '12:00',
        complexityEmoji: '🐱',
        complexityTag: 'FUN',
        langTag: 'RU',
        title: 'Тяжелое утро с HolyJS #3',
        tags: '#holyjs #morning #show',
        description: [
            "Гость третьего выпуска «Тяжелого утра с HolyJS» — разработчик и IT-консультант Маргарита Рой, которая работает в Финляндии и специализируется на улучшении процессов разработки программного обеспечения.",
            "Ведущие — участники программного комитета конференции HolyJS 2020 Piter Женя Кот из Wrike и Михаил Башуров из WiseBeats — расспросят Маргариту о консультантах в IT и связанных с ними распространенных мифах, а также поговорят об AI и о стилях.",
            "Кстати, на HolyJS 2020 Piter, которая пройдет в онлайне с 22 по 25 июня, Маргарита выступит с докладом про CSS reset и расскажет, как значительно сократить время на сброс стилей."
        ],
        telegramLink: 'tg://resolve?domain=holyjsconf',
        speakers: [
            {
                photoLink: 'https://static.jugru.org/photo/raw/100955_1553492270_Margarita__Obraztsova_A_tale_of_CSS_Resets_and_Everything_You_Need_to_Know_About_Them.jpg',
                name: 'Маргарита Рой',
                affiliation: 'Nitor',
                description: 'Consultant software engineer, who loves to find different ways to optimize project work.',
                twitterAccount: 'riittagirl'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/2Sr3POKsCQmEecYMe2eSIs/c55a1eb684aadf07411608e03235a735/avatar4.jpg?w=340',
                name: 'Евгений Кот',
                affiliation: 'Wrike',
                description: 'Долгое время занимался .NET, Microsoft stack. Работал в Quest Software, после этого в Dell, где начал заниматься фронтендом. Прошёл путь от CoffeeScript + Angular 1.0 до Dart + Angular 2.0. На данный момент трудится в Wrike, высоконагруженном SaaS-приложении с очень богатым клиентским кодом. Команда Wrike пишет на Dart и Angular 2.0 и стремится быть up-to-date со всеми современными течениями в мире веб.',
                twitterAccount: 'bunopus'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/5JSd3BxPdbo1nQEDNHwbXx/d9c7c6046e1365c9d5b765f49b4aac32/100211_359346961_Mikhail_Bashurov.jpg?w=340',
                name: 'Михаил Башуров',
                affiliation: 'WiseBits',
                description: 'Фулстек на JS/.NET, фанат TypeScript и ReasonML. Пытается в теорию типов и компиляторов, отбирает доклады в ПК HolyJS.',
                twitterAccount: 'saitonakamura'
            },
        ]
    },
    {
        id: 'hydra-heads-2',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/-qAb-ZvQitc',
        date: '26 мая',
        timeStart: '20:00',
        timeEnd: '21:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Шоу «Головы Гидры» #2',
        tags: '#hydraconf #concurrency #ЖЗЛ #sptdc',
        description: [
            '«Головы Гидры» — это новое разговорное шоу конференции по разработке многопоточных и распределенных систем Hydra, где участники программного комитета конференции в прямом эфире обсуждают доклады друг друга.',
            'В гостях у Алексея Федорова и Виталия Аксенова — тимлид в JetBrains, который работает над корутинами и библиотеками в Kotlin, Роман Елизаров.',
            'Они поговорят о работе Романа, о его докладе по structured concurrency на прошлогодней Hydra, о курсе по concurrent и distributed computing, который Роман ведёт в ИТМО, а также о программе Hydra 2020.'
        ],
        telegramLink: 'tg://resolve?domain=hydraconf',
        speakers: [
            {
                photoLink: 'https://pbs.twimg.com/profile_images/904461024786513920/xqFuwPeM_400x400.jpg',
                name: 'Алексей Федоров',
                affiliation: 'JUG Ru Group',
                description: 'Лидер питерского JUG и организатор Java-конференций Joker и JPoint',
                twitterAccount: '23derevo'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5aYfREZK1x2E0o598oGsQd/0a60f37b8919975d0c36e44b8a9d0425/Aksenov.jpg?w=200',
                name: 'Виталий Аксенов',
                affiliation: 'ITMO University',
                description: 'Сотрудник кафедры «Компьютерные Технологии» Университета ИТМО.',
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5R2rOzCrniEU8Wg4EAWoKQ/19b101e09cba7e89d8577f390398056a/maxresdefault__1_.jpg?w=600',
                name: 'Роман Елизаров',
                affiliation: 'JetBrains',
                description: 'Разработчик языка Kotlin в компании JetBrains, эксперт в области многопоточного программирования. До JetBrains более 10 лет занимался разработкой ПО для биржевой и брокерской деятельности в компании Devexperts. Член международного организационного комитета Финала ACM ICPC. Лауреат премии Президента в области образования. Java Champion.',
                twitterAccount: 'relizarov'
            }
        ]
    },
    {
        id: 'qa-heisenbug-breakfast-2',
        fuckupUrl: 'heisenbug-breakfast-2',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/w_8xYcxZlg4',
        date: '27 мая',
        timeStart: '11:00',
        timeEnd: '11:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Тяжелое утро с Heisenbug #2',
        tags: '#selenide',
        description: [
            "«Тяжелое утро с Heisenbug» — это утреннее шоу с программным комитетом конференции о тестировании Heisenbug. В этом выпуске встретятся:",
            "— Всеволод Брекелов: участник программного комитета Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».",
            "— Дарья Манухина: участник программного комитета Heisenbug, руководитель по развитию продуктов в «Калуга Астрал», один из организаторов GDG Kaluga, более 6 лет занималась тестированием: построением и улучшением процессов, формированием команд и автоматизацией.",
            "— Андрей Солнцев: разработчик в эстонской компании Codeborne, автор фреймворка Selenide, организатор таллиннского Devclub, частый докладчик на конференциях, ярый приверженец экстремального программирования, автоматических тестов, парного программирования и чистого кода.",
            "Гость шоу Андрей Солнцев обсудит с ведущими новости индустрии, новый релиз Selenide и план развития продукта, а также затронет тему выступлений на конференциях и многое другое."
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/4rFSQpKle1lfuh6nqXp24V/8605818a06bda1794c9f950bd11a34cb/manuhina.jpg?w=200',
                name: 'Дарья Манухина',
                affiliation: 'Калуга Астрал',
                description: 'Руководитель по развитию продуктов в "Калуга Астрал". Один из организаторов GDG Kaluga. Более 6 лет занималась тестированием: построением и улучшением процессов, формированием команд и автоматизацией. Не мыслит свою жизнь без творчества и постоянного развития.',
                twitterAccount: 'ManukhinaDarya'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/7xfnZBcbASJ9wZqnTm1Lbm/8690bbd7c4776fa2becced0abf4eeeb9/brekelov.jpg?w=200',
                name: 'Всеволод Брекелов',
                affiliation: 'JUG Ru Group',
                description: ' PMM в JUG Ru Group и участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/335EioMAg0qGYi6eIgkO4y/19ac9cb6b8bb88e9b3261c0ba1d2b153/asolntsev__1_.jpg?w=200',
                name: 'Андрей Солнцев',
                affiliation: 'Codeborne',
                description: 'Разработчик в эстонской компании Codeborne. Автор фреймворка Selenide, организатор таллиннского Devclub, частый докладчик на конференциях. Ярый приверженец экстремального программирования, автоматических тестов, парного программирования и чистого кода.',
                twitterAccount: 'asolntsev'
            },
        ]
    },
    {
        id: 'java-jpoint-coffee-2',
        fuckupUrl: 'jpoint-coffee-2',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/d_n4Kpte7_Q',
        date: '27 мая',
        timeStart: '12:00',
        timeEnd: '12:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Первая чашка кофе с JPoint #2',
        tags: '',
        description: [
            "Утреннее шоу «Первая чашка кофе с JPoint» — это встречи программного комитета конференции JPoint с ее спикерами без хардкора: только инструменты, задачки, технологии и околоайтишные темы.",
            "Гостем нового выпуска будет Маргарита Недзельская, разработчик в Wix и организатор Kotlin-комьюнити в Киеве, один из организаторов Rockstar Night dev club. Ведущие — основатель встреч jug.msk.ru Андрей Когунь и Developer Advocate проекта GraalVM в OracleLabs Олег Шелаев.",
            "Маргарита поговорит с ведущими про Scala на работе и Kotlin для души, расскажет подробнее о своем докладе для JPoint 2020 про Scala и Kotlin interop, о пет-проекте телеграм-бота на Kotlin, с помощью которого она берет интервью у интересных людей, а также обсудит с программным комитетом темы докладов других спикеров конференции. "
        ],
        telegramLink: 'tg://resolve?domain=jpointconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/nOqNSu66FcGT0MwzHuWMe/38d4e7aae96ac8a7c1d4fb9353243215/Selajev.jpg',
                name: 'Олег Шелаев',
                affiliation: 'OracleLabs, Oracle',
                description: 'Developer advocate for GraalVM at OracleLabs, Oracle.',
                twitterAccount: 'shelajevoleg'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7FnTvLwAqWekJIlkSb5mSY/90a4fc100f1db0dd83816289fc37d63e/Kogun.jpg',
                name: 'Андрей Когунь',
                affiliation: 'КРОК',
                description: 'Основатель и организатор встреч московского сообщества Java-разработчиков JUG.MSK.',
                twitterAccount: 'akogun'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/4Ai3LAWTwEUzbZzIgnI0oW/a26cfce3f33ce3e060d6a53a35a6d53b/Marharyta_Nedzelska.jpg',
                name: 'Маргарита Недзельская',
                affiliation: 'Wix Engineering',
                description: 'Разработчик в Wix и организатор Kotlin-комьюнити в Киеве.  Является одним из организаторов Rockstar Night dev club.',
                twitterAccount: 'jMargaritaN'
            },
        ]
    },
    {
        id: 'mobile-mobius-breakfast-3',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/g2uyTrj4mjI',
        date: '28 мая',
        timeStart: '13:00',
        timeEnd: '14:00',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: '"Hello, world!" с Mobius #3',
        tags: '#mobius #morning #show #bleedingedge',
        description: [
            "В эфир третьего выпуска \"Hello, world!\" с Mobius придут Андрей Куликов и Анастасия Соболева, которые работают в Google и разрабатывают известную библиотеку Jetpack Compose.",
            "Ведущие — участники программного комитета Mobius Михаил Емельянов (ЦФТ) и Владимир Иванов (EPAM) — расспросят Андрея и Анастасию о работе в Google и о том, каково изобретать самую ожидаемую библиотеку для Android. Еще они расскажут, с каким трудностями сталкиваются в работе и почему так много разработчиков ждут Compose.",
            "Кстати, оба спикера выступят на Mobius 2020 Piter, который пройдет с 22 по 25 июня в онлайне, с совместным докладом. Они расскажут, как в Jetpack Compose работают UI-компоненты, как написать свои и как легко их переиспользовать и кастомизировать. Андрей и Анастасия продемонстрируют, как легко сверстать сложный экран, что происходит при этом под капотом, какие решения приходилось принимать в процессе разработки фреймворка и как Kotlin помогает сделать наши API удобными."
        ],
        telegramLink: 'tg://resolve?domain=mobiusconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/6hzI9xTq9zBiyTIyva4HtK/15b95d19ade7e81aedd30620a5d18a3e/Emeljanov.jpg?w=200',
                name: 'Михаил Емельянов',
                affiliation: 'ЦФТ',
                description: 'В Android-разработке с 2010 года. Участвовал в разработке проектов от мультимедийных до финтеха.Катается на сноуборде, любит пухляк и высокие трамплины. Меломан, играет на гитаре.',
                twitterAccount: 'mike_emelyanov'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/38AoDV2wj98dHchJVRYfFn/0cac3ab9234b0a2227aa001b6115da2a/Ivanov.jpg?w=200',
                name: 'Владимир Иванов',
                affiliation: 'EPAM',
                description: 'Lead software engineer в EPAM, стремится стать Solution Architect. Опытный Android-разработчик, эксперт по React Native, Certified Google Cloud Architect, ментор и спикер.',
                twitterAccount: 'vvsevolodovich'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/70OqtqIWd92ba2TrHC85A7/e4cc50b3deb7d38ee2f6c0170429410c/Anastasiya_Soboleva.jpg?w=200',
                name: 'Анастасия Соболева',
                affiliation: 'Google',
                description: 'Работает в Google, где занимается разработкой Jetpack Compose. До этого она работала в нескольких проектах Amazon как бэкендер и параллельно как iOS-разработчик. В свободное время Анастасия занимается всем понемногу: рисует эскизы, играет в бадминтон, катается на велосипеде и гуляет. Также она постоянно учит новые языки, которые, к сожалению, быстро забывает. На данный момент это французский и мандаринский китайский.',
                twitterAccount: ''
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/1PWPWkGj1ROUhvEdKweZLD/9c0117b6c2897cf435952c2a733c75c7/kulikov.jpg?w=200',
                name: 'Андрей Куликов',
                affiliation: 'Google',
                description: 'Разрабатывает Android приложения с 2012 года. Автор популярной библиотеки Transitions-Everywhere. Устроившись в Google, перенес все наработки из нее в официальную AndroidX Transitions-библиотеку. В данный момент работает над Jetpack Compose.',
                twitterAccount: 'and_kulikov'
            }
        ]
    },
    {
        id: 'devops-brunch-2',
        hasPassed:  true,
        youtubeLink: 'https://www.youtube.com/embed/CNxXnJJWxic',
        date: '28 мая',
        timeStart: '18:00',
        timeEnd: '18:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Шоу "DevOops в рабочий полдник" №2',
        tags: '#devoops',
        description: [
            "«DevOops в рабочий полдник» — это шоу с участниками программного комитета и спикерами конференции DevOops 2020 Moscow. В этот раз в студии встретятся:",
            "— Алексей Кирпичников. Работает в Контуре и делает инфраструктуру-как-сервис для разработчиков продуктов компании. Специализируется на сборе метрик, алертинге, агрегации логов, service discovery, деплое, хостинге и всем остальном, что помогает разработчику сосредоточиться на развитии сервиса и не тратить время на эксплуатацию. В прошлом был тимлидом команды разработки Яндекс.Такси и довел инфраструктуру бэкендов этого сервиса от основания до миллионного заказа.",
            "— Сергей Киселев. Руководит командой в Яндекс.Облаке. В IT более 15 лет, основная роль — ведущий разработчик. Работал с серверной разработкой проектов в областях дистанционного обучения, медицины, интернет-поиска, контейнеризации. Три года руководил компанией по разработке и системному администрированию. Был организатором сообщества Java-разработчиков в Иркутске. Текущие интересы: container registry, Docker, k8s, CI/CD.",
            "— Матвей Кукуй. Спикер DevOops 2020 Moscow, основал компанию Amixr Inc. В прошлом — DevOps и Software Engineer. Работал в Kaspersky, Cisco, Constructor.IO.",
            "В этом шоу не будет никакого хардкора: только дружеская беседа, околоайтишные новости и, конечно же, DevOps.",
            "На конференции DevOops 2020 Moscow, которая пройдет с 6 по 9 июля, Матвей Кукуй расскажет, как его DevOps-инструмент за полтора года превратился из идеи в компанию Amixr Inc. с офисом в Сан-Франциско, десятком сотрудников, инвестициями, продуктом и продажами."
        ],
        telegramLink: 'tg://resolve?domain=devOopsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7Dw30diwYmaiFeTDlYE7u9/9d47fdc5166d595ae150ca5cc54d4704/kirpichnikov.jpg?w=200',
                name: 'Алексей Кирпичников',
                affiliation: 'Контур',
                description: 'Алексей работает в Контуре и делает инфраструктуру-как-сервис для разработчиков продуктов этой компании. В сферу интересов Алексея входит сбор метрик, алертинг, агрегация логов, service discovery, деплой, хостинг и все остальное, что нужно разработчику, чтобы сосредоточиться на развитии сервиса и не тратить время на эксплуатацию. До Контура Алексей был тимлидом команды разработки Яндекс.Такси и довел инфраструктуру бэкендов этого сервиса от основания до миллионного заказа.',
                twitterAccount: ''
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/3PDRPFKbSpmc1DflJ4unrS/0fad508d089b85b45a0761ce7c090d9e/kiselev.jpg?w=200',
                name: 'Сергей Киселёв',
                affiliation: 'Яндекс.Облако',
                description: 'В IT более 15 лет, последние 7 лет в Яндексе. Богатый опыт серверной разработки проектов в областях дистанционного обучения, медицины, интернет поиска, контейнеризации. Основная роль в проектах — ведущий разработчик. Три года руководил компанией по разработке и системному администрированию. Являлся организатором сообщества Java-разработчиков в Иркутске. На данный момент руководит командой в Яндекс.Облаке. Текущие интересы: container registry, Docker, k8s, CI/CD.',
                twitterAccount: 'intr13'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1G9UiZLIuzjPpP3yJiEQti/49023f7ad126fdcbd964de2e6a1df2ab/Matvey_Kukuy.jpg?w=200',
                name: 'Матвей Кукуй',
                affiliation: 'Amixr.Inc',
                description: 'CEO в Amixr Inc., в прошлом — DevOps и Software Engineer. Ex. Kaspersky, Cisco, Constructor.IO.',
                twitterAccount: 'Mkukkk'
            }
        ]
    },
    {
        id: 'c-plus-plus-morning-2',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/kUO5S2NlKOg',
        date: '29 мая',
        timeStart: '13:00',
        timeEnd: '13:40',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Шоу "Утро с С++ Russia" #2',
        tags: '',
        description: [
            'Пятница — лучший день для ламповой беседы про плюсы. Гость второго выпуска шоу «Утро с C++ Russia» — С++ разработчик из Solarwinds Александр Зайцев. В свободное от работы время он пытается сделать так, чтобы С++ можно было пользоваться проще в повседневной жизни — в том числе, пишет свои предложения в С++ комитет.',
            'Александр обсудит с ведущими Сергеем Платоновым (организатор C++ Siberia) и Павелом Филоновым (разработчик ПО в области информационной безопасности) новости индустрии, инструменты и технологии, а также объяснит, почему начинать новые проекты на С++ в настоящее время - абсолютно нормальная практика.'
        ],
        telegramLink: 'tg://resolve?domain=cpprussia',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1RVQKFKjEleCAAn2WsQqAv/8a8c5646e572407cce55eb6d6cc5db3a/Platonov.jpg?w=200',
                name: 'Сергей Платонов',
                affiliation: 'Tensor Technologies',
                description: 'C++ программист. Организатор C++ Siberia.',
                twitterAccount: 'sermp'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7K6BS8bMMueKKia7KQIaGY/611209d08d440588f812189d382adcf8/Filonov.jpg?w=200',
                name: 'Павел Филонов',
                affiliation: 'Лаборатория Касперского',
                description: 'Более 5 лет занимается разработкой ПО в области информационной безопасности. Более 3 лет активно использует методы анализа данных для разработки и внедрения методов машинного обучения в ИБ. До начала карьеры разработчика занимался исследованиями в области радиофизики и преподаванием в высшей школе.',
                twitterAccount: 'pavel_filonov'
            },
            {
                photoLink: 'https://i.ibb.co/4YQypCb/image.jpg',
                name: 'Александр Зайцев',
                affiliation: 'Solarwinds',
                description: 'С++ разработчик. Любит и уважает Open Source. В свободное время пытается сделать так, чтобы С++ можно было пользоваться проще в повседневной жизни (в том числе и через написание предложений в С++ комитет). Считает, что начинать новые проекты на С++ в настоящее время - абсолютно нормальная практика.',
                twitterAccount: 'zamazan4ik'
            }
        ]
    },
    {
        id: 'qa-survival-bias-5',
        youtubeLink: 'https://www.youtube.com/embed/jM3gAE8_ifc',
        hasPassed: true,
        date: '29 мая',
        timeStart: '18:00',
        timeEnd: '19:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего» Episode 5',
        tags: '#qa #show #allureEE',
        description: [
            'Ведущие поделятся новостями минувшей недели, ответят на ваши вопросы и Артем Ерошенко покажет основные возможности Allure EE. Это будет не скучная презентация со слайдами, а live-демонстрация системы в бою с вопросами и ответами в прямом эфире! \n'
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        surveyMonkey: 'https://ru.research.net/r/meetup_290520_bias',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ],
    },
    {
        id: 'dotnet-msk-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/_0j8Z6okVMM',
        date: '29 мая',
        timeStart: '20:00',
        timeEnd: '22:30',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Встреча MSKDotNet № 46',
        tags: '#MskDotNet, #DotNetRu, #csharp',
        description: [
            "MskDotNet проведет свой 46-й митап в онлайне. Вести его будут активисты сообщества Юрий Орлов и Елизавета Голенок. Вместе с лидером Московского Клуба Программистов Марком Шевченко они поговорят про разработку микросервисов на C#, встречающихся на пути подводных камнях и методах их преодоления. А архитектор Райффайзенбанка Константин Густов покажет примеры и возможности применения inner source, открыв некоторые секреты внутренней разработки.",
            "Подключайтесь к чату MskDotNet в телеграме, чтобы комментировать эфир и задавать вопросы.",
            "О спикерах:",
            "— Марк Шевченко: программирует с 1989 года. Писал на C, C++, Delphi, Perl, PHP, C#, Ruby и F#. Организует встречи Московского клуба программистов. Помогает коллегам делать интересные доклады и писать хорошие статьи.",
            "— Константин Густов. На данный момент работает архитектором. Разработкой ПО занимается уже больше 10 лет. Начинал с C++ и немного Delphi, потом полностью перешел на .NET и C#. Сменил несколько предметных областей, от военной отрасли и энергетики нефтедобычи до банковского дела. Старается всегда придерживаться прагматичных подходов без крайностей. Работает с сервисными архитектурами и DDD.",
        ],
        telegramLink: 'tg://resolve?domain=MskDotNet',
        speakers: [
            {
                photoLink: 'https://markshevchenko.pro/img/speaker.png',
                name: 'Марк Шевченко',
                affiliation: 'Наука',
                description: 'Марк Шевченко программирует с 1989 года. Писал на C, C++, Delphi, Perl, PHP, C#, Ruby и F#. Организует встречи Московского клуба программистов. Помогает коллегам делать интересные доклады и писать хорошие статьи.',
                twitterAccount: ''
            },
            {
                photoLink: 'https://i.ibb.co/Fnwzvzp/IMG-0004.jpg',
                name: 'Константин Густов',
                affiliation: 'Райффайзенбанк',
                description: 'На данный момент работает архитектором. Разработкой ПО занимается уже больше 10 лет. Начинал с C++ и немного Delphi, потом полностью перешел на .NET и C#, чему очень рад. Сменил несколько предметных областей, от военной отрасли и энергетики нефтедобычи до банковского дела. Старается всегда придерживаться прагматичных подходов без крайностей. Работает с сервисными архитектурами и DDD.',
                twitterAccount: ''
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/78aJR2g8aPAY4RBqPtEoz5/163ae1d2c2a1d9d0e25a4b54c16ede47/100637_1633341212_Yuriy_Orlov_Praktika_prinyatiya_i_primeneniya_Coding_sonventions_v_razrabotke.jpg',
                name: 'Юрий Орлов',
                description: 'Активист MskDotNet',
                twitterAccount: ''
            },
            {
                photoLink: 'https://images.ctfassets.net/9n3x4rtjlya6/3xsAQQJNkI2mSqKGEEeacq/47270cdf3be4f143daddc7f6157174a5/DSC_0088_2__1_.jpg?w=600',
                name: 'Елизавета Голенок',
                description: 'Активист MskDotNet',
                twitterAccount: ''
            }
        ]
    },
    {
        id: 'hydra-heads-3',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/v3oK5_docYE',
        date: '1 июня',
        timeStart: '21:00',
        timeEnd: '21:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Шоу «Головы Гидры» #3',
        tags: '#hydraconf',
        description: [
            '«Головы Гидры» — это новое разговорное шоу конференции по разработке многопоточных и распределенных систем Hydra, где участники программного комитета конференции в прямом эфире обсуждают доклады друг друга.',
            'В этом выпуске ведущие Алексей Федоров и Виталий Аксенов побеседуют с Сергеем Куксенко. Сергей — Java Performance Engineer, который работает с Java начиная с версии 1.0. За это время он успел поучаствовать в разработке мобильных, клиентских и серверных приложений, а также виртуальных машин. Производительностью Java он занимается c 2005 года, а в данный момент работает в Oracle над производительностью OracleJDK/OpenJDK.',
            'Ведущие обсудят с Сергеем многопоточность в JVM и производительность программ.'
        ],
        telegramLink: 'tg://resolve?domain=hydraconf',
        surveyMonkey: 'https://ru.research.net/r/meetup_010620',
        speakers: [
            {
                photoLink: 'https://pbs.twimg.com/profile_images/904461024786513920/xqFuwPeM_400x400.jpg',
                name: 'Алексей Федоров',
                affiliation: 'JUG Ru Group',
                description: 'Лидер питерского JUG и организатор Java-конференций Joker и JPoint',
                twitterAccount: '23derevo'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5aYfREZK1x2E0o598oGsQd/0a60f37b8919975d0c36e44b8a9d0425/Aksenov.jpg?w=200',
                name: 'Виталий Аксенов',
                affiliation: 'ITMO University',
                description: 'Сотрудник кафедры «Компьютерные Технологии» Университета ИТМО.',
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/WBK4nk3ejBLfqzZX3ZrT9/ede94a780985e14acec3551f8482b49b/Kuksenko.jpg',
                name: 'Сергей Куксенко',
                affiliation: 'Oracle',
                description: 'Java Performance Engineer. Работает с Java начиная с версии 1.0. За это время успел поучаствовать в разработке мобильных, клиентских, серверных приложений и виртуальных машин. Производительностью Java занимается c 2005 года и в данный момент в Oracle занимается производительностью OracleJDK/OpenJDK.',
                twitterAccount: 'kuksenk0'
            }
        ]
    },
    {
        id: 'js-holy-breakfast-4',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/J-DQVrurswE',
        date: '2 июня',
        timeStart: '11:00',
        timeEnd: '12:00',
        complexityEmoji: '🐱',
        complexityTag: 'FUN',
        langTag: 'RU',
        title: 'Тяжелое утро с HolyJS #4',
        tags: '#holyjs #morning #show',
        description: [
            "Утро вторника — не время для хардкора и мозголомных тем! Устраивайтесь поудобнее и приготовьтесь к приятной беседе про инструменты, задачи, технологии и околоайтишные темы.",
            "Гость нового выпуска шоу «Тяжелое утро с HolyJS» — Илья Климов, который занимается фронтендом в GitLab. Илья — кандидат технических наук, больше шести лет профессионально учит людей в двух вузах и на собственных курсах. Программирует на JS уже 15 лет во всех его проявлениях: от микроконтроллеров до облачного рендеринга видео.",
            "Ведущие — участники программного комитета HolyJS. Евгений Кот прошел путь от CoffeeScript + Angular 1.0 до Dart + Angular 2.0 и теперь работает в высоконагруженном SaaS-приложении с богатым клиентским кодом Wrike. Александра Калинина в программировании больше 10 лет и имеет большой опыт в технологиях веб-разработки, шаблонах проектирования, проектирования баз данных, разработки алгоритмов и структуры данных."
        ],
        telegramLink: 'tg://resolve?domain=holyjsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4Tj0bDtTQA4gSIqSoCi2q2/75a28bb3eb8fd025464801ede9bc7bb9/Klymov.jpg?w=340',
                name: 'Илья Климов',
                affiliation: 'GitLab',
                description: '15 лет программирует на JS во всех его проявлениях: от микроконтроллеров до облачного рендеринга видео. Более 6 лет профессионально учит людей (в двух вузах и на собственных курсах), кандидат технических наук. Сейчас занимается фронтом в GitLab.',
                twitterAccount: 'xanf_ua'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4pLwhybhMhIePf8jc2nMxV/b300806af12712c0ed0e470a7b13724c/kalinina.jpg?w=340',
                name: 'Александра Калинина',
                affiliation: 'Nix',
                description: 'Более 10 лет опыта в программировании. Хорошо подкована в веб-разработке: решает проблемы с дырявыми абстракциями. Имеет опыт в написании unit/integration/E2E/B2B-тестов, используя mocha/chai/sinon. Большой опыт в технологиях веб-разработки, шаблонах проектирования, проектирования баз данных, разработки алгоритмов и структуры данных. Есть опыт в разработке проектных заданий и технической документации.',
                twitterAccount: 'korery'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/2Sr3POKsCQmEecYMe2eSIs/c55a1eb684aadf07411608e03235a735/avatar4.jpg?w=340',
                name: 'Евгений Кот',
                affiliation: 'Wrike',
                description: 'Долгое время занимался .NET, Microsoft stack. Работал в Quest Software, после этого в Dell, где начал заниматься фронтендом. Прошёл путь от CoffeeScript + Angular 1.0 до Dart + Angular 2.0. На данный момент трудится в Wrike, высоконагруженном SaaS-приложении с очень богатым клиентским кодом. Команда Wrike пишет на Dart и Angular 2.0 и стремится быть up-to-date со всеми современными течениями в мире веб.',
                twitterAccount: 'bunopus'
            },
        ]
    },
    {
        id: 'devops-cdf-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/eZKKxFbyE08',
        date: '2 июня',
        timeStart: '18:00',
        timeEnd: '18:50',
        complexityEmoji: '🐱',
        complexityTag: 'FUN',
        langTag: 'RU',
        title: 'Знай себя и своего врага : CI/CD в королевстве Cloud Native',
        tags: '#cloudnative #cicd #стратегия #cdf #ci/cd',
        description: [
            "Cовладелец технологического консалтинга Otomato Software, эксперт по техническому преподаванию, спикер DevOops 2020 Moscow Антон Вайс выступит с 30-минутным онлайн-докладом про CI/CD.",
            "Непрерывная доставка, особенно для сложных cloud native-систем с множеством пользователей и окружений, по-прежнему является сложной задачей. Тем более что возможных подходов и инструментов очень много. Все ли ветки проверять? Использовать облачные сервисы или хостить у себя? Что важнее — простота или гибкость? Является ли зависимость от поставщика (vendor lock-in) реальной угрозой? Пришло ли уже время повального GitOps-а? Какие меры инфобеза действительно необходимы? В своем докладе Антон расскажет, как построить стратегию CI/CD и как постоянно адаптировать ее к изменяющемуся отраслевому ландшафту так, чтобы уметь отвечать на эти и другие сложные вопросы.",
            "После доклада Антон пообщается с ведущим Михаилом Дружининым, техническим менеджером в Amazon и тренером по архитектуре и Java-разработке. Михаил задаст Антону самые интересные вопросы из телеграм-чата конференции DevOops. "
        ],
        telegramLink: 'tg://resolve?domain=devOopsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/3wciqev5tZ2xjVPPCHUHPj/94cd03f9ad79f7008660bb5feeefa672/weiss.jpg?w=200',
                name: 'Антон Вайс',
                affiliation: 'Otomato Software',
                description: 'Совладелец технологического консалтинга Otomato Software, обладатель более чем 15-летнего опыта в области высоких технологий. Является экспертом по техническому преподаванию, инициатором и соавтором первого в Израиле курса DevOps-сертификации. Антон участвует в международных конференциях и известен как крутой докладчик.',
                twitterAccount: 'antweiss'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/6wJHO9pVTPnS51pJr7enDj/fe4eae2c627af16998c39b1b4f995b20/Druzhinin.jpg?w=200',
                name: 'Михаил Дружинин',
                affiliation: 'Amazon',
                description: 'Технический менеджер в компании Amazon. 13 лет в IT. Тренер по архитектуре и Java-разработке. Управлял проектами как Team Lead и PM. Участвовал во многих проектах в роли архитектора.',
                twitterAccount: 'xomyakus'
            },
        ]
    },
    {
        id: 'qa-heisenbug-breakfast-3',
        fuckupUrl: 'heisenbug-breakfast-3',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/GzP1u7zFowc',
        date: '3 июня',
        timeStart: '11:00',
        timeEnd: '11:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Тяжелое утро с Heisenbug #3',
        tags: '#security',
        description: [
            "Утреннее шоу с программным комитетом конференции о тестировании Heisenbug снова в эфире! Гость нового выпуска — эксперт по информационной безопасности в Одноклассниках Александра Сватикова, которая последние восемь лет занимается безопасностью мобильных и веб-приложений.",
            "Ведущие выпуска — участник программного комитета Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска» Всеволод Брекелов и Иван Тречёкас, который занимается тестированием десктопов, эмуляторов и веб-сервисов, поднимал автоматизацию для web и API и знает три языка (Java, Kotlin, Python). В эфире они обсудят с Александрой новости сферы безопасности в индустрии, автоматизицию этой отрасли, этичных хакеров, поддельные сайты и, конечно, ближайшую конференцию Heisenbug.",
            "На Heisenbug 2020 Piter Александра выступит с докладом об уязвимостях, вызванных ошибками работы с механизмами обмена сообщениями, и расскажет о методах тестирования и инструментах, которые необходимо применять для обнаружения подобных проблем. Она также объяснит, как обезопасить ваши Android-приложения при взаимодействии с операционной системой."
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/4HNtzhoHMN83yQtw5i77M5/85b5c5abea0554b2b0cb1d554a3c2483/Ivan_Trechkas.jpg?w=300',
                name: 'Иван Тречёкас',
                affiliation: '',
                description: 'Иван успел поработать в России и в Казахстане, а скоро, мы надеемся, и в Канаде найдёт себе работу. Занимался тестированием десктопов, эмуляторов и веб-сервисов, поднимал автоматизацию для web и api, знает три языка: Java, Kotlin, Python. В тестировании с 2012 года и за это время успел поработать как в продуктовых компания, так и в аутсорсе. Любит троллить в курсе по Python на Stepik. Сейчас не работает в Канаде и ему это очень нравится.',
                twitterAccount: 'bahka'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/7xfnZBcbASJ9wZqnTm1Lbm/8690bbd7c4776fa2becced0abf4eeeb9/brekelov.jpg?w=200',
                name: 'Всеволод Брекелов',
                affiliation: 'JUG Ru Group',
                description: ' PMM в JUG Ru Group и участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/6hi98kRJ3SzWQsg9W7ZMO9/fb6bb9f5519311dbc985a9b350210f67/svatikova.jpg?w=300',
                name: 'Александра Сватикова',
                affiliation: 'Одноклассники',
                description: 'Эксперт по информационной безопасности в Одноклассниках. Путь в IT начинала как Java-разработчик, но свернула не туда. Последние 8 лет занимается безопасностью веб и мобильных приложений.',
                twitterAccount: ''
            },
        ]
    },
    {
        id: 'java-jpoint-coffee-3',
        fuckupUrl: 'jpoint-coffee-3',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/eTLYTKNoQGo',
        date: '3 июня',
        timeStart: '12:00',
        timeEnd: '12:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Первая чашка кофе с JPoint #3',
        tags: '',
        description: [
            "Утреннее шоу «Первая чашка кофе с JPoint» — это встречи программного комитета конференции JPoint с ее спикерами.",
            "Гость нового выпуска — Тагир Валеев, разработчик из JetBrains, который занимается статическим анализатором кода IntelliJ IDEA, инспекциями и квик-фиксами. Также Тагир кидает патчи в OpenJDK и разрабатывает опенсорсную библиотеку StreamEx.",
            "Ведущие эфира — Владимир Ситников и Дмитрий Александров. Владимир — автор более десятка улучшений производительности в официальном PostgreSQL JDBC-драйвере, уже десять лет работает над производительностью и масштабируемостью NetCracker OSS. Дмитрий — ведущий программист и архитектор в T-Systems, по совместительству один из лидеров болгарской Java User Group.",
            "В студии ведущие обсудят с Тагиром его доклад «Ещё немного маленьких оптимизаций», с которым он выступит на онлайн-конференции JPoint 2020. Еще они поговорят про киллер-фичи в IntelliJ IDEA, про новые фичи в новых версиях Java и про то, как контрибьютить в Java. "
        ],
        telegramLink: 'tg://resolve?domain=jpointconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5mzA6ao6QF4MnwWTdLdu55/7bf2f4e0c1ebe2ef4e5d7b5f9df6e389/Sitnikov.jpg',
                name: 'Владимир Ситников',
                affiliation: 'Netcracker',
                description: 'Десять лет работает над производительностью и масштабируемостью NetCracker OSS — ПО, используемого операторами связи для автоматизации процессов управления сетью и сетевым оборудованием. Увлекается вопросами производительности Java и Oracle Database. Автор более десятка улучшений производительности в официальном PostgreSQL JDBC-драйвере.',
                twitterAccount: 'VladimirSitnikv'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/16mtImj1dcWwUayYWgKs8Y/6b17815f9959308d75a6c2b0b9073bba/Aleksandrov__1_.jpg',
                name: 'Дмитрий Александров',
                affiliation: 'T-Systems',
                description: 'Ведущий программист/архитектор в T-Systems, по совместительству со-лидер болгарской Java User Group.',
                twitterAccount: 'bercut2000'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/3gM8xfRRroiZ00qBy2zMeQ/4c21e49b06f0a379d140356851ac0f9a/Valeev.jpg',
                name: 'Тагир Валеев',
                affiliation: 'JetBrains',
                description: 'Разработчик в JetBrains, занимается статическим анализатором кода IntelliJ IDEA, инспекциями и квик-фиксами. Также кидает патчики в OpenJDK и разрабатывает опенсорсную библиотеку StreamEx. Известен на Хабре как lany, в Twitter — как @tagir_valeev.',
                twitterAccount: 'tagir_valeev'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7FnTvLwAqWekJIlkSb5mSY/90a4fc100f1db0dd83816289fc37d63e/Kogun.jpg',
                name: 'Андрей Когунь',
                affiliation: 'КРОК',
                description: 'Основатель и организатор встреч московского сообщества Java-разработчиков JUG.MSK.',
                twitterAccount: 'akogun'
            },
        ]
    },
    {
        id: 'mobile-mobius-breakfast-4',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/OsD0VpCFgOU',
        date: '4 июня',
        timeStart: '11:00',
        timeEnd: '12:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: '"Hello, world!" с Mobius #4',
        tags: '#swift, #xcode, #appcode, #jetbrains, #germany, #it-community',
        description: [
            "\"Hello, World!\" с Mobius — это шоу о мобильной разработке, которое запустил программный комитет конференции. Гость нового выпуска — Айдар Мухаметзянов, который сейчас работает в JetBrains в команде AppCode, но все еще помнит, каково это — быть мобильным разработчиком. Айдар разрабатывал iOS-приложения со времен iOS 5 в мобильном агентстве, в небольшом стартапе, в большом и теплом 2GIS и в мюнхенском Freeletics. Пишет код на Kotlin/Java, чтобы людям было удобно писать на Swift/Objective-C.",
            "Ведущие выпуска — участники программного комитета Mobius Петр Третьяков (Redmadrobot) и Антон Давыдов (EPAM). Петр работает со Swift, любит UI и AppCode, а в свободное время развивает собственный iOS-проект с бэком на Rails, у которого больше 6 тысяч оценок в App Store. Антон — старший iOS-разработчик в компании EPAM, где помимо проектной работы занимается развитием петербургского и глобального мобильного сообщества в компании.",
            "Ведущие обсудят с Айдаром, каково было переехать в Мюнхен и адаптироваться к жизни в Германии, и расспросят его про местную IT-тусовку. Еще они поговорят про Swift и про то, куда движется экосистема Apple. Айдар коснется и своего доклада на Mobius 2020 Piter, посвященного AppCode под капотом."
        ],
        telegramLink: 'tg://resolve?domain=mobiusconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/7acXtv2mxfLYcmaRoaXWV4/1cb0fe3fa7bed468f81ed5f579c45948/Davydov.jpg?w=200',
                name: 'Антон Давыдов',
                affiliation: 'EPAM',
                description: 'Старший iOS-разработчик в компании EPAM, где помимо проектной работы занимается развитием петербургского и глобального мобильного сообщества в компании.',
                twitterAccount: 'dydus0x14'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/3CZuUPFjYb97as65a7ENlN/8f38d024310253c6c5b91e7008aa05e5/Tretyakov.jpg?w=200',
                name: 'Петр Третьяков',
                affiliation: 'Redmadrobot',
                description: 'Начал свой путь разработчика с персональных web-проектов на Ruby on Rails. Когда Apple представила альтернативу мешанине из всех видов открывающих и закрывающих скобок (читай: Objective-C) в виде Swift, понял, что мобильной разработкой наконец можно заниматься, и сменил уютненький Ruby на строго типизированный Swift. Любит работать с UI, предпочитает не захламлять Cartfile тем, что можно легко реализовать самому. Любит AppCode. В свободное время развивает собственный iOS-проект с бэком на Rails, у которого больше 6К оценок в App Store со средней оценкой в 4.8.',
                twitterAccount: 'petertretyakov'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/2VHpIB8Rn4NDpoaxWbRl1E/193ca50a2a4b1b4b434d1e591ec9e668/Aydar_Mukhametzyanov.jpg?w=200',
                name: 'Айдар Мухаметзянов',
                affiliation: 'JetBrains',
                description: 'Разрабатывал iOS-приложения со времен iOS 5 в мобильном агенстве, в небольшом стартапе, в большом и теплом 2GIS, и в мюнхенском Freeletics. Сейчас работает в JetBrains в команде AppCode, но все еще помнит, каково это — быть мобильным разработчиком. Пишет код на Kotlin/Java, чтобы людям было удобно писать на Swift/Objective-C.',
                twitterAccount: 'aydarmukh'
            }
        ]
    },
    {
        id: 'devops-brunch-3',
        hasPassed:  true,
        youtubeLink: 'https://www.youtube.com/embed/l5wJUO88TRM',
        date: '4 июня',
        timeStart: '18:00',
        timeEnd: '18:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Шоу "DevOops в рабочий полдник" №3',
        tags: '#devoops',
        description: [
            "«DevOops в рабочий полдник» — это шоу с участниками программного комитета и спикерами конференции DevOops 2020 Moscow о новостях, технологиях и жизни. Гость нового выпуска — Леонид Игольник. Всю свою карьеру он занимается онлайн-приложениями, начав путь в одном из первых интернет-провайдеров Израиля. Первый опыт работы с масштабными приложениями на Java Леонид получил в 2002-м, когда работал во втором по величине на тот момент доменном регистраторе.",
            "Ведущие выпуска — участники программного комитета DevOops Барух Садогурский и Вячеслав Кузнецов. Барух — Head of DevOps Advocacy и Developer Advocate в JFrog, компании, которая разработала универсальный менеджер репозитория Artifactory, а также соавтор книги Liquid Software. Вячеслав — соведущий встреч сообщества Hangops_ru, которое посвящено распространению DevOps-практик и обмену опытом.",
            "Ведущие поговорят с Леонидом про DevOps и обсудят их с Барухом совместный доклад на DevOops 2020 Moscow — «Устраиваем DevOps без полномочий: даже \"DevOps-инженер\" может помочь». Они расскажут, как повлиять на стейкхолдеров, как работать с возражениями и как мотивировать сотрудников."
        ],
        telegramLink: 'tg://resolve?domain=devOopsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1LoXqAs2dGOWIUC2cMooK4/057601cd55f057b4a437a4e2c2bf8fa5/2018-03-13_19.35.48.jpg?w=600',
                name: 'Барух Садогурский',
                affiliation: 'JFrog',
                description: 'Поскольку «религия не позволяет» быть евангелистом, Барух — developer advocate в компании JFrog и делает в жизни ровно 3 вещи: зависает с разработчиками Bintray и Artifactory, пописывает для них код и рассказывает о впечатлениях в блогах и на конференциях, таких как JavaOne, Devoxx, OSCON, конечно же, JPoint и Joker, да и многих других. И так более десяти лет подряд.',
                twitterAccount: 'jbaruch'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/2t2lc56zDT1h3xZMMMkjQa/eb32ce4cc8f26e1686317d2c8eb343fc/Kuznetsov.jpg?w=200',
                name: 'Вячеслав Кузнецов',
                affiliation: '',
                description: 'Вячеслав является соведущим встреч сообщества Hangops_ru, которое посвящено распространению DevOps-практик и обмену опытом.',
                twitterAccount: 'smith3v'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1XjK6yID2McSG8WaAIqCoq/5c7ee76f3d133ded6e05f9a51f9c0a6e/Igolnik.jpg?w=200',
                name: 'Леонид Игольник',
                affiliation: '',
                description: 'Леонид хорошо знаком и с разработкой, и с управлением, и с администрированием масштабных проектов. До недавней продажи SignalFx в Splunk on занимал должность Executive Vice President of Engineering в SignalFx. Всю свою карьеру он занимается онлайн-приложениями, начав её в одном из первых интернет-провайдеров Израиля. Первый опыт работы с масштабными приложениями на Java у него произошёл в 2002-м, когда он работал во втором по величине на тот момент доменном регистраторе.',
                twitterAccount: 'ligolnik'
            }
        ]
    },
    {
        id: 'dotnet-home-bar-1',
        hasPassed:  true,
        youtubeLink: 'https://www.youtube.com/embed/_hp2ZcgCjqU',
        date: '4 июня',
        timeStart: '19:30',
        timeEnd: '20:30',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Барная стойка / №1 // Андрей Акиньшин',
        tags: '##dotnext #ЖЗЛ',
        description: [
            "Помните тот самый Stay the F**k Home bar, о котором все говорили в начале карантина? Сначала все было отлично, но со временем гостей стало все меньше, а беседы за стойкой — все скучнее. Пришло время открыть собственный онлайн-бар — с околоайтишными разговорами и классными гостями.",
            "Программный комитет конференции DotNext запускает свое разговорное шоу «Барная стойка». Его ведущие — Михаил Щербаков и Максим Аршинов. Михаил — докторант в KTH Royal Institute of Technology, участник Microsoft Bug Bounty Programs, соорганизатор сообщества DotNet.ru. Максим — соучредитель казанской аутсорс-компании «Хайтек Груп», который также преподает в Высшей школе информационных технологий и информационных систем Казанского федерального университета и ведет блог на Хабре.",
            "Первым за барную стойку сядет Андрей Акиньшин, один из самых любимых спикеров DotNext. Андрей — соавтор и мейнтейнер библиотеки BenchmarkDotNet, автор книги Pro .NET Benchmarking. Сейчас он работает в JetBrains над Rider IDE. Любимые темы Андрея — производительность и микрооптимизации. Но говорить с ведущими он будет не только о технологиях, но и просто о жизни — так что подключайтесь к трансляции и заходите в наш бар в этот четверг."
        ],
        telegramLink: 'tg://resolve?domain=dotnextconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/9n3x4rtjlya6/5hBoerzyjQwP5suFuhqhJl/16cb19ac7d9dc0ffc92b4b513d99e644/Shcherbakov.jpg?w=200',
                name: 'Михаил Щербаков',
                affiliation: 'KTH Royal Institute of Technology',
                description: 'Докторант в KTH Royal Institute of Technology, участник Microsoft Bug Bounty Programs, со-организатор сообщества DotNet.ru. 10 лет работал в индустрии, сейчас занимается исследованиями в области безопасности приложений, статического и динамического анализа кода, применения формальных методов для поиска уязвимостей.',
                twitterAccount: 'yu5k3'
            },
            {
                photoLink: 'https://images.ctfassets.net/9n3x4rtjlya6/6bCu08LNoO8aDFyBWvd6JR/84004a776a0953940be5fc8787038284/arshinov.jpg?w=200',
                name: 'Максим Аршинов',
                affiliation: 'Хайтек Груп',
                description: 'Соучредитель казанской аутсорс-компании «Хайтек Груп». Кроме ведения бизнеса преподает в Высшей школе информационных технологий и информационных систем Казанского федерального университета и ведет блог на Хабре. Суммарный стаж в программировании и управлении разработкой — 10 лет. За это время прошел путь от программиста в казанской веб-студии до руководителя отдела качества в финансовой индустрии. Работал в компаниях Казани, Москвы, Питера и Антверпена (Бельгия). Считает, что технологии — это не «вещь в себе», а инструменты для достижения целей. Каждая — со своей областью применения.',
                twitterAccount: 'marshinov'
            },
            {
                photoLink: 'https://images.ctfassets.net/9n3x4rtjlya6/2OnQZ9i8lcBhIrEXZgmf22/39141db94544a39c5d40356029e1ae28/Akinshin.jpg?w=200',
                name: 'Андрей Акиньшин',
                affiliation: 'JetBrains',
                description: 'Любимые темы Андрея — производительность и микрооптимизации, он также мейнтейнер проекта BenchmarkDotNet (библиотека для написания .NET-бенчмарков, поддержанная .NET Foundation). Андрей — частый спикер на различных мероприятиях для разработчиков и член программного комитета конференции DotNext. Он также к.ф.-м.н., серебрянный медалист ACM ICPC. В свободное время любит заниматься наукой, основные научные интересы — математическая биология и теория бифуркаций. Раньше он работал постдоком в Институте Вейцмана и научным сотрудником в Институте математики СО РАН.',
                twitterAccount: 'andrey_akinshin'
            }
        ]
    },
    {
        id: 'c-plus-plus-morning-3',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/jUQe6-60smI',
        date: '5 июня',
        timeStart: '13:00',
        timeEnd: '13:40',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Утро с C++ Russia / №3 // Алексей Веселовский',
        tags: '',
        description: [
            'Пятница снова начинается с дружеской беседы про плюсы. Гость нового выпуска «Утро с C++ Russia» — Алексей Веселовский, старший С++ разработчик в Align Technology. Алексей занимается разработкой общих системных компонентов и фреймворка специализированной 3D CAD-системы. Ранее он разработал систему реалтайм-мониторинга пациентов, а также систему сбора и обработки данных для беспилотных автомобилей. Участвовал в проекте Squid-proxy.',
            'Ведущие — Сергей Платонов (организатор C++ Siberia) и Павел Филонов (разработчик ПО в области информационной безопасности). Они обсудят с Алексеем новости из мира С++, а также допотопные отладчики, внедрение анализаторов кода в проект, санитайзеры, С++ Russia и многое другое.'
        ],
        telegramLink: 'tg://resolve?domain=cpprussia',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1RVQKFKjEleCAAn2WsQqAv/8a8c5646e572407cce55eb6d6cc5db3a/Platonov.jpg?w=200',
                name: 'Сергей Платонов',
                affiliation: 'Tensor Technologies',
                description: 'C++ программист. Организатор C++ Siberia.',
                twitterAccount: 'sermp'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7K6BS8bMMueKKia7KQIaGY/611209d08d440588f812189d382adcf8/Filonov.jpg?w=200',
                name: 'Павел Филонов',
                affiliation: 'Лаборатория Касперского',
                description: 'Более 5 лет занимается разработкой ПО в области информационной безопасности. Более 3 лет активно использует методы анализа данных для разработки и внедрения методов машинного обучения в ИБ. До начала карьеры разработчика занимался исследованиями в области радиофизики и преподаванием в высшей школе.',
                twitterAccount: 'pavel_filonov'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/RsPJjd7HYaupPfDGX0wEy/bdce5ca9cb5e080a1c1c35aa646de1e7/Aleksey_Veselovskiy.jpg?w=200',
                name: 'Алексей Веселовский',
                affiliation: 'Align Technology',
                description: 'Старший С++ разработчик в Align Technology. Занимается разработкой общих системных компонентов и фреймворка специализированной 3D CAD-системы. Ранее разработал систему реалтайм мониторинга пациентов, а также систему сбора и обработки данных для self driving car. Участвовал в проекте Squid-proxy. В область интересов входят вопросы надежности ПО, компиляторостроения, системного программирования. Всегда старается докопаться до инстины.',
                twitterAccount: 'I_vlxy_I'
            }
        ]
    },
    {
        id: 'qa-survival-bias-6',
        youtubeLink: 'https://www.youtube.com/embed/_sN6JpVpQ-c',
        hasPassed: true,
        date: '5 июня',
        timeStart: '18:00',
        timeEnd: '19:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего» Episode 6',
        tags: '#qa #show',
        description: [
            'Ведущие поделятся новостями минувшей недели, ответят на ваши вопросы и сравнят TestNG и JUnit на реальных примерах.'
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ],
    },
    {
        id: 'js-holy-breakfast-5',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/cQ-b5LXPOLM',
        date: '9 июня',
        timeStart: '11:00',
        timeEnd: '12:00',
        complexityEmoji: '🐱',
        complexityTag: 'FUN',
        langTag: 'RU',
        title: 'Тяжелое утро HolyJS / №5 // ПК HolyJS',
        tags: '#holyjs',
        description: [
            "«Тяжелое утро с HolyJS» — это утреннее шоу, в котором программный комитет конференции обсуждает инструменты, задачки, технологии и новости IT. В этот раз участники комитета поговорят про предстоящую онлайн-конференцию HolyJS 2020 Piter, которая пройдет с 22 по 26 июня. Среди спикеров конференции — Charlie Gerard (Senior Frontend Developer в Netlify, GDE в веб-разработке, Mozilla Tech Speaker и увлеченный IoT-инженер), Damian Dulisz (участник core-команды Vue.js, Lead Frontend Engineer в Coursedog, автор нескольких опенсорс-библиотек) и многие другие.",
            "В эфире участники программного комитета подробно расскажут про программу HolyJS 2020 Piter, тематические блоки докладов и спикеров. "
        ],
        telegramLink: 'tg://resolve?domain=holyjsconf',
        speakers: [
            {
                photoLink: 'https://i.ibb.co/3vKdRK4/Denisyuk-500x500.png',
                name: 'Таня Денисюк',
                affiliation: 'JUG Ru Group',
                description: 'Программный координатор JUG Ru Group, нежно люблю фронтенд-сообщество и HolyJS, забочусь о спикерах и воспитываю 4 котов (включая Женю Кота)',
                twitterAccount: 'deni_deniskina'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/2Sr3POKsCQmEecYMe2eSIs/c55a1eb684aadf07411608e03235a735/avatar4.jpg?w=340',
                name: 'Евгений Кот',
                affiliation: 'Wrike',
                description: 'Долгое время занимался .NET, Microsoft stack. Работал в Quest Software, после этого в Dell, где начал заниматься фронтендом. Прошёл путь от CoffeeScript + Angular 1.0 до Dart + Angular 2.0. На данный момент трудится в Wrike, высоконагруженном SaaS-приложении с очень богатым клиентским кодом. Команда Wrike пишет на Dart и Angular 2.0 и стремится быть up-to-date со всеми современными течениями в мире веб.',
                twitterAccount: 'bunopus'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4B1ZPeV08JiSrblqJok2ev/34be2c3aabdb9832de2c4d763137c600/Kobzar.jpg?w=340',
                name: 'Артем Кобзарь',
                affiliation: '',
                description: 'Cэмпай в JavaScript.Ninja, ведущий подкаста UnderJS, язычник, маргинал, традиционалист, постмодернист, андердог./integration/E2E/B2B-тестов, используя mocha/chai/sinon. Большой опыт в технологиях веб-разработки, шаблонах проектирования, проектирования баз данных, разработки алгоритмов и структуры данных. Есть опыт в разработке проектных заданий и технической документации.',
                twitterAccount: 'rage_monk'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4mvjaY8IMaoTMu0xL4IDz4/96bcbcdb3f9ca77f8c9d187cc517cb14/Vasiliy_Vanchuk.jpg?w=340',
                name: 'Василий Ванчук',
                affiliation: '',
                description: 'Увлеченный разработчик, который любит нестандартные задачи, любит как кодить, так и общаться. Обращает внимание на саморазвитие, много читает и помогает развиваться другим. Преподает на курсах и является активным участником сообщества Web Not Bombs./integration/E2E/B2B-тестов, используя mocha/chai/sinon. Большой опыт в технологиях веб-разработки, шаблонах проектирования, проектирования баз данных, разработки алгоритмов и структуры данных. Есть опыт в разработке проектных заданий и технической документации.',
                twitterAccount: 'vvscode'
            },
        ]
    },
    {
        id: 'java-jpoint-coffee-4',
        fuckupUrl: 'jpoint-coffee-4',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/Cy05rCIAFPY',
        date: '10 июня',
        timeStart: '11:00',
        timeEnd: '11:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Первая чашка кофе с JPoint / №4 // Олег Докука',
        tags: '',
        description: [
            "Утреннее шоу «Первая чашка кофе с JPoint» — это встречи программного комитета конференции JPoint с ее спикерами.",
            "Гость нового выпуска — Олег Докука, который разрабатывает корпоративное ПО и распределенные системы, в основном используя стек Spring. С самого начала разработки Spring 5 Олег внимательно следит за развитием фреймворка и активно продвигает публике Reactive-решения, основанные на Spring 5 и Reactor 3. Кроме того, Олег — коммиттер Reactor 3, а также спикер таких конференций, как JEEConf и JavaDay Ukraine. Еще он написал книгу Reactive Programming with Spring 5.",
            "Ведущие выпуска — участники программного комитета конференции JPoint Антон Архипов и Максим Гореликов. Антон — Developer Advocate в компании JetBrains, резидент подкаста «Разбор полетов». Максим использует экосистему Spring и Netflix, экспериментирует с реактивными подходами, работает с инфраструктурой (логи, CI/CD, оркестрация) и тестами. ",
            "Вместе с Олегом они обсудят в эфире карантин и то, как он повлиял на работу, поговорят про релиз RSocket и про то, везде ли нужно использовать React."
        ],
        telegramLink: 'tg://resolve?domain=jpointconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/4N8NimCihZPMiISDlJqXEc/29813cdee95937b7adf820ff178cadeb/dokuka.jpg',
                name: 'Олег Докука',
                affiliation: 'Netifi, Inc',
                description: 'Инженер ПО, уже более 7 лет занимается разработкой ПО в различных областях. В последнее время активно разрабатывает корпоративное ПО и распределённые системы, в основном используя стек Spring. С самого начала разработки Spring 5 внимательно следит за развитием фреймворка и активно продвигает публике Reactive-решения, основанные на Spring 5 и Reactor 3. Кроме того, Олег — коммиттер Reactor 3, а также спикер таких конференций, как JEEConf и JavaDay Ukraine. Автор книги «Reactive Programming with Spring 5».',
                twitterAccount: 'OlehDokuka'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5P1Kd3hcFVE3HEXjH1yduN/9eb021a69c50918371b92e5be39467a4/Arhipov.jpg',
                name: 'Антон Архипов',
                affiliation: 'JetBrains',
                description: 'Product Manager в проекте Kotlin, в компании JetBrains, резидент подкаста «Разбор полетов»',
                twitterAccount: 'antonarhipov'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5BS4ZmCOWVF24hO952XAg4/2185c68e8f3b40ec55dc9e3572cba9b5/Gorelikov.jpg',
                name: 'Максим Гореликов',
                affiliation: 'Экосистема недвижимости "М2"',
                description: ' Техлид, использует экосистему Spring и что найдет на github, экспериментирует с реактивными подходами, интересуется инфраструктурой.',
                twitterAccount: 'gorelikoff'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7FnTvLwAqWekJIlkSb5mSY/90a4fc100f1db0dd83816289fc37d63e/Kogun.jpg',
                name: 'Андрей Когунь',
                affiliation: 'КРОК',
                description: 'Основатель и организатор встреч московского сообщества Java-разработчиков JUG.MSK.',
                twitterAccount: 'akogun'
            },
        ]
    },
    {
        id: 'qa-heisenbug-breakfast-4',
        fuckupUrl: 'heisenbug-breakfast-4',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/zCTieU3adK0',
        date: '10 июня',
        timeStart: '11:00',
        timeEnd: '11:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Тяжелое утро с Heisenbug / №4 // ПК Heisenbug',
        tags: '#heisenbug #program',
        description: [
            "В новом выпуске шоу «Тяжелое утро с Heisenbug» соберутся четыре участника программного комитета конференции. Они подробно обсудят программу Heisenbug, который пройдет c 15 по 18 июня, разберут доклады и расскажут о ключевых событиях этих четырех дней. ",
            "В эфир выйдут: ",
            "— Никита Макаров. Занимался автоматизацией встраиваемых операционных систем на базе Linux, комплексных VPN-решений для бизнеса, программно-аппаратных комплексов. С 2012 по 2019 года работал руководителем группы автоматизации тестирования в проекте Одноклассники. Сейчас руководит автоматизацией тестирования в группе компаний Тинькофф.",
            "— Михаил Чумаков. Профессионально занимается тестированием более 10 лет. Работал в огромных корпорациях и маленьких стартапах. Собирал команды, автоматизировал, обучал коллег, отвечал за качество. Сейчас Михаил продолжает образование в одном из вузов Канады.",
            "— Дарья Манухина. Руководитель по развитию продуктов в Калуга Астрал. Один из организаторов GDG Kaluga. Более 6 лет занималась тестированием: построением и улучшением процессов, формированием команд и автоматизацией. ",
            "— Авенир Воронов. DM в компании EPAM. Работал разработчиком в HP, Luxoft, Motorola. Прошел путь от программиста до CTO. Ведет тренинги."
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/6HjOTaXimcECcYgA2E00wi/4ec2621d40c064a4e03468f4923d4ec7/___________________________.jpg?w=200',
                name: 'Никита Макаров',
                affiliation: 'Тинькофф',
                description: 'Работал в аутсорсинге и продуктовых компаниях. Занимался автоматизацией встраиваемых операционных систем на базе Linux, комплексных VPN-решений для бизнеса, программно-аппаратных комплексов. С 2012 по 2019 года работал руководителем группы автоматизации тестирования в проекте «Одноклассники». Сейчас руководит автоматизацией тестирования в группе компаний «Тинькофф»',
                twitterAccount: 'PapaMinos'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/1g2xxe4E88Kis0KeWAw6U8/53dad8b6db56a016c784fb48de0531ec/c47105e5-75b0-4e2f-a970-a82375983016.jpg?w=200',
                name: 'Михаил Чумаков',
                affiliation: '',
                description: 'Профессионально занимается тестированием более 10 лет, не профессионально — остальное время. В течение карьеры работал в огромных корпорациях и маленьких стартапах. Собирал команды, автоматизировал, обучал коллег, отвечал за качество. Любит самообразовываться и постоянно изучать что-то новое. Сейчас Михаил продолжает образование в одном из ВУЗов Канады.',
                twitterAccount: 'ainomc1'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/4rFSQpKle1lfuh6nqXp24V/8605818a06bda1794c9f950bd11a34cb/manuhina.jpg?w=200',
                name: 'Даша Манухина',
                affiliation: 'Калуга Астрал',
                description: 'Руководитель по развитию продуктов в "Калуга Астрал". Один из организаторов GDG Kaluga. Более 6 лет занималась тестированием: построением и улучшением процессов, формированием команд и автоматизацией. Не мыслит свою жизнь без творчества и постоянного развития.',
                twitterAccount: 'ManukhinaDarya'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/3ATywAPoYXADt4e3xSMHD3/5a540263d70c79ebed6eba59f716e65d/100065_668442024_Avenir_Voronov.JPG?w=200',
                name: 'Авенир Воронов',
                affiliation: 'EPAM',
                description: 'DM в компании EPAM. Работал разработчиком в HP, Luxoft, Motorola. Прошел путь от программиста до CTO. Ведет тренинги и участвует в организации конференций Jug Ru Group. Есть кот.',
                twitterAccount: ''
            },
        ]
    },
    {
        id: 'hydra-heads-4',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/C8CawdH3RFI',
        date: '10 июня',
        timeStart: '20:00',
        timeEnd: '20:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Головы Гидры / №4 // Олег Анастасьев ',
        tags: '#hydraconf',
        surveyMonkey: 'https://ru.research.net/r/meetup_100620_hydra',
        description: [
            '«Головы Гидры» — это разговорное шоу конференции по разработке многопоточных и распределенных систем Hydra, где участники программного комитета в прямом эфире обсуждают доклады и беседуют друг с другом. ',
            'В этом выпуске ведущие Алексей Федоров и Виталий Аксенов встретятся с Олегом Анастасьевым. Олег начал карьеру программиста в 1995 году. За годы работы он разрабатывал ПО в банковской сфере, телекоме, транспорте, государственном управлении. Также он занимался классами, музыкой, фотками, мессаджингом и другими продуктами Одноклассников. Сейчас в его обязанности входит разработка архитектур и решений для высоконагруженных систем, больших хранилищ данных, решение проблем производительности и надежности портала. В последнее время Олег занимался созданием С*One (распределенной отказоустойчивой NewSQL СУБД) и one-cloud — частным облаком, управляющим всеми серверами Одноклассников.',
            'На конференции Hydra 2020, которая пройдет с 6 по 9 июля, Олег выступит с докладом про микросервисы, которые используют в Одноклассниках для обработки запросов пользователей. На «Головах Гидры» он подробно расскажет о своем докладе.'
        ],
        telegramLink: 'tg://resolve?domain=hydraconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/AG4VkryqvIAzn7Udn7vPt/c5fcc77d13228c4d88bf59b6284e323a/anastasiev.jpg?w=200',
                name: 'Олег Анастасьев',
                affiliation: 'Одноклассники',
                description: 'Oleg Anastasyev started his career in computer programming in 1995. He developed banking, telecom, public transportation software as well as software for the government of Latvia. Oleg is a principal engineer at Odnoklassniki (ok.ru). His primary responsibilities are development of architectures and solutions for highly loaded as well as big data services, solving performance and availability problems. His lastest successful projects include NewSQL ACID compliant distributed fault-tolerant database and private cloud system to help manage the whole fleet of Odnoklassniki machines.',
                twitterAccount: 'm0nstermind'
            },
            {
                photoLink: 'https://pbs.twimg.com/profile_images/904461024786513920/xqFuwPeM_400x400.jpg',
                name: 'Алексей Федоров',
                affiliation: 'JUG Ru Group',
                description: 'Лидер питерского JUG и организатор Java-конференций Joker и JPoint',
                twitterAccount: '23derevo'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5aYfREZK1x2E0o598oGsQd/0a60f37b8919975d0c36e44b8a9d0425/Aksenov.jpg?w=200',
                name: 'Виталий Аксенов',
                affiliation: 'ITMO University',
                description: 'Сотрудник кафедры «Компьютерные Технологии» Университета ИТМО.',
            },
        ]
    },
    {
        id: 'mobile-mobius-hello-world-5',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/8ZPBYYopaOk',
        date: '11 июня',
        timeStart: '18:00',
        timeEnd: '19:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: '"Hello, world!" с Mobius / №5 // Степан Гончаров',
        tags: '#development #relocation #devops #android #flutter #gradle #bazel #bestpractices #californication',
        description: [
            "В эфир пятого выпуска \"Hello, world!\" с Mobius придет известный спикер, разработчик и наш друг - Степан Гончаров из компании Level 5.",
            "Ведущие — участники программного комитета конференции по мобильной разработке Mobius Михаил Емельянов (ЦФТ) и Женя Сатуров (Surf). Они поговорят со Степой про его богатый опыт разработки под Android и не только. Степа поделится тем, как у него получилось поработать в России, Сингапуре и, наконец, в Калифорнии в таких компаниях, как 90seconds и Grab, и подробно расскажет про своего актуального работодателя — Level 5. В беседе ребята также затронут инженерную культуру, опыт релокации, кроссплатформу и Flutter, а также узнают, почему Степа не будет делать доклады про Gradle.",
            "Напомним, что на Mobius 2020 Piter, который пройдет с 22 по 25 июня в онлайне, Степа выступит с докладом. Он расскажет, почему не стоит бояться Bazel в Android-разработке, что из себя представляет эта мощная система сборки и почему многие крупные компании используют Bazel. Он также коснется функциональности Bazel и покажет, как просто сейчас применять его в ваших проектах."
        ],
        telegramLink: 'tg://resolve?domain=mobiusconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/7j1wl8vrMWlLSqWGpBG7RF/de7b3db1f9f0cdca8d57c13cac87bda4/goncharov.jpg?w=200',
                name: 'Степан Гончаров',
                affiliation: 'Lyft',
                description: 'Разрабатывает приложения и игры под Android c 2008 года, за свою карьеру успел побывать в роли QA, менеджера, маркетолога, блогера, аналитика, советника и многих других. Участвовал в разработке как никому неизвестных приложений, так и приложений с миллионами пользователей по всему миру. В настоящее время работает в Grab, является лидером Kotlin Singapore User Group, активно использует Rx и все больше времени посвящает OSS.',
                twitterAccount: 'stepango'
            },
            {
                photoLink: 'https://i.ibb.co/h9JVMs3/Yemelyanov.png',
                name: 'Михаил Емельянов',
                affiliation: 'ЦФТ',
                description: 'В Android-разработке с 2010 года. Участвовал в разработке проектов от мультимедийных до финтеха. Катается на сноуборде, любит пухляк и высокие трамплины. Меломан, играет на гитаре.',
                twitterAccount: 'mike_emelyanov'
            },
            {
                photoLink: 'https://images.ctfassets.net/2grufn031spf/7hVTUwBmZFz8s4vgrgTu1k/0164c9b59a4f7e997b2b0bbb4806876d/Yevgeniy_Saturov.jpg?w=200',
                name: 'Евгений Сатуров',
                affiliation: 'Surf',
                description: 'Провёл 4,5 года в Android-разработке, но год назад радикально поменял свою карьеру и впервые соприкоснулся с кроссплатформой. Вдохновился пребыванием на тёмной стороне настолько, что основал Flutter Dev Podcast и с тех пор является его бессменным ведущим. Крашит виджеты, двигает сливеры, заполняет скаффолды и делает другие вещи. PC Mobius member, GDG Voronezh lead и активный спикер.',
                twitterAccount: 'saturovv'
            }
        ]
    },
    {
        id: 'devops-brunch-4',
        hasPassed:  true,
        youtubeLink: 'https://www.youtube.com/embed/aA_7BS6WCzU',
        date: '11 июня',
        timeStart: '18:00',
        timeEnd: '18:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'DevOops в рабочий полдник / №4 // Антон Вайс',
        tags: '#devoops',
        description: [
            "Гость нового выпуска шоу «DevOops в рабочий полдник» — Антон Вайс, IT-футурист, эксперт по техническому преподаванию, спикер DevOops 2020 Moscow.",
            "Ведущие — участники программного комитета DevOops Барух Садогурский и Алексей Кирпичников. Барух — Head of DevOps Advocacy и Developer Advocate в JFrog, компании, которая разработала универсальный менеджер репозитория Artifactory. Алексей работает в Контуре и делает инфраструктуру-как-сервис для разработчиков продуктов этой компании.",
            "В эфире ведущие обсудят с Антоном новости, технологии и просто поболтают о жизни. И, конечно же, расспросят Антона о его докладе на DevOops 2020 Moscow про стадии DevOps-развития."
        ],
        telegramLink: 'tg://resolve?domain=devOopsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/3wciqev5tZ2xjVPPCHUHPj/94cd03f9ad79f7008660bb5feeefa672/weiss.jpg?w=200',
                name: 'Антон Вайс',
                affiliation: 'OtomatoSoftware',
                description: 'Совладелец технологического консалтинга Otomato Software, обладатель более чем 15-летнего опыта в области высоких технологий. Является экспертом по техническому преподаванию, инициатором и соавтором первого в Израиле курса DevOps-сертификации. Антон участвует в международных конференциях и известен как крутой докладчик.',
                twitterAccount: 'antweiss'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1LoXqAs2dGOWIUC2cMooK4/057601cd55f057b4a437a4e2c2bf8fa5/2018-03-13_19.35.48.jpg?w=600',
                name: 'Барух Садогурский',
                affiliation: 'JFrog',
                description: 'Поскольку «религия не позволяет» быть евангелистом, Барух — developer advocate в компании JFrog и делает в жизни ровно 3 вещи: зависает с разработчиками Bintray и Artifactory, пописывает для них код и рассказывает о впечатлениях в блогах и на конференциях, таких как JavaOne, Devoxx, OSCON, конечно же, JPoint и Joker, да и многих других. И так более десяти лет подряд.',
                twitterAccount: 'jbaruch'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7Dw30diwYmaiFeTDlYE7u9/9d47fdc5166d595ae150ca5cc54d4704/kirpichnikov.jpg?w=200',
                name: 'Алексей Кирпичников',
                affiliation: 'Контур',
                description: 'Алексей работает в Контуре и делает инфраструктуру-как-сервис для разработчиков продуктов этой компании.',
                twitterAccount: ''
            },
        ]
    },
    {
        id: 'js-piterjs-48',
        hasPassed: true,
        // hlsPlaylistLink: 'https://d1vpkt96p37mm7.cloudfront.net/piterjs47/piterjs47.m3u8',
        youtubeLink: 'https://www.youtube.com/embed/ANbnQhxos-A',
        date: '11 июня',
        timeStart: '19:00',
        timeEnd: '21:00',
        complexityEmoji: '👷‍♀️',
        complexityTag: 'PRACTICAL',
        langTag: 'RU',
        title: 'Митап PiterJS #48',
        tags: '#piterjs, #piterjs48, #js, #javascript',
        description: [
            'Поговорим про юридические риски для программиста, про игру на Firebase и про экспериментальный Node.js отладчик. Присоединяйтесь, будет интересно.',
            'Расписание:',
            '19:10–19:40 — «Юридические риски для программиста», Ян Клименков: ',
            'Расскажу про использование кода из открытых библиотек: правовое регулирование, про систему контроля версий и отношения с работодателем.',
            '19:50–20:20 — «Пишем многопользовательскую игру с помощью Firebase», Kir JS: ',
            'В этом докладе я напишу с нуля многопользовательскую игру с использованием сервисов Firebase. В результате вы узнаете как использовать удаленную базу данных Firestore, добавить аутентификацию через сторонние сервисы и загрузить результат в Firebase Hosting. Если боги лайв-кодинга будут благосклонны, то и поучаствуете в турнире по каменьножицебумаге 🔥',
            '20:30–21:00 — «Отлаживаем Node.js в Chrome DevTools максимально удобно», Алексей Козятинский: ',
            'В докладе я покажу в работе экспериментальный Node.js отладчик — ndb, построенный на основе Chrome DevTools. Как он может экономить ваше время, почему мы когда-то решили его делать, как оно все устроено внутри, и как вы можете помочь этому проекту.'
        ],
        telegramLink: 'tg://resolve?domain=piterjsflood',
        speakers: [
            {
                photoLink: 'https://i.ibb.co/c1fBmXW/h-J2-Qq-Oke-400x400.jpg',
                name: 'Михаил Полубояринов',
                affiliation: 'SEMrush',
                description: 'Работаю в SEMrush, организатор PiterJS, в разработке с 2004 года.',
                twitterAccount: 'mike1pol'
            },
            {
                photoLink: 'https://i.ibb.co/FnLfNFY/photo-2020-06-04-18-42-31.png',
                name: 'Анна Волкова',
                affiliation: 'Альфа-банк',
                description: 'Занимаюсь фронтендом в Альфа-Банке, организатор PiterJS.',
                twitterAccount: 'volkova_annie'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*ax0wJDkMrSmYM_-vw9wTiQ.png',
                name: 'Ян Клименков',
                affiliation: 'Юридическая фирма K&U Law',
                description: 'Ранее работал в крупнейшем в России рекламном интернет-агентстве, сооснователь юр.фирмы, специализирующейся на IT и Digital.',
                twitterAccount: ''
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*lGXBNwcw1SzJlZKcjzsjrQ.png',
                name: 'Kir JS',
                affiliation: 'Firebase',
                description: 'Родился в Москве, сейчас живу в Нью-Йорке и работаю в Firebase. Обучаю Angular не только в Google, но и во всем мире, организую самый большой Angular-митап в мире — AngularNYC (а также VueNYC и ReactNYC). В свободное от программирования время увлекаюсь танго, книгами и приятными беседами.',
                twitterAccount: 'kirjs'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*t-NZOBm5dQ3r4dazsu4zaQ.png',
                name: 'Алексей Козятинский',
                affiliation: 'Netflix',
                description: 'Я пять лет работал в Google над JavaScript отладчиком в команде Chrome DevTools. Написал своими руками и фронтенд часть, и большую часть бэкенда в V8. Сейчас работаю в Netlix и отвечаю за успех переезда нашего основного приложения с очень старого JSC на новый модный V8. Вероятно вы видели мои доклады с Holy.js, где я рассказывал про Chrome DevTools и Chrome DevTools protocol. :)',
                twitterAccount: 'ak_239'
            },
        ]
    },
    {
        id: 'c-plus-plus-morning-4',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/hC9a8F8qojc',
        date: '12 июня',
        timeStart: '13:00',
        timeEnd: '13:40',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Утро с C++ Russia / №4 // ПК С++ Russia',
        tags: '#cpprussia #program',
        description: [
            "«Утро с C++ Russia» — это шоу, в котором программный комитет конференции обсуждает инструменты, задачки, технологии и новости IT. В этот раз участники комитета поговорят про предстоящую онлайн-конференцию C++ Russia 2020 Moscow, которая пройдет с 29 июня по 2 июля. В этом году среди ее спикеров — создатель языка C++ Bjarne Stroustrup и председатель ISO C++ Standard Committee Herb Sutter. ",
            "В эфире участники программного комитета подробно расскажут про программу C++ Russia 2020 Moscow, тематические блоки докладов и спикеров. "
        ],
        telegramLink: 'tg://resolve?domain=cpprussia',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1RVQKFKjEleCAAn2WsQqAv/8a8c5646e572407cce55eb6d6cc5db3a/Platonov.jpg?w=200',
                name: 'Сергей Платонов',
                affiliation: 'Tensor Technologies',
                description: 'C++ программист. Организатор C++ Siberia.',
                twitterAccount: 'sermp'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7K6BS8bMMueKKia7KQIaGY/611209d08d440588f812189d382adcf8/Filonov.jpg?w=200',
                name: 'Павел Филонов',
                affiliation: 'Лаборатория Касперского',
                description: 'Более 5 лет занимается разработкой ПО в области информационной безопасности. Более 3 лет активно использует методы анализа данных для разработки и внедрения методов машинного обучения в ИБ. До начала карьеры разработчика занимался исследованиями в области радиофизики и преподаванием в высшей школе.',
                twitterAccount: 'pavel_filonov'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1nTRqaLOzESBQ57RubQvPz/d26a7f7a2d8fd94ec656f705ab198458/Kazakova.jpg?w=200',
                name: 'Анастасия Казакова',
                affiliation: 'JetBrains',
                description: 'Имеет опыт более 8 лет в коммерческой разработке на C и C++. В прошлом занималась встроенными система и задачами управления сетевым трафиком, стажировалась в лаборатории Microsoft Research по направлению Сетевой трафик и участвовала в запуске сетей поколения 4G в компании Yota. Анастасия уверена в том, что разработка — процесс творческий, а рутинные задачи должны быть переданы «умным» инструментам. Поэтому сейчас она является частью команды JetBrains и работает менеджером по продуктовому маркетингу продуктов для разработки на C++ и .NET. Уже несколько лет занимается организацией встреч питерского сообщества C++ разработчиков (St. Petersburg C++ User Group).',
                twitterAccount: 'anastasiak2512'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5NkCaU6kXMPc5JBLBgS4OI/75cce4516eff4f987e3b1e0530ca2b15/shamanina.jpg?w=200',
                name: 'Елизавета Шаманина',
                affiliation: 'JUG Ru Group',
                description: 'Программный координатор конференции С++ Russia в JUG Ru Group.',
                twitterAccount: 'liz_shamanina'
            }
        ]
    },
    {
        id: 'qa-survival-bias-7',
        youtubeLink: 'https://www.youtube.com/embed/clh_OsK_eJ8',
        hasPassed: true,
        date: '12 июня',
        timeStart: '18:00',
        timeEnd: '19:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего» Episode 7',
        tags: '#qa #show',
        description: [
            'Ведущие поделятся новостями минувшей недели, ответят на ваши вопросы и проверят как работают Self-Healing инструменты.'
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ],
    },
    {
        id: 'java-jpoint-coffee-5',
        fuckupUrl: 'jpoint-coffee-5',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/FW-oPxeBxCs',
        date: '17 июня',
        timeStart: '12:00',
        timeEnd: '12:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Первая чашка кофе с JPoint / №5 // Иван Углянский',
        tags: '',
        description: [
            "На этой неделе гость шоу «Первая чашка кофе с JPoint» — Иван Углянский. Иван работал в компании Excelsior c 2011 года, где являлся одним из разработчиков Excelsior JET: сертифицированной реализации Java SE, основанной на оптимизирующей AOT-компиляции. Сейчас он работает в Huawei над компиляторами, JVM и новыми языками программирования. В основном занимается рантаймом: GC, загрузкой классов, поддержкой многопоточности, профилированием и многим другим. Также Иван — один из основателей и лидеров JUGNsk, официальной Java User Group в Новосибирске. Преподает в НГУ на курсах по C/C++.",
            "Ведущие эфира — Андрей Когунь и Дмитрий Александров. Андрей — эксперт в департаменте разработки ПО в КРОК, основатель встреч jug.msk.ru. Дмитрий — ведущий программист и архитектор в T-Systems, по совместительству один из лидеров болгарской Java User Group.",
            "В студии ведущие обсудят с Иваном баги в Native, коснутся работы Panama, Loom, Valhalla, GraalVM, а также расспросят Ивана про его доклад на JPoint 2020, посвященный путешествию из уютного мира Java в нативный код. "
        ],
        telegramLink: 'tg://resolve?domain=jpointconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/1u8fr0uaS7sLocTSy8MCZ4/fe7087ee1bf692e7e25f7bfa5f61929c/kXjgjPbMYcY.jpg',
                name: 'Иван Углянский',
                affiliation: 'Huawei',
                description: 'Работал в компании Excelsior c 2011 года, где являлся одним из разработчиков Excelsior JET: сертифицированной реализации Java SE, основанной на оптимизирующей AOT-компиляции. Сейчас работает в Huawei над компиляторами, JVM и новыми языками программирования. В основном занимается рантаймом: GC, загрузка классов, поддержка многопоточности, профилирование и т. д. Также является одним из основателей и лидеров JUGNsk — официальной Java User Group в Новосибирске, и членом программного комитета конференции SnowOne. В качестве хобби преподает в НГУ курсы по C/C++.',
                twitterAccount: 'dbg_nsk'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/16mtImj1dcWwUayYWgKs8Y/6b17815f9959308d75a6c2b0b9073bba/Aleksandrov__1_.jpg',
                name: 'Дмитрий Александров',
                affiliation: 'T-Systems',
                description: 'Ведущий программист/архитектор в T-Systems, по совместительству со-лидер болгарской Java User Group.',
                twitterAccount: 'bercut2000'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7FnTvLwAqWekJIlkSb5mSY/90a4fc100f1db0dd83816289fc37d63e/Kogun.jpg',
                name: 'Андрей Когунь',
                affiliation: 'КРОК',
                description: 'Основатель и организатор встреч московского сообщества Java-разработчиков JUG.MSK.',
                twitterAccount: 'akogun'
            },
        ]
    },
    {
        id: 'hydra-heads-5',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/BP00xihSQo8',
        date: '17 июня',
        timeStart: '19:00',
        timeEnd: '19:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Головы Гидры / №5 // Андрей Сатарин',
        tags: '#hydraconf',
        description: [
            '«Головы Гидры» — это разговорное шоу конференции по разработке многопоточных и распределенных систем Hydra, где участники программного комитета в прямом эфире обсуждают доклады и беседуют друг с другом. ',
            'В этом выпуске ведущие Алексей Федоров и Виталий Аксенов встретятся с Андреем Сатариным. Андрей работает над Amazon Aurora в компании Amazon Web Services. В прошлом он занимался тестированием распределенной базы данных NewSQL в Яндексе, системой облачного детектирования в Лаборатории Касперского, многопользовательской игрой в Mail.ru и сервисом расчета валютных цен в Deutsche Bank. Интересуется тестированием крупномасштабных backend- и распределенных систем.'
        ],
        telegramLink: 'tg://resolve?domain=hydraconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/57ZjF4ZjJPjtZEhWNVcTD3/a9e58696f702dbb200f2083f89193202/Satarin.jpg?w=200',
                name: 'Андрей Сатарин',
                affiliation: 'Amazon Web Services',
                description: 'Andrey is working on Amazon Aurora at Amazon Web Services. In the past he has worked on: testing a distributed NewSQL database at Yandex, cloud antivirus detection at Kaspersky Lab, an online multiplayer game at Mail.ru, and a foreign exchange pricing service at Deutsche Bank. Andrey is interested in bulding large scale distributed databases and backend systems.',
                twitterAccount: 'asatarin'
            },
            {
                photoLink: 'https://pbs.twimg.com/profile_images/904461024786513920/xqFuwPeM_400x400.jpg',
                name: 'Алексей Федоров',
                affiliation: 'JUG Ru Group',
                description: 'Лидер питерского JUG и организатор Java-конференций Joker и JPoint',
                twitterAccount: '23derevo'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5aYfREZK1x2E0o598oGsQd/0a60f37b8919975d0c36e44b8a9d0425/Aksenov.jpg?w=200',
                name: 'Виталий Аксенов',
                affiliation: 'ITMO University',
                description: 'Сотрудник кафедры «Компьютерные Технологии» Университета ИТМО.',
            },
        ]
    },
    {
        id: 'devops-brunch-5',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/8iSubpC10_Q',
        date: '18 июня',
        timeStart: '18:00',
        timeEnd: '18:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'DevOops в рабочий полдник / №5 // Дмитрий Столяров',
        tags: '#devoops',
        description: [
            "Гость нового выпуска шоу «DevOops в рабочий полдник» — Дмитрий Столяров. Дмитрий — технический директор и соучредитель компании Флант. У него за плечами — 14 лет с Linux, 10 лет эксплуатации, более 30 highload-проектов. Программирует на C++, PHP, Python и Bash, любит C и Ruby, читает на Go. Понимает Docker и Kubernetes. Архитектор и соавтор nginx-http-rdns, pam_docker, php_fpm_docker, werf и loghouse.",
            "Ведущие — Максим Гореликов и Александр Дрянцов. Максим более 10 лет разрабатывал все, начиная от софта для сейсморазведки до финтеха, интересуется cloud-native, реактивными системами и инфраструктурой. Александр 13 лет занимается проектированием, построением и дальнейшей поддержкой высоконагруженных производительных систем в e-commerce стартапах. Последние три года занимался изучением внутренностей Kubernetes, работы его сети и контроллеров, а также разработкой своих контроллеров, внедрением Kubernetes в Production- и Dev-инфраструктуры в компании Ecwid.",
            "Ведущие обсудят с Дмитрием, как и где разворачивать Kubernetes и какие при этом могут возникнуть проблемы."
        ],
        telegramLink: 'tg://resolve?domain=devOopsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/4sA4xlvNTWDcL33E7YhsHD/b12b886e0d83d7831d45e149d8a1bb8e/stolyarov.jpg?w=200',
                name: 'Дмитрий Столяров',
                affiliation: 'Флант',
                description: 'Технический директор и соучредитель компании Флант. 14 лет с Linux, 10 лет эксплуатации, более 30 highload-проектов. Программирует на C++, PHP, Python и Bash. Любит C и Ruby. Читает на Go. Активно использовал Chef, но потерял в него веру и осваивает Ansible. Понимает Docker и Kubernetes. Архитектор и соавтор: nginx-http-rdns, pam_docker, php_fpm_docker, werf и loghouse.',
                twitterAccount: 'dmistol'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5BS4ZmCOWVF24hO952XAg4/2185c68e8f3b40ec55dc9e3572cba9b5/Gorelikov.jpg?w=200',
                name: 'Максим Гореликов',
                affiliation: 'Экосистема недвижимости «М2»',
                description: 'Более 10 лет разрабатывает всё подряд, начинал с С/С++ и геофизики, сейчас JVM-языки/JS и финансовые сервисы. В основном использует экосистему Spring и Netflix, но пробует всё, что найдет хорошего на GitHub. Экспериментирует с реактивными подходами. Интересуется тем, как работают приложения от начала до конца, поэтому работает с инфраструктурой (логи, CI/CD, оркестрация) и тестами. В общем, DevOps — наше всё и всё такое.',
                twitterAccount: 'gorelikoff'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/29wNI1ThrlSVwlTfyJsLnt/dabb204c6d82439a7daa292ac073cdb9/Aleksandr_Dryantsov.jpg?w=200',
                name: 'Александр Дрянцов',
                affiliation: 'Ecwid',
                description: '13 лет занимается проектированием, построением и дальнейшей поддержкой высоконагруженных производительных систем в e-commerce стартапах. Много лет оттачивал навыки скоростного тушения пожаров в большой микросервисной инфраструктуре. Последние 3 года занимался изучением внутренностей Kubernetes, работы его сети и контроллеров, а также разработкой своих контроллеров, внедрением Kubernetes в Production- и Dev-инфраструктуру в компании Ecwid. Продвигает Kotlin как лучший вариант статически типизированного языка для написания Kubernetes-контроллеров и системного программирования в целом.',
                twitterAccount: 'b3zn0gim'
            },
        ]
    },
    {
        id: 'qa-survival-bias-8',
        youtubeLink: 'https://www.youtube.com/embed/LeA2_GJ1e70',
        hasPassed: true,
        date: '19 июня',
        timeStart: '18:00',
        timeEnd: '19:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего» Episode 8',
        tags: '#qa #show #Docker, #Jenkins, #Jira, #Selenoid, #Allure',
        description: [
            'Артем и Сева покажут как завести QA инфраструктуру крупной компании на локальной машине и поделятся новостями прошедшей недели.'
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ],
    },
    {
        id: 'java-jpoint-coffee-6',
        fuckupUrl: 'jpoint-coffee-6',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/FD57lg_Zzqg',
        date: '24 июня',
        timeStart: '12:00',
        timeEnd: '12:50',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Первая чашка кофе с JPoint / №6 // Алексей Шипилев',
        tags: '',
        description: [
            "На этой неделе гость шоу «Первая чашка кофе с JPoint» — один из самых любимых спикеров конференции Алексей Шипилёв. Сейчас он живет в Германии и работает в Red Hat, где занимается разработкой OpenJDK и его производительностью. Также Алексей разрабатывает и поддерживает несколько подпроектов в OpenJDK, включая JMH, JOL и JCStress. Он активно участвует в экспертных группах и сообществах, работающих над вопросами производительности и многопоточности.",
            "Ведущие эфира — Владимир Ситников и Олег Шелаев. Владимир — автор более десятка улучшений производительности в официальном PostgreSQL JDBC-драйвере, уже десять лет работает над производительностью и масштабируемостью NetCracker OSS. Олег — Developer Advocate проекта GraalVM в OracleLabs.",
            "Темы выпуска:",
            "— Metropolis, GraalVM, новый проект в OpenJDK и Shenandoah 3.0.",
            "— Как Алексей, будучи мейнтейнером, видит 6-месячные релизы.",
            "— OpenJDK: hg vs git.",
            "— Мета-информация в OpenJDK JIRA.",
            "— Как выбирают, что именно портировать из 15 в 8.",
            "— Умрет ли Java 8 и кто ее будет поддерживать?",
            "Напомним, что на конференции JPoint 2020 будет Q&A-сессия с Алексеем, где вы сможете задать ему вопросы в прямом эфире. Присоединяйтесь к нам!"
        ],
        telegramLink: 'tg://resolve?domain=jpointconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/157LvOFSUoK6mIm8mUkQQC/2459d7c2ebbd9a1933b0883e5e574429/_______________________________.jpg',
                name: 'Алексей Шипилёв',
                affiliation: 'Red Hat',
                description: 'Алексей работает над производительностью Java больше 10 лет. Сегодня он работает в Red Hat, где занимается разработкой OpenJDK и его производительностью. Алексей разрабатывает и поддерживает несколько подпроектов в OpenJDK, включая JMH, JOL и JCStress. Алексей также активно участвует в экспертных группах и сообществах, работающих над вопросами производительности и многопоточности. Перед тем как перейти в Red Hat, Алексей работал над Apache Harmony в Intel, а затем перешёл в Sun Microsystems, которая была поглощена Oracle.',
                twitterAccount: 'shipilev'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5mzA6ao6QF4MnwWTdLdu55/7bf2f4e0c1ebe2ef4e5d7b5f9df6e389/Sitnikov.jpg',
                name: 'Владимир Ситников',
                affiliation: 'Netcracker',
                description: 'Десять лет работает над производительностью и масштабируемостью NetCracker OSS — ПО, используемого операторами связи для автоматизации процессов управления сетью и сетевым оборудованием. Увлекается вопросами производительности Java и Oracle Database. Автор более десятка улучшений производительности в официальном PostgreSQL JDBC-драйвере.',
                twitterAccount: 'vladimirsitnikv'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/16mtImj1dcWwUayYWgKs8Y/6b17815f9959308d75a6c2b0b9073bba/Aleksandrov__1_.jpg',
                name: 'Дмитрий Александров',
                affiliation: 'T-Systems',
                description: 'Ведущий программист/архитектор в T-Systems, по совместительству со-лидер болгарской Java User Group.',
                twitterAccount: 'bercut2000'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/nOqNSu66FcGT0MwzHuWMe/38d4e7aae96ac8a7c1d4fb9353243215/Selajev.jpg',
                name: 'Олег Шелаев',
                affiliation: 'OracleLabs, Oracle',
                description: 'Developer advocate for GraalVM at OracleLabs, Oracle.',
                twitterAccount: 'shelajevoleg'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7FnTvLwAqWekJIlkSb5mSY/90a4fc100f1db0dd83816289fc37d63e/Kogun.jpg',
                name: 'Андрей Когунь',
                affiliation: 'КРОК',
                description: 'Основатель и организатор встреч московского сообщества Java-разработчиков JUG.MSK.',
                twitterAccount: 'akogun'
            },
        ]
    },
    {
        id: 'qa-survival-bias-9',
        youtubeLink: 'https://www.youtube.com/embed/d91Ca1Yz5q0',
        hasPassed: true,
        date: '27 июня',
        timeStart: '18:00',
        timeEnd: '19:30',
        complexityEmoji: '🔥',
        complexityTag: 'FLAME',
        langTag: 'RU',
        title: 'Шоу «Ошибка выжившего» Episode 9',
        tags: '#qa #show #visualTesting',
        description: [
            'Артем и Сева разберут инструменты визуального тестирования и поделятся новостями прошедшей недели.'
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://hsto.org/webt/ua/lc/aj/ualcajsrugvrhregmx59oboezrg.png',
                name: 'Артем Ерошенко',
                description: 'Автор инструмента Allure/Allure 2, эксперт по автоматизации тестирования.',
                twitterAccount: 'eroshenkoam'
            },
            {
                photoLink: 'https://hsto.org/webt/dt/bn/sm/dtbnsmyfm5cahh2wkk5nahcagbm.png',
                name: 'Всеволод Брекелов',
                description: 'Участник программного комитета конференции Heisenbug, бывалый QA и разработчик, ведущий подкаста «Битовая Каска».',
                twitterAccount: 'brekelov'
            }
        ],
    },
    {
        id: 'js-piterjs-49',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/R5_yImO-m4g',
        date: '30 июня',
        timeStart: '19:00',
        timeEnd: '21:00',
        complexityEmoji: '👷‍♀️',
        complexityTag: 'PRACTICAL',
        langTag: 'RU',
        title: 'Митап PiterJS #49',
        tags: '#piterjs, #piterjs49, #js, #javascript',
        description: [
            'Поговорим про работу приложения после закрытия вкладки, про Elm и yarn@berry. Присоединяйтесь, будет интересно!',
            'Расписание:',
            '19:10–19:40 — «Фоновые сервисы в браузерах — есть ли жизнь после закрытия вкладки?», Максим Сальников: ',
            'Пользователь закрывает вкладку в браузере и ваше замечательное фронтенд-приложение испаряется. А что, если можно было бы оставить какие-то его части, и еще немного поработать во благо улучшения UX? Отреагировать на какие-то события, завершить начатое общение с сетью, в общем, исполнить немного кода при закрытой вкладке и даже браузере. Максим расскажет о разных интересных возможностях API из семейства сервис-воркеров, позволяющих продлить жизнь приложения, чтобы всегда иметь под рукой свежие данные. А еще не бояться проблем с сетью и уметь показывать уведомления — все ради отличного пользовательского опыта.',
            '19:50–20:20 — «Почему Elm?», Максим Пацианский: ',
            'Elm — это функциональный язык программирования, в котором нет runtime exceptions, зато есть очень дружественный компилятор. Рефакторинг кода на абсолютно новом уровне! Если проект собрался — значит он работает! Elm компилируется в JavaScript и исполняется в браузере привычным образом. Как написано в документации по Redux: «Even if you don’t plan to use Elm, you should read about the Elm architecture, and play with it». Максим прочитал и «поиграл».',
            '20:30–21:00 — «yarn@berry — надо ли оно нам?», Игорь Камышев: ',
            'yarn@berry громко зарелизился, но сейчас о нем мало что слышно. Игорь расскажет о главных преимуществах, которые даёт новая версия менеджера зависимостей, основных проблемах и способах их решения.'
        ],
        telegramLink: 'tg://resolve?domain=piterjsflood',
        speakers: [
            {
                photoLink: 'https://i.ibb.co/FnLfNFY/photo-2020-06-04-18-42-31.png',
                name: 'Анна Волкова',
                affiliation: 'Альфа-банк',
                description: 'Занимаюсь фронтендом в Альфа-Банке, организатор PiterJS.',
                twitterAccount: 'volkova_annie'
            },
            {
                photoLink: 'https://i.ibb.co/52Br0fv/2020-07-28-10-51-32.png',
                name: 'Дмитрий Карловский',
                affiliation: '1С',
                description: 'Руководитель направления. Автор одного из первых чисто тайпскриптовых фреймворков.'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*1Xbe8GBbJZCWq1GyGfZCpQ.png',
                name: 'Максим Сальников',
                affiliation: 'Microsoft',
                description: 'Максим занимается созданием веб-приложений с конца прошлого века и накопил солидный опыт работы со многими аспектами веб-платформы, которыми он активно делится, выступая и проводя мастер-классы на конференциях для разработчиков по всему миру (и сам организует конференции и митапы). В настоящее время он активно исследует новые возможности веба и идею прогрессивных веб-приложений (PWA).',
                twitterAccount: 'webmaxru'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*PaOh-i90tX2NlwTphpgxag.png',
                name: 'Максим Пацианский',
                affiliation: 'Shore GmbH (shore.com)',
                description: 'На работе у Максима Elm используют в продакшене: кодовая база 40% Elm, 60% JavaScript/TypeScript. Сам спикер работает с Elm около года, но задач не так много, как ему хотелось бы, поэтому в качестве обучения он написал сервис для создания и публикации стикеров в Телеграм. Также Максим является автором учебников по React и Redux, которые были очень популярны в свое время.',
                twitterAccount: 'MaxPatsiansky'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/1*wT2Ou6-Va6j23wIQg-wYHA.png',
                name: 'Игорь Камышев',
                affiliation: 'Aviasales',
                description: 'Игорь работает в платформенной команде и помогает фронтендерам жить проще. Внедряет yarn@berry в новые проекты внутри Aviasales и пишет все свои пет-проекты с использованием этого менеджера зависимостей.',
                twitterAccount: 'kamyshev_code'
            },
        ]
    },
    {
        id: 'js-piterjs-50',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/56iO6g2T5xI',
        date: '27 августа',
        timeStart: '19:00',
        timeEnd: '20:30',
        complexityEmoji: '👷‍♀️',
        complexityTag: 'PRACTICAL',
        langTag: 'RU',
        title: 'Митап PiterJS #50',
        tags: '#piterjs, #piterjs50, #js, #javascript',
        description: [
            '27 августа пройдет PiterJS №50, на котором Алексей Остапенко проведет воркшоп про локальную разработку c HTTPS. Общаться и задавать вопросы можно будет во флудилке в Телеграме. Присоединяйтесь, будет интересно!',
            'Расписание:',
            '19:10–20:10 — «Воркшоп: Локальная разработка c HTTPS», Алексей Остапенко: ',
            'Алексей объяснит, как начать разрабатывать сразу с HTTPS и перестать его бояться. Покажет, как настроить свой Certificate Authority и научиться выписывать сертификаты для dev-окружений. И расскажет, как сделать это один раз и больше об этом не думать.',
            '20:10–20:30 — Q&A, ведущий Дмитрий Карловский',
            ],
        telegramLink: 'tg://resolve?domain=piterjsflood',
        speakers: [
            {
                photoLink: 'https://miro.medium.com/max/1000/1*nBz6BgSdOHyydmRYlTNpEQ.png',
                name: 'Алексей Остапенко',
                affiliation: 'Samokat.ru',
                description: 'Lead Frontend Developer. Алексей занимается фронтендом уже 15 лет и хочет поделиться как делать это проще.',
                twitterAccount: 'kbakba'
            },
            {
                photoLink: 'https://miro.medium.com/max/1000/0*WAuXARxb-BiWJUP1.png',
                name: 'Дмитрий Карловский',
                affiliation: '1С',
                description: 'Руководитель направления. Автор одного из первых чисто тайпскриптовых фреймворков.'
            },
        ]
    },
    {
        id: 'js-holy-breakfast-6',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/xaZ2bu0ld20',
        date: '15 сентября',
        timeStart: '10:00',
        timeEnd: '11:00',
        complexityEmoji: '🐱',
        complexityTag: 'FUN',
        langTag: 'RU',
        title: 'Тяжелое утро с HolyJS #6',
        tags: '#holyjs #morning #show',
        description: [
            "Программный комитет подведёт итоги прошлого сезона и объявит что ждет в новом!"
        ],
        telegramLink: 'tg://resolve?domain=holyjsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4WhNI6NhtSEawOGM0gcCi6/d7dfc7a60a9a5e7785fc1d951d88b385/zolotykh.jpg?w=340',
                name: 'Алексей Золотых',
                affiliation: 'Infobip',
                description: 'Пишет на JavaScript c 2007 года. До перехода к JS работал верстальщиком, сверстал более 100 сайтов. Писал на jQuery, Backbone.js, Angular 1, Angular 2, React, Ext JS, PolymerJS. Любит пользовательские интерфейсы и кофе.',
                twitterAccount: 'zolotyh'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/2Sr3POKsCQmEecYMe2eSIs/c55a1eb684aadf07411608e03235a735/avatar4.jpg?w=340',
                name: 'Евгений Кот',
                affiliation: 'Wrike',
                description: 'Долгое время занимался .NET, Microsoft stack. Работал в Quest Software, после этого в Dell, где начал заниматься фронтендом. Прошёл путь от CoffeeScript + Angular 1.0 до Dart + Angular 2.0. На данный момент трудится в Wrike, высоконагруженном SaaS-приложении с очень богатым клиентским кодом. Команда Wrike пишет на Dart и Angular 2.0 и стремится быть up-to-date со всеми современными течениями в мире веб.',
                twitterAccount: 'bunopus'
            },
            {
                photoLink: 'https://images.ctfassets.net/nn534z2fqr9f/4pLwhybhMhIePf8jc2nMxV/b300806af12712c0ed0e470a7b13724c/kalinina.jpg?w=340',
                name: 'Александра Калинина',
                affiliation: 'Nix',
                description: 'Более 10 лет опыта в программировании. Хорошо подкована в веб-разработке: решает проблемы с дырявыми абстракциями. Имеет опыт в написании unit/integration/E2E/B2B-тестов, используя mocha/chai/sinon. Большой опыт в технологиях веб-разработки, шаблонах проектирования, проектирования баз данных, разработки алгоритмов и структуры данных. Есть опыт в разработке проектных заданий и технической документации.',
                twitterAccount: 'korery'
            },
        ]
    },
    {
        id: 'qa-heisenbug-show-1',
        fuckupUrl: 'heisenbug-show-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/v2YR0fVmQnI',
        date: '15 сентября',
        timeStart: '13:30',
        timeEnd: '14:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Heisenbug Show / №1 // Live // Высшее образование в IT',
        tags: '',
        description: [
            "Тема: Высшее образование в IT",
            "Кто-то уверен, что работать в IT без высшего образования нельзя, а кто-то убежден, что государственные ВУЗы — лишняя трата времени. Гости эфира много лет преподают и работают в IT. Они попробуют разобраться, насколько необходимо высшее образование для старта карьеры."
        ],
        telegramLink: 'tg://resolve?domain=heisenbugconf',
        speakers: [
            {
                photoLink: 'https://i.ibb.co/FKmdfjw/me-holyjs-nov2019-1.jpg?w=200',
                name: 'Виталий Брагилевский',
                affiliation: 'JetBrains/МКН СПбГУ',
                description: 'Преподаватель функционального программирования, теории языков программирования и теории вычислений, член комитета по стандартизации языка программирования Haskell и наблюдательного комитета по разработке компилятора GHC языка Haskell, автор книги «Haskell in Depth» (Manning Publications).',
                twitterAccount: '_bravit'
            },
            {
                photoLink: 'https://images.ctfassets.net/2jxgmeypnru5/50HQhU5Jpe8AeOgA0wis6C/192efd6c4b898f62c3196e7890dac208/dmitriev.jpg?w=200',
                name: 'Андрей Дмитриев',
                affiliation: 'JUG Ru Group',
                description: 'Закончил матмех СПбГУ. Несколько лет разрабатывал графический стек библиотек JDK (AWT/Swing/JavaFX) в Sun и Oracle. Руководил группой разработки в компании QuickOffice, отвечал за функциональность компонента QuickPoint. Работал менеджером группы нагрузочного тестирования в компании NetCracker. На данный момент Андрей — директор департамента производства в JUG Ru Group. Преподаватель СПБГУ',
                twitterAccount: 'it_improve'
            },
            {
                photoLink: 'https://images.ctfassets.net/ut4a3ciohj8i/1wgFNcg401cnZkzReBP9sv/a551ac5ba70b1c7630600584484bcee5/Ponomarev.jpg?w=200',
                name: 'Иван Пономарев',
                affiliation: 'КУРС/МФТИ',
                description: 'Технический лидер в компании КУРС, преподаватель в МФТИ. Более 15 лет работает в ИТ, пишет код и руководит проектами от доработки ERP-систем до построения систем мониторинга данных в интернете в реальном времени. Периодически делится своим опытом на конференциях и митапах.',
                twitterAccount: 'inponomarev'
            },
        ]
    },
    {
        id: 'dotnet-home-bar-2',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/jXdPgPbf2EY',
        date: '15 сентября',
        timeStart: '19:00',
        timeEnd: '20:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Барная стойка / №2 // Live /// Дмитрий Сошников',
        tags: '#dotnext #ЖЗЛ',
        description: [
            "Помните тот самый Stay the F**k Home bar, о котором все говорили в начале карантина? Сначала все было отлично, но со временем гостей стало все меньше, а беседы за стойкой — все скучнее. Пришло время открыть собственный онлайн-бар — с околоайтишными разговорами и классными гостями.",
            "Программный комитет конференции DotNext запускает свое разговорное шоу «Барная стойка». Его ведущие — Михаил Щербаков и Максим Аршинов. Михаил — докторант в KTH Royal Institute of Technology, участник Microsoft Bug Bounty Programs, соорганизатор сообщества DotNet.ru. Максим — соучредитель казанской аутсорс-компании «Хайтек Груп», который также преподает в Высшей школе информационных технологий и информационных систем Казанского федерального университета и ведет блог на Хабре.",
            "Гость - Дмитрий Сошников."
        ],
        telegramLink: 'tg://resolve?domain=dotnextconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/9n3x4rtjlya6/5VLL30wi6gm4VeYI5u27tX/9f96397b94d598d7065e02ed67b54aad/soshnikov.jpg?w=200',
                name: 'Дмитрий Сошников',
                affiliation: 'Microsoft',
                description: 'Технологический евангелист компании Майкрософт, занимается популяризацией современных технологий разработки программного обеспечения среди начинающих разработчиков: школьников, студентов, начинающих стартапов. Специализируется в области интернета вещей, в разработке универсальных приложений Windows, в области функционального программирования и на платформе .NET (F#, Roslyn). Лично провел несколько десятков хакатонов по всей России, помогал многим студенческим стартапам начать свои проекты в различных областях. Доцент, к.ф.-м.н., ведет занятия в МФТИ и МАИ, член Российской ассоциации искусственного интеллекта, летом — ведущий кафедры компьютерных технологий детского лагеря ЮНИО-Р. В свободное время увлекается технологической магией, проводит китайские чайные церемонии Гун Фу Ча.',
                twitterAccount: 'shwars'
            },
            {
                photoLink: 'https://images.ctfassets.net/9n3x4rtjlya6/5hBoerzyjQwP5suFuhqhJl/16cb19ac7d9dc0ffc92b4b513d99e644/Shcherbakov.jpg?w=200',
                name: 'Михаил Щербаков',
                affiliation: 'KTH Royal Institute of Technology',
                description: 'Докторант в KTH Royal Institute of Technology, участник Microsoft Bug Bounty Programs, со-организатор сообщества DotNet.ru. 10 лет работал в индустрии, сейчас занимается исследованиями в области безопасности приложений, статического и динамического анализа кода, применения формальных методов для поиска уязвимостей.',
                twitterAccount: 'yu5k3'
            },
            {
                photoLink: 'https://images.ctfassets.net/9n3x4rtjlya6/6bCu08LNoO8aDFyBWvd6JR/84004a776a0953940be5fc8787038284/arshinov.jpg?w=200',
                name: 'Максим Аршинов',
                affiliation: 'Хайтек Груп',
                description: 'Соучредитель казанской аутсорс-компании «Хайтек Груп». Кроме ведения бизнеса преподает в Высшей школе информационных технологий и информационных систем Казанского федерального университета и ведет блог на Хабре. Суммарный стаж в программировании и управлении разработкой — 10 лет. За это время прошел путь от программиста в казанской веб-студии до руководителя отдела качества в финансовой индустрии. Работал в компаниях Казани, Москвы, Питера и Антверпена (Бельгия). Считает, что технологии — это не «вещь в себе», а инструменты для достижения целей. Каждая — со своей областью применения.',
                twitterAccount: 'marshinov'
            },
        ]
    },
    {
        id: 'java-joker-coffee-1',
        fuckupUrl: 'joker-coffee-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/6bMSz8HGBQE',
        date: '16 сентября',
        timeStart: '14:00',
        timeEnd: '15:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Вторая чашка кофе с Joker / №1 // Live /// Алексей Федоров',
        tags: '',
        description: [
            "Ведущие: Антон Архипов и Андрей Когунь",
            "Гость: Алексей Федоров",
            "В первом выпуске обсудим, что нового ждет участников на предстоящей конференции Joker и поговорим про онлайн. Также участники эфира обсудят, какие полезные фичи они видели на других онлайн-конференциях."
        ],
        telegramLink: 'tg://resolve?domain=jokerconf',
        speakers: [
            {
                photoLink: 'https://pbs.twimg.com/profile_images/904461024786513920/xqFuwPeM_400x400.jpg',
                name: 'Алексей Федоров',
                affiliation: 'JUG Ru Group',
                description: 'Лидер питерского JUG и организатор Java-конференций Joker и JPoint',
                twitterAccount: '23derevo'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/5P1Kd3hcFVE3HEXjH1yduN/9eb021a69c50918371b92e5be39467a4/Arhipov.jpg',
                name: 'Антон Архипов',
                affiliation: 'JetBrains',
                description: 'Developer advocate в проекте Kotlin, в компании JetBrains, резидент подкаста «Разбор полетов»',
                twitterAccount: 'antonarhipov'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7FnTvLwAqWekJIlkSb5mSY/90a4fc100f1db0dd83816289fc37d63e/Kogun.jpg',
                name: 'Андрей Когунь',
                affiliation: 'КРОК',
                description: 'Руководитель группы Java разработчиков в КРОК. Основатель и организатор встреч московского сообщества Java-разработчиков JUG.MSK.',
                twitterAccount: 'andrei_kogun'
            },
        ]
    },
    {
        id: 'c-plus-plus-show-1',
        hasPassed: true,
        youtubeLink: 'https://www.youtube.com/embed/DopObynuvQg',
        date: '17 сентября',
        timeStart: '18:00',
        timeEnd: '18:40',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'Pure Virtual Cast / №1 // Live /// Дмитрий Полищук, Игорь Макаренко',
        tags: '#cpprussia #cpp',
        description: [
            'Вместе с гостями выпуска Дмитрием и Игорем обсудим – Self driving car: какие задачи решают разработчики, какие трудности возникают, помогают или мешают плюсы, скоро ли перепишут все на расте.'
        ],
        telegramLink: 'tg://resolve?domain=cpprussia',
        speakers: [
            {
                photoLink: 'https://i.ibb.co/KKQr267/image.jpg',
                name: 'Дмитрий Полищук',
                affiliation: 'Sber Automotive Technologies',
                description: 'Более 15 лет занимается embedded разработкой. Начинал, как разработчик С++ математических симуляторов и программирования FPGA. Как Senior developer разрабатывал  мобильные приложения, highload, создавал продукты для миллионов пользователей. Создал с нуля подразделение разработки продуктов для водителей в Яндекс.Такси и стал экспертом в геопозиционировании пользователей и водителей.  Сейчас  занимается организацией процесса разработки всех направлений компании в Sber Automotive Technologies.',
                twitterAccount: 'dpolishuk'
            },
            {
                photoLink: 'https://i.ibb.co/F4Q9n4p/image.jpg',
                name: 'Игорь Макаренко',
                affiliation: 'Sber Automotive Technologies',
                description: 'Руководитель отдела локализации в Sber Automotive Technologies. Использует все доступные сенсоры для ответа на простой вопрос - где в данный момент находится машина. Руководил локализацией в компаниях Arrival и Roborace. В роли CTO сделал беспилотных складских погрузчиков в компании RoboCV.',
                twitterAccount: ''
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7K6BS8bMMueKKia7KQIaGY/611209d08d440588f812189d382adcf8/Filonov.jpg?w=200',
                name: 'Павел Филонов',
                affiliation: 'Лаборатория Касперского',
                description: 'Более 5 лет занимается разработкой ПО в области информационной безопасности. Более 3 лет активно использует методы анализа данных для разработки и внедрения методов машинного обучения в ИБ. До начала карьеры разработчика занимался исследованиями в области радиофизики и преподаванием в высшей школе.',
                twitterAccount: 'pavel_filonov'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/RsPJjd7HYaupPfDGX0wEy/bdce5ca9cb5e080a1c1c35aa646de1e7/Aleksey_Veselovskiy.jpg?w=200',
                name: 'Алексей Веселовский',
                affiliation: 'Align Technology',
                description: 'Старший С++ разработчик в Align Technology. Занимается разработкой общих системных компонентов и фреймворка специализированной 3D CAD-системы. Ранее разработал систему реалтайм мониторинга пациентов, а также систему сбора и обработки данных для self driving car. Участвовал в проекте Squid-proxy. В область интересов входят вопросы надежности ПО, компиляторостроения, системного программирования. Всегда старается докопаться до инстины.',
                twitterAccount: 'I_vlxy_I'
            },
        ]
    },
    {
        id: 'devops-brunch-6',
        hasPassed: false,
        youtubeLink: 'https://www.youtube.com/embed/AUhY5QHpSaU',
        date: '18 сентября',
        timeStart: '18:00',
        timeEnd: '19:00',
        complexityEmoji: '🥤',
        complexityTag: 'SMOOTHIE',
        langTag: 'RU',
        title: 'DevOops в рабочий полдник / №6 // Live /// Виктория Алмазова',
        tags: '#devoops',
        description: [
            "Интервью со спикером DevOops 2020 Piter Викторией Алмазовой."
        ],
        telegramLink: 'tg://resolve?domain=devOopsconf',
        speakers: [
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/2IQxl7SHxKSgrkkmRXCkbV/4bdef2e40f694846dc84c51813eb1515/Victoria_Almazova.jpg?w=200',
                name: 'Виктория Алмазова',
                affiliation: 'Microsoft',
                description: 'Security girl in Microsoft Norway with an experience of more than 14 years in security. She spends all her time working closely with developers and architects to make security built-in from the design level. She is a big supporter of making security as culture and shifting security to the left. Victoria believes that empowering developers and architects in security tasks by helping with education will increase the security level without increasing additional workload. During her free time, she deep dives into cloud security, development, identity, and access management.',
                twitterAccount: 'wildcattz'
            },
            {
                photoLink: 'https://pbs.twimg.com/profile_images/1225192722748694528/38_2OsWm_400x400.jpg',
                name: 'Барух Садогурский',
                affiliation: 'JFrog',
                description: 'Барух Садогурский (a.k.a. JBaruch) — Head of DevOps Advocacy и Developer Advocate в компании JFrog. Больше всего любит рассказывать о технологиях — то есть просто поболтать любит, но человек, говорящий о технологиях, имеет умный вид, да и 18 лет опыта в сфере высоких технологий никуда не делись. Когда он не выступает (ну, или не летит к месту следующего выступления), то изучает технологии, людей и то, как они работают, точнее, не работают вместе. Барух — соавтор книги «Liquid Software», CNCF ambassador и профессиональный спикер по таким темам, как DevOps, DevSecOps, Go, Java и др. Он регулярно выступает на таких известных конференциях, как Joker, JPoint, DevOops, Heisenbug, DockerCon, GopherCon, Devoxx, DevOps Days, OSCON, Qcon, JavaOne и др. Некоторые его доклады можно посмотреть здесь: jfrog.com/shownotes',
                twitterAccount: 'jbaruch'
            },
            {
                photoLink: 'https://images.ctfassets.net/oxjq45e8ilak/7Dw30diwYmaiFeTDlYE7u9/9d47fdc5166d595ae150ca5cc54d4704/kirpichnikov.jpg?w=200',
                name: 'Алексей Кирпичников',
                affiliation: 'Контур',
                description: 'Алексей работает в Контуре и делает инфраструктуру-как-сервис для разработчиков продуктов этой компании. В сферу интересов Алексея входит сбор метрик, алертинг, агрегация логов, service discovery, деплой, хостинг и все остальное, что нужно разработчику, чтобы сосредоточиться на развитии сервиса и не тратить время на эксплуатацию. До Контура Алексей был тимлидом команды разработки Яндекс.Такси и довел инфраструктуру бэкендов этого сервиса от основания до миллионного заказа.',
                twitterAccount: ''
            },
        ]
    },
];

export default events
